import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useStateContext } from "contexts/ContextProvider";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ChangePasswordModal from "modals/miscellaneous/ChangePasswordModal";
import "./marquee.css";
import socket from "contexts/socket";

export default function ProfileMenu() {
  const { auth, setAuth } = useStateContext();

  const [anchorEl, setAnchorEl] = useState(null);

  const [open, setOpen] = useState(false);

  // const handleOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  const handleLogout = () => {
    // socket.emit("leaveRoom", `room-${auth?.unitId}`, () => {
    //   console.log(
    //     `User ${auth?.firstName} ${auth?.lastName} left the room ${auth?.unitName}`
    //   );
    // });
    socket.emit("leaveRoom", `room-${auth?.unitId}`);

    setAuth(null);
    localStorage.clear();
  };

  useEffect(() => {
    if (auth && auth.changePass === 1) {
      setOpen(true);
    }
  }, []);

  return (
    <Box>
      <ChangePasswordModal open={open} handleClose={() => setOpen(false)} />
      {/* <ViewSignatureModal
        open={openViewSignatureModal}
        handleClose={() => {
          setDataFromActions(null);
          setOpenViewSignatureModal(false);
        }}
        dataFromActions={dataFromActions || null}
      /> */}
      <IconButton
        onClick={(evt) => setAnchorEl(evt.currentTarget)}
        sx={{
          color: "#59395c",
        }}
      >
        <AccountCircleIcon sx={{ fontSize: 30 }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <Box
          sx={{
            // boxShadow: "3px 3px 5px 3px rgba(0, 0, 0, 0.3)",
            borderTop: "solid 1px gray",
            borderBottom: "solid 1px gray",
            mx: 1,
            mb: 1,
            p: 0.5,
            height: "35px",
            position: "relative",
            overflow: "hidden",
            whiteSpace: "nowrap",
            "&:hover .scrollingText": {
              animation: "scroll 8s linear infinite",
            },
          }}
        >
          <Typography
            className="scrollingText"
            sx={{
              display: "inline-block",
              position: "absolute",
              fontFamily: "Poppins",
              // fontStyle: "italic",
              fontWeight: "bold",
              color: "#59395c",
            }}
          >
            {auth?.username}
          </Typography>
        </Box>
        <Divider sx={{ backgroundColor: "gray" }} />
        <MenuItem
          onClick={() => setOpen(true)}
          sx={{
            "&:hover": {
              backgroundColor: "#f6e247",
              fontWeight: "bold",
            },
          }}
        >
          Change Password
        </MenuItem>
        <MenuItem
          onClick={() => handleLogout()}
          sx={{
            "&:hover": {
              backgroundColor: "#f6e247",
              fontWeight: "bold",
            },
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </Box>
  );
}
