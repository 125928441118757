/* eslint-disable no-alert */
/* eslint-disable no-param-reassign */
import React from "react";
import { Box, Typography } from "@mui/material";

export default function PageTemplate({
  icon,
  header,
  modals,
  error,
  leftButtons,
  rightButtons,
  table,
}) {
  return (
    <Box
      sx={{
        overflowX: "auto",
        p: 6,
        width: "100%",
      }}
    >
      {modals}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "solid 1px gray",
          mb: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          {icon}
          <Typography
            sx={{
              fontWeight: "bold",
              color: "#59395c",
              textTransform: "uppercase",
              fontSize: "25px",
            }}
          >
            {header}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
          mt: -0.5,
          minWidth: "30%",
          right: 280,
          zIndex: 1000,
        }}
      >
        {leftButtons && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: 2,
            }}
          >
            {leftButtons}
          </Box>
        )}
        {rightButtons && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: 2,
            }}
          >
            {rightButtons}
          </Box>
        )}
      </Box>
      {error && (
        <Box
          sx={{
            backgroundColor: "red",
            width: "100%",
            mt: 2,
            px: 1,
          }}
        >
          <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
            {error}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          mt: 2,
          minWidth: "100%",
        }}
      >
        {table}
      </Box>
    </Box>
  );
}
