import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import useAxiosPrivate from "contexts/interceptors/axios";
import { useStateContext } from "contexts/ContextProvider";

export default function SelectDestinations({
  label,
  placeholder,
  name,
  value,
  onChange,
  onBlur,
  errorFormik,
  helperText,
  disabled,
  addSpecificDestinations = null,
  showOnlySpecificDestinations = null,
  showSuperintendents = false,
  restrictOwnDestination,
  sx = { width: "100%" },
}) {
  // eslint-disable-next-line no-unused-vars
  const { auth } = useStateContext();
  const axiosPrivate = useAxiosPrivate();

  const [destinations, setDestinations] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const SDSSecIds = [3, 4];
  const ASDSSecIds = [7];

  const handleGetAll = () => {
    setLoading(true);
    setError("");

    axiosPrivate
      .get("/libraries/getDestinations")
      .then((e) => {
        let fetchedUnits = e?.data?.units;

        if (auth?.officeId !== 1) {
          fetchedUnits = fetchedUnits.filter((unit) => unit.id === 12);
        } else if (
          showOnlySpecificDestinations &&
          showOnlySpecificDestinations.length > 0
        ) {
          fetchedUnits = fetchedUnits.filter((unit) =>
            showOnlySpecificDestinations.includes(unit.id)
          );
        } else if (restrictOwnDestination === "unit") {
          if (SDSSecIds.includes(auth.unitId)) {
            fetchedUnits = fetchedUnits.filter(
              (unit) =>
                !SDSSecIds.includes(unit.id) && unit.id !== 1 && unit.id !== 2
            );
          } else if (ASDSSecIds.includes(auth.unitId)) {
            fetchedUnits = fetchedUnits.filter(
              (unit) =>
                !ASDSSecIds.includes(unit.id) && unit.id !== 1 && unit.id !== 2
            );
          } else {
            fetchedUnits = fetchedUnits.filter(
              (unit) =>
                unit.id !== auth.unitId && unit.id !== 1 && unit.id !== 2
              // (unit) => unit.id !== auth.unitId
            );
          }
        } else if (SDSSecIds.includes(auth.unitId)) {
          fetchedUnits = fetchedUnits.filter(
            (unit) => unit.id !== 1 && unit.id !== 2
          );
        } else if (ASDSSecIds.includes(auth.unitId)) {
          fetchedUnits = fetchedUnits.filter(
            (unit) => unit.id !== 1 && unit.id !== 2
          );
        } else if (showSuperintendents) {
          fetchedUnits = e?.data?.units;
        } else {
          fetchedUnits = fetchedUnits.filter(
            (unit) => unit.id !== 1 && unit.id !== 2
            // (unit) => unit.id !== auth.unitId
          );
        }

        const upperManagement = fetchedUnits.filter((unit) => unit.id < 8);

        const sortedUnits = fetchedUnits
          .filter((unit) => unit.id < 1 || unit.id > 7)
          .sort((a, b) => a.destination.localeCompare(b.destination));

        const fetchedOffices = e?.data?.offices.filter(
          (office) => office.id !== 1
        );

        let sortedOffices = fetchedOffices.sort((a, b) =>
          a?.destination.localeCompare(b?.destination)
        );

        if (restrictOwnDestination === "office") {
          sortedOffices = sortedOffices.filter(
            (office) => office.id !== auth.officeId
          );
        }

        let combinedDestinations = [
          ...upperManagement,
          ...sortedUnits,
          ...(auth?.unitId === 12 ? [...sortedOffices] : []),
        ];

        if (addSpecificDestinations) {
          combinedDestinations = [
            ...addSpecificDestinations,
            ...combinedDestinations,
          ];
        }

        if (auth?.unitId === 1) {
          const filteredSDSUnits = e?.data?.units
            ?.filter((unit) =>
              [4, 7, 8, 9, 10, 11, 12, 13, 18, 19, 20, 21].includes(unit?.id)
            )
            ?.sort((a, b) => {
              const order = [13, 21, 4, 7, 8, 9, 10, 11, 12, 18, 19, 20];
              return order.indexOf(a.id) - order.indexOf(b.id);
            });
          setDestinations(filteredSDSUnits);
        } else {
          setDestinations(combinedDestinations);
        }
        // setUnits(fetchedUnits);
      })
      .catch((err) => {
        setError(err.message || "Error: Something went wrong");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleGetAll();
  }, [auth]);

  const [selectedDestination, setSelectedDestination] = useState(
    destinations?.find((dest) => dest.id === value) || null
  );

  // useEffect(() => {
  //   const destination = destinations?.find((dest) => dest.id === value) || null;

  //   if (destination) {
  //     const { unit, office, ...rest } = destination;
  //     setSelectedDestination({
  //       ...rest,
  //       destination: unit || office,
  //     });
  //   } else {
  //     setSelectedDestination(null);
  //   }
  // }, [destinations]);

  useEffect(() => {
    setSelectedDestination(
      destinations?.find((dest) => dest.id === value) || null
    );
  }, [destinations]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (newValue) => {
    setAnchorEl(null);
    if (newValue) {
      // eslint-disable-next-line no-unused-expressions, no-param-reassign
      delete newValue?.office || newValue?.unit;

      onChange?.(name, newValue || "");
      setSelectedDestination(newValue);
    }
  };

  const handleClear = () => {
    onChange?.(name, "");
    setSelectedDestination(null);
  };

  return (
    <Box>
      <TextField
        label={error ? `${label} - ${error}` : label}
        placeholder={error ? `${placeholder} - ${error}` : placeholder}
        name={name}
        variant="outlined"
        size="small"
        disabled={error || disabled}
        value={
          selectedDestination && value ? selectedDestination.destination : ""
        }
        onClick={handleClick}
        onBlur={onBlur}
        error={errorFormik}
        helperText={<span style={{ color: "red" }}>{helperText}</span>}
        sx={sx}
        InputProps={{
          endAdornment: (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {loading ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                <>
                  {selectedDestination && (
                    <IconButton edge="end" onClick={handleClear}>
                      <CloseIcon />
                    </IconButton>
                  )}
                  {!disabled && !error && (
                    <IconButton edge="end" onClick={handleClick}>
                      <ArrowDropDownIcon />
                    </IconButton>
                  )}
                </>
              )}
            </>
          ),
        }}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleClose(null)}
      >
        {destinations.map((destination) => (
          <MenuItem
            sx={{ width: "100%" }}
            key={destination.id}
            onClick={() => handleClose(destination)}
          >
            {destination.destination}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
