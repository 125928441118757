import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Button } from "@mui/material";
import AddUserModal from "modals/users/AddUserModal";
import UpdateUserModal from "modals/users/UpdateUserModal";
import useAxiosPrivate from "contexts/interceptors/axios";

import UsersIcon from "@mui/icons-material/PeopleAlt";

import DisplayCreatedUserModal from "modals/users/DisplayCreatedUserModal";
import PageTemplate from "layouts/PageTemplate";
import UsersTable from "./UsersTable";

export default function Users() {
  const axiosPrivate = useAxiosPrivate();

  const [data, setData] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [createdUser, setCreatedUser] = useState(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [disabled, setDisabled] = useState(false);

  const [openAddModal, setOpenAddModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);

  const handleOpen = (type) => {
    if (type === "add") {
      setOpenAddModal(true);
    } else if (type === "update") {
      setOpenUpdateModal(true);
    }
  };

  const handleGetAll = () => {
    setLoading(true);
    setError("");

    axiosPrivate
      .get(`/user/getAllUsers`)
      .then((e) => {
        setData(e.data);
      })
      .catch((err) => {
        setError(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleGetAll();
  }, []);

  useEffect(() => {
    if (!selectedUser?.length) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [selectedUser]);

  return (
    <PageTemplate
      icon={
        <UsersIcon
          sx={{
            fontSize: "40px",
          }}
        />
      }
      header="Users"
      modals={[
        <AddUserModal
          open={openAddModal}
          handleClose={() => setOpenAddModal(false)}
          setCreatedUser={setCreatedUser}
          updateTableFunction={() => {
            handleGetAll();
          }}
        />,
        <UpdateUserModal
          handleClose={() => setOpenUpdateModal(false)}
          open={openUpdateModal}
          data={selectedUser && selectedUser[0]}
          setSelectedUser={setSelectedUser}
          updateTableFunction={() => {
            handleGetAll();
          }}
        />,
        <DisplayCreatedUserModal
          open={Boolean(createdUser)}
          handleClose={() => setCreatedUser(null)}
          data={createdUser}
        />,
      ]}
      error={error}
      leftButtons={[
        <Button
          onClick={() => handleOpen("add")}
          sx={{
            backgroundColor: "green",
            color: "#fff",
            fontSize: "14px",
            fontWeight: "bold",
            padding: "5px 20px",
            borderRadius: "20px",
            boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
            "&:hover": {
              backgroundColor: "#f6e247",
              color: "#59395c",
              fontWeight: "bold",
            },
          }}
        >
          <AddIcon sx={{ mr: "10px" }} />
          Add
        </Button>,
        <Button
          onClick={() => handleOpen("update")}
          disabled={disabled}
          sx={{
            backgroundColor: disabled ? "lightgray" : "#246fc9",
            color: "#fff",
            fontSize: "14px",
            fontWeight: "bold",
            padding: "5px 20px",
            borderRadius: "20px",
            boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
            "&:hover": {
              backgroundColor: "#f6e247",
              color: "#59395c",
              fontWeight: "bold",
            },
          }}
        >
          <EditIcon sx={{ mr: "10px" }} />
          Update
        </Button>,
      ]}
      table={
        <UsersTable
          data={data}
          selectedData={selectedUser}
          setSelectedData={setSelectedUser}
          loadingState={loading}
          updateTableFunction={() => {
            handleGetAll();
          }}
        />
      }
    />
  );
}
