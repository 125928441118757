/* eslint-disable no-alert */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";

import { RiFileTransferFill } from "react-icons/ri";

import { useStateContext } from "contexts/ContextProvider";
import useAxiosPrivate from "contexts/interceptors/axios";
import PageTemplate from "layouts/PageTemplate";
import DocumentsTable from "../DocumentsTable";

export default function RoutedOutDocuments() {
  const { auth, referenceId } = useStateContext();
  const axiosPrivate = useAxiosPrivate();
  const [documents, setDocuments] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const SDSSecIds = [3, 4];
  const ASDSSecIds = [7];

  const handleGetAll = () => {
    setLoading(true);
    setError("");

    axiosPrivate
      .get(`/documents/getAllDocuments`, {
        params: { referenceId },
      })
      .then((res) => {
        res.data.forEach((doc) => {
          if (typeof doc.action === "string") {
            doc.action = JSON.parse(doc.action);
          }
        });

        const filteredRoutedOut = res?.data?.filter((doc) => {
          const parsedRoutedBy = doc.routedBy ? JSON.parse(doc.routedBy) : [];

          if (SDSSecIds.includes(auth.unitId)) {
            if (
              parsedRoutedBy.some(
                (router) => router.id === 1 && doc.classification === 4
              )
            ) {
              return true;
            }
          } else if (ASDSSecIds.includes(auth.unitId)) {
            if (
              parsedRoutedBy.some(
                (router) => router.id === 2 && doc.classification === 4
              )
            ) {
              return true;
            }
          } else if (
            parsedRoutedBy.some(
              (router) => router.id === auth.unitId && doc.classification === 4
            )
          ) {
            return true;
          }

          return false;
        });

        setDocuments(filteredRoutedOut);
      })
      .catch((err) => {
        setError(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleGetAll();
  }, []);

  return (
    <PageTemplate
      icon={
        <Box sx={{ mx: 0.5 }}>
          <RiFileTransferFill
            style={{
              fontSize: "33px",
            }}
          />
        </Box>
      }
      header="Routed Out Documents"
      error={error}
      table={
        <DocumentsTable
          data={documents}
          loadingState={loading}
          setLoadingState={setLoading}
          updateTableFunction={handleGetAll}
        />
      }
    />
  );
}
