import React from "react";
import { Box, Typography } from "@mui/material";

export default function LessPaperLogo() {
  return (
    <Box
      sx={{
        display: "block",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexWrap: "noWrap",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#59395c",
            height: "50px",
            width: "50px",
            mr: "5px",
            "@media (max-width: 510px)": {
              height: "40px",
              width: "40px",
            },
          }}
        >
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontWeight: "500",
              fontSize: "40px",
              color: "#fff",
              letterSpacing: "5px",
              "@media (max-width: 510px)": {
                fontSize: "30px",
              },
            }}
          >
            L
          </Typography>
        </Box>
        <Typography
          sx={{
            fontFamily: "Roboto",
            fontWeight: "500",
            fontSize: "40px",
            color: "#59395c",
            letterSpacing: "5px",
            "@media (max-width: 510px)": {
              fontSize: "30px",
            },
          }}
        >
          ess
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexWrap: "noWrap",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#59395c",
            height: "50px",
            width: "50px",
            mr: "5px",
            "@media (max-width: 510px)": {
              height: "40px",
              width: "40px",
            },
          }}
        >
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontWeight: "500",
              fontSize: "40px",
              color: "#fff",
              letterSpacing: "5px",
              "@media (max-width: 510px)": {
                fontSize: "30px",
              },
            }}
          >
            P
          </Typography>
        </Box>
        <Typography
          sx={{
            fontFamily: "Roboto",
            fontWeight: "500",
            fontSize: "40px",
            color: "#59395c",
            letterSpacing: "5px",
            "@media (max-width: 510px)": {
              fontSize: "30px",
            },
          }}
        >
          aper
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexWrap: "noWrap",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#59395c",
            height: "50px",
            width: "50px",
            mr: "5px",
            "@media (max-width: 510px)": {
              height: "40px",
              width: "40px",
            },
          }}
        >
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontWeight: "500",
              fontSize: "40px",
              color: "#fff",
              letterSpacing: "5px",
              "@media (max-width: 510px)": {
                fontSize: "30px",
              },
            }}
          >
            S
          </Typography>
        </Box>
        <Typography
          sx={{
            fontFamily: "Roboto",
            fontWeight: "500",
            fontSize: "40px",
            color: "#59395c",
            letterSpacing: "5px",
            "@media (max-width: 510px)": {
              fontSize: "30px",
            },
          }}
        >
          ystem
        </Typography>
      </Box>
    </Box>
  );
}
