/* eslint-disable no-nested-ternary */
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useLocation, useNavigate } from "react-router-dom";
import { useStateContext } from "contexts/ContextProvider";
import links from "./sidebarlinks";

const openedMixin = (theme, width) => ({
  width,
  background: "linear-gradient(60deg, #2b2b2b, #686868, #2b2b2b)",
  // background: "linear-gradient(60deg, #b38a29, #e8ac20, #795f22)",
  // background:
  // "linear-gradient(40deg, rgba(15, 15, 15, 1), rgba(44, 42, 42, 1), rgba(82, 79, 79, 0.9))",
  // background:
  //   "linear-gradient(40deg, rgba(66, 201, 116, 0.9), rgba(59, 152, 184, 1), rgba(26, 214, 164, 0.9))",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  scrollbarWidth: "0.3rem",
  msOverflowStyle: "none",
  "&::-webkit-scrollbar": {
    width: "0.3rem",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "gray",
  },
});

const closedMixin = (theme) => ({
  background: "linear-gradient(60deg, #2b2b2b, #686868, #2b2b2b)",
  // background: "linear-gradient(60deg, #b38a29, #e8ac20, #795f22)",

  // background: "linear-gradient(40deg, #e963fd, #8233c5, #274b74)",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  scrollbarWidth: "0.3rem",
  msOverflowStyle: "none",
  "&::-webkit-scrollbar": {
    width: "0.3rem",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "gray",
  },
  width: `calc(${theme.spacing(7)} + 1px)`,
  "@media (max-width: 600px)": {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  "@media (max-width: 380px)": {
    width: 0,
  },
});

const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ width, theme, open }) => ({
  width,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  overflow: "auto",
  scrollbarWidth: "thin",
  msOverflowStyle: "none",
  "&::-webkit-scrollbar": {
    width: "0.5rem",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#53FDFD",
  },
  ...(open && {
    ...openedMixin(theme, width),
    "& .MuiDrawer-paper": openedMixin(theme, width),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

function SideBar({
  themeProp,
  drawerWidth,
  drawerOpenStatus,
  closeDrawerFunction,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const { auth } = useStateContext();

  const parsedRole = auth?.role ? JSON.parse(auth?.role) : null;

  const handleNavigate = (link) => {
    navigate(`/${link}`);
  };

  return (
    <Box
      sx={{
        backgroundColor: "blue",
        zIndex: 100,
        position: "fixed",
        overflow: "auto",
        scrollbarWidth: "thin",
        msOverflowStyle: "none",
        "&::-webkit-scrollbar": {
          width: "0.5rem",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#53FDFD",
        },
      }}
    >
      <StyledDrawer
        variant="permanent"
        width={drawerWidth}
        theme={themeProp}
        open={drawerOpenStatus}
      >
        <DrawerHeader theme={themeProp}>
          <IconButton onClick={closeDrawerFunction}>
            {themeProp.direction === "rtl" ? (
              <ChevronRightIcon sx={{ color: "#fff" }} />
            ) : (
              <ChevronLeftIcon sx={{ color: "#fff" }} />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider
          sx={{
            backgroundColor: "#fff",
          }}
        />
        {drawerOpenStatus ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              mt: 2,
              // mx: 2,
              color: "#fff",
              textAlign: "center",
              // border: "solid 1px #fff",
            }}
          >
            <Typography sx={{ fontWeight: "bold" }}>
              {`${auth?.firstName} ${auth?.lastName}`}
            </Typography>
            <Typography
              sx={{
                fontSize: "12px",
                wordBreak: "break-word",
                whiteSpace: "normal",
              }}
            >
              {auth?.officeId === 1 ? auth?.unitName : auth?.officeName}
            </Typography>
            <Typography
              sx={{
                fontSize: "10px",
                wordBreak: "break-word",
                whiteSpace: "normal",
              }}
            >
              {parsedRole
                ?.map(
                  (role) =>
                    role === "admin"
                      ? "Administrator"
                      : role === "sds" || role === "asds"
                      ? ""
                      : role
                          ?.toLowerCase()
                          .replace(/\b\w/g, (match) => match.toUpperCase()) // Capitalizes the first letter of each word
                )
                .join(", ")}
            </Typography>
          </Box>
        ) : (
          <Box />
        )}
        <List
          sx={{
            pb: 4,
            "@media (min-height: 1920px)": {
              mt: 6,
            },
          }}
        >
          {links.map(
            (item) =>
              (item.role === "superadmin" || // Display items for superadmin to all users
                (parsedRole?.some((role) => ["admin"].includes(role)) &&
                  item.role === "admin") || // Display items for admin to admin users
                item.role === "transmittingUsers") && (
                <ListItem
                  key={item.title}
                  disablePadding
                  sx={{ display: "block" }}
                >
                  {parsedRole?.some((role) =>
                    item?.links?.some((link) => link?.access?.includes(role))
                  ) && (
                    <Box
                      sx={{
                        ml: drawerOpenStatus ? 2 : 0,
                        my: 2,
                        color: "#fff",
                      }}
                    >
                      <Typography>
                        {drawerOpenStatus ? (
                          item.title
                        ) : (
                          <Divider
                            sx={{
                              backgroundColor: "#fff",
                            }}
                          />
                        )}
                      </Typography>
                    </Box>
                  )}
                  {item.links.map(
                    (link) =>
                      ((auth?.unitId === 12 &&
                        parsedRole?.some((role) =>
                          link?.access?.includes(role)
                        )) ||
                        parsedRole?.some((role) =>
                          link?.access?.includes(role)
                        )) && (
                        <Tooltip
                          title={!drawerOpenStatus && link.name}
                          placement="right"
                          key={link.path}
                        >
                          <ListItemButton
                            onClick={() => handleNavigate(link.path)}
                            sx={{
                              py: 0.3,
                              justifyContent: drawerOpenStatus
                                ? "initial"
                                : "center",
                              px: 2.5,
                              borderRadius: "10px",
                              background:
                                `/${link.path}` === location.pathname
                                  ? "linear-gradient(180deg, #d2d2d2, #cecece, #b2b2b2)"
                                  : "transparent",
                              "&:hover": {
                                backgroundColor: "gray",
                                color: "#59395c",
                              },
                            }}
                          >
                            <ListItemIcon
                              sx={{
                                color: "#fff",
                                minWidth: 0,
                                mr: drawerOpenStatus ? 3 : "auto",
                                justifyContent: "center",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "30px",
                                  color:
                                    `/${link.path}` === location.pathname
                                      ? "black"
                                      : "#fff",
                                }}
                              >
                                {link.icon}
                              </Typography>
                            </ListItemIcon>
                            <ListItemText
                              sx={{
                                opacity: drawerOpenStatus ? 1 : 0,
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  color:
                                    `/${link.path}` === location.pathname
                                      ? "black"
                                      : "#fff",
                                  fontWeight:
                                    `/${link.path}` === location.pathname
                                      ? "bold"
                                      : "normal",
                                }}
                              >
                                {link.name}
                              </Typography>
                            </ListItemText>
                          </ListItemButton>
                        </Tooltip>
                      )
                  )}
                </ListItem>
              )
          )}
        </List>
      </StyledDrawer>
    </Box>
  );
}

export default SideBar;
