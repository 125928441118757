/* eslint-disable no-alert */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useStateContext } from "contexts/ContextProvider";

import { AiFillSignature } from "react-icons/ai";
import CheckIcon from "@mui/icons-material/Check";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";

import useAxiosPrivate from "contexts/interceptors/axios";
import ReturnDocumentModal from "modals/documents/ReturnDocumentModal";
import { enqueueSnackbar } from "notistack";
import PageTemplate from "layouts/PageTemplate";
import DocumentsTable from "../DocumentsTable";

export default function SignatureDocuments() {
  const { auth, referenceId } = useStateContext();
  const axiosPrivate = useAxiosPrivate();
  const [documents, setDocuments] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [openReturnModal, setOpenReturnModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleGetAll = () => {
    setLoading(true);
    setError("");

    axiosPrivate
      .get(`/documents/getAllDocuments`, {
        params: { referenceId },
      })
      .then((res) => {
        res.data.forEach((doc) => {
          if (typeof doc.action === "string") {
            doc.action = JSON.parse(doc.action);
          }
        });

        const filteredForSigning = res.data.filter((doc) => {
          const destinations = JSON.parse(doc.destinations);

          if (
            destinations.some(
              (dest) => dest.id === referenceId && dest.type === "unit"
            ) &&
            doc.status === 7 &&
            doc.acceptStatus === 1
          ) {
            return true;
          }
          return false;
        });

        // Sort documents by complexity (3 - 1)
        filteredForSigning.sort((a, b) => b.complexity - a.complexity);

        setDocuments(filteredForSigning);
      })
      .catch((err) => {
        setError(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleGetAll();
  }, []);

  const handleMoveToIncoming = () => {
    const confirmed = window.confirm(
      `Are you sure you want to move ${selectedData > 1 ? "these" : "this"} ${
        selectedData > 1 ? "documents" : "document"
      } to your Incoming?`
    );

    if (confirmed) {
      setLoading(true);
      setError("");

      axiosPrivate
        .patch(`/documents/patchUpdate`, {
          documents: selectedData,
          updateFields: {
            status: 1,
            acceptStatus: 0,
            currentOwner: 0,
            remarks: `Moved to Incoming by ${auth?.firstName} ${
              auth?.lastName
            } from ${auth?.officeId === 1 ? auth?.unitName : auth?.officeName}`,
          },
        })
        .then(() => {
          handleGetAll();
          enqueueSnackbar("Document Moved to Incoming", {
            variant: "success",
          });
        })
        .catch((err) => {
          setError(err.response.data.error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    setSelectedData(null);
  };

  const handleAccept = () => {
    const confirmed = window.confirm(
      `Are you sure you want to move ${selectedData > 1 ? "these" : "this"} ${
        selectedData > 1 ? "documents" : "document"
      } to your Pending?`
    );

    if (confirmed) {
      setLoading(true);
      setError("");

      axiosPrivate
        .patch(`/documents/patchUpdate`, {
          documents: selectedData,
          updateFields: {
            status: 3,
            acceptStatus: 1,
            remarks: `Moved to Pending by ${auth?.firstName} ${
              auth?.lastName
            } from ${auth?.officeId === 1 ? auth?.unitName : auth?.officeName}`,
          },
        })
        .then(() => {
          handleGetAll();
          enqueueSnackbar(
            `${selectedData > 1 ? "Documents" : "Document"} Moved`,
            {
              variant: "success",
            }
          );
        })
        .catch((err) => {
          setError(err.response.data.error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    setSelectedData(null);
  };

  // font "Mont"

  return (
    <PageTemplate
      icon={
        <AiFillSignature
          style={{
            fontSize: "40px",
          }}
        />
      }
      header="Documents for Signature"
      modals={[
        <ReturnDocumentModal
          open={openReturnModal}
          handleClose={() => {
            setSelectedData(null);
            setOpenReturnModal(false);
          }}
          loadingState={loading}
          selectedData={selectedData || null}
          updateTableFunction={() => handleGetAll()}
        />,
      ]}
      error={error}
      leftButtons={[
        auth?.unitId !== 1 &&
          auth?.unitId !== 2 && [
            <Button
              disabled={loading || selectedData?.length === 0}
              onClick={() => handleAccept()}
              sx={{
                backgroundColor:
                  loading || selectedData?.length === 0 ? "lightgray" : "green",
                color: "#fff",
                fontSize: "14px",
                fontWeight: "bold",
                padding: "5px 20px",
                borderRadius: "15px",
                "&:hover": {
                  backgroundColor: "lightgray",
                  color: "#2f2f2f",
                  boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
                },
              }}
            >
              <CheckIcon sx={{ mr: 1 }} />
              Move to Pending
            </Button>,
            <Button
              disabled={loading || selectedData?.length === 0}
              onClick={() => handleMoveToIncoming()}
              sx={{
                backgroundColor:
                  loading || selectedData?.length === 0
                    ? "lightgray"
                    : "#184c8c",
                color: "#fff",
                fontSize: "14px",
                fontWeight: "bold",
                padding: "5px 20px",
                borderRadius: "15px",
                "&:hover": {
                  backgroundColor: "lightgray",
                  color: "#2f2f2f",
                  boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
                },
              }}
            >
              <ShortcutIcon sx={{ mr: 1 }} />
              Move to Incoming
            </Button>,
          ],
        (auth?.unitId === 1 || auth?.unitId === 2) && (
          <Button
            disabled={
              loading ||
              selectedData?.length === 0 ||
              selectedData?.length > 1 ||
              !selectedData ||
              selectedData[0]?.status === 2 ||
              selectedData[0]?.signedDateTime
            }
            onClick={() => setOpenReturnModal(true)}
            sx={{
              backgroundColor:
                loading ||
                selectedData?.length === 0 ||
                selectedData?.length > 1 ||
                !selectedData ||
                selectedData[0]?.status === 2 ||
                selectedData[0]?.signedDateTime
                  ? "lightgray"
                  : "#da2c43",
              color: "#fff",
              fontSize: "14px",
              fontWeight: "bold",
              padding: "5px 20px",
              borderRadius: "15px",
              "&:hover": {
                backgroundColor: "lightgray",
                color: "#2f2f2f",
                boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
              },
            }}
          >
            <KeyboardReturnIcon sx={{ mr: 1 }} />
            Return
          </Button>
        ),
      ]}
      table={
        <DocumentsTable
          data={documents}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          showCheckbox
          showMultipleSelection
          singleSelect
          loadingState={loading}
          setLoadingState={setLoading}
          updateTableFunction={handleGetAll}
        />
      }
    />
  );
}
