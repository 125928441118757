/* eslint-disable no-alert */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";

import CheckIcon from "@mui/icons-material/Check";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";

import { useStateContext } from "contexts/ContextProvider";
import useAxiosPrivate from "contexts/interceptors/axios";
import ReturnDocumentModal from "modals/documents/ReturnDocumentModal";
import { enqueueSnackbar } from "notistack";
import PageTemplate from "layouts/PageTemplate";
import DocumentsTable from "../DocumentsTable";

export default function ReleaseDocuments() {
  const { auth, referenceId } = useStateContext();
  const axiosPrivate = useAxiosPrivate();
  const [documents, setDocuments] = useState([]);
  const [selectedData, setSelectedData] = useState(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [openReturnModal, setOpenReturnModal] = useState(false);

  // const SDSSecIds = [3, 4];
  // const ASDSSecIds = [7];

  const handleGetAll = () => {
    setLoading(true);
    setError("");

    axiosPrivate
      .get(`/documents/getAllDocuments`, {
        params: { referenceId },
      })
      .then((res) => {
        res.data.forEach((doc) => {
          if (typeof doc.action === "string") {
            doc.action = JSON.parse(doc.action);
          }
        });

        const filteredForReleaseDocs = res?.data?.filter((doc) => {
          const parsedLastSources = JSON.parse(doc?.lastSource);
          const parsedRelatedUnits = JSON.parse(auth?.relatedUnits);

          if (
            doc.currentOwner !== referenceId &&
            doc.status === 8 &&
            doc.acceptStatus === 0 &&
            parsedLastSources.some((last) =>
              parsedRelatedUnits.some((unit) => unit.id === last.id)
            )
          ) {
            return true;
          }
          return false;
        });

        setDocuments(filteredForReleaseDocs);
      })
      .catch((err) => {
        setError(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleGetAll();
  }, []);

  const handleRelease = () => {
    const confirmed = window.confirm(
      `Are you sure you want to release ${
        selectedData?.length > 1 ? "these" : "this"
      } ${selectedData?.length > 1 ? "documents" : "document"}?`
    );

    if (confirmed) {
      setLoading(true);
      setError("");

      axiosPrivate
        .patch(`/documents/routeDocuments`, {
          documents: selectedData,
          updateFields: {
            lastSource:
              auth?.officeId === 1
                ? {
                    id: auth?.unitId,
                    destination: auth?.unitName,
                    type: "unit",
                  }
                : {
                    id: auth?.officeId,
                    destination: auth?.officeName,
                    type: "office",
                  },
            status: 1,
            classification: 4,
            remarks: `Released by ${auth?.firstName} ${auth?.lastName} from ${
              auth?.officeId === 1 ? auth?.unitName : auth?.officeName
            }`,
          },
        })
        .then(() => {
          enqueueSnackbar(
            `Document${selectedData?.length > 1 ? "s" : ""} Routed`,
            {
              variant: "success",
            }
          );
          handleGetAll();
        })
        .catch((err) => {
          setError(err.response.data.error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    setSelectedData(null);
  };

  // font "Mont"

  return (
    <PageTemplate
      icon={
        <ThumbUpIcon
          sx={{
            fontSize: "40px",
          }}
        />
      }
      header="Documents for Release"
      modals={[
        <ReturnDocumentModal
          open={openReturnModal}
          handleClose={() => {
            setSelectedData(null);
            setOpenReturnModal(false);
          }}
          loadingState={loading}
          selectedData={selectedData || null}
          updateTableFunction={() => handleGetAll()}
        />,
      ]}
      error={error}
      leftButtons={[
        <Button
          disabled={
            loading ||
            selectedData?.length === 0 ||
            !selectedData ||
            selectedData[0]?.status === 2
          }
          onClick={() => handleRelease()}
          sx={{
            backgroundColor:
              loading ||
              selectedData?.length === 0 ||
              !selectedData ||
              selectedData[0]?.status === 2
                ? "lightgray"
                : "green",
            color: "#fff",
            fontSize: "14px",
            fontWeight: "bold",
            padding: "5px 20px",
            borderRadius: "15px",
            "&:hover": {
              backgroundColor: "lightgray",
              color: "#2f2f2f",
              boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
            },
          }}
        >
          <CheckIcon sx={{ mr: 1 }} />
          Release
        </Button>,
        <Button
          disabled={
            loading ||
            selectedData?.length === 0 ||
            selectedData?.length > 1 ||
            !selectedData ||
            selectedData[0]?.status === 2
          }
          onClick={() => setOpenReturnModal(true)}
          sx={{
            backgroundColor:
              loading ||
              selectedData?.length === 0 ||
              selectedData?.length > 1 ||
              !selectedData ||
              selectedData[0]?.status === 2
                ? "lightgray"
                : "#da2c43",
            color: "#fff",
            fontSize: "14px",
            fontWeight: "bold",
            padding: "5px 20px",
            borderRadius: "15px",
            "&:hover": {
              backgroundColor: "lightgray",
              color: "#2f2f2f",
              boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
            },
          }}
        >
          <KeyboardReturnIcon sx={{ mr: 1 }} />
          Return
        </Button>,
      ]}
      table={
        <DocumentsTable
          data={documents}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          showCheckbox
          showMultipleSelection
          loadingState={loading}
          setLoadingState={setLoading}
          updateTableFunction={handleGetAll}
        />
      }
    />
  );
}
