/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from "react";
import { useStateContext } from "contexts/ContextProvider";
import useAxiosPrivate from "contexts/interceptors/axios";

import FileCopyIcon from "@mui/icons-material/FileCopy";

import PageTemplate from "layouts/PageTemplate";
import DocumentsTable from "../DocumentsTable";

export default function UploadedDocuments() {
  const { auth, referenceId } = useStateContext();
  const axiosPrivate = useAxiosPrivate();
  const [documents, setDocuments] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleGetAll = () => {
    setLoading(true);
    setError("");

    axiosPrivate
      .get(`/documents/getAllDocuments`, {
        params: { referenceId },
      })
      .then((res) => {
        res.data.forEach((document) => {
          if (typeof document.action === "string") {
            document.action = JSON.parse(document.action);
          }
        });

        const filteredUploaded = res.data.filter((doc) => {
          const parsedPrimarySources = doc.primarySources
            ? JSON.parse(doc.primarySources)
            : [];

          if (
            parsedPrimarySources.some((prim) => {
              const isOffice = auth?.officeId !== 1 && prim?.type === "office";
              const isUnit = auth?.officeId === 1 && prim?.type === "unit";
              return prim?.id === referenceId && (isOffice || isUnit);
            })
          ) {
            return true;
          }
          return false;
        });

        setDocuments(filteredUploaded);
      })
      .catch((err) => {
        setError(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleGetAll();
  }, []);

  return (
    <PageTemplate
      icon={
        <FileCopyIcon
          style={{
            fontSize: "40px",
          }}
        />
      }
      header="Uploaded Documents"
      error={error}
      table={
        <DocumentsTable
          data={documents}
          loadingState={loading}
          setLoadingState={setLoading}
          updateTableFunction={handleGetAll}
        />
      }
    />
  );
}
