/* eslint-disable no-new */
import { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import RequireAuth from "contexts/RequireAuth";
import { useStateContext } from "contexts/ContextProvider";
import useAxiosPrivate from "contexts/interceptors/axios";
import { enqueueSnackbar } from "notistack";
import socket from "contexts/socket";
import {
  Missing,
  Unauthorized,
  Dashboard,
  Login,
  DocTypes,
  Units,
  Offices,
  Users,
  Outgoing,
  Incoming,
  Pending,
  Saved,
  Lapsed,
  Onhold,
  Signed,
  Uploaded,
  Routing,
  Signature,
  Release,
  RoutedIn,
  RoutedOut,
  AllDocuments,
} from "./pages";
import AdminLayout from "./layouts/MainLayout";

function App() {
  const axiosPrivate = useAxiosPrivate();
  const { auth, sessionExpired, setUsersRelatedUnits } = useStateContext();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (auth && location.pathname === "/") {
      navigate("/dashboard");
    }
  }, [auth]);

  const mainUsersRole = [
    "admin",
    "sds",
    "asds",
    "secretary",
    "chief",
    "unit head", // Unit/Section Heads
    "unit employee",
    "school personnel",
  ];

  useEffect(() => {
    // const refreshTokenExists = checkRefreshTokenInCookie();
    // if (!refreshTokenExists) {
    //   console.log(refreshTokenExists);
    // }

    // console.log(refreshTokenExists);
    if (sessionExpired) {
      alert("Session has expired. Please log in again");
      navigate("/login", { state: { from: location }, replace: true });
    }
  }, [sessionExpired]);

  const handleGetAllUsers = () => {
    axiosPrivate
      .get(`/user/getAllUsers`)
      .then((e) => {
        const usersWithRelatedUnits = e?.data
          ?.filter((user) => user.relatedUnits)
          .map((user) => user.relatedUnits);

        setUsersRelatedUnits(
          usersWithRelatedUnits.map((user) => JSON.parse(user))
        );
      })
      .catch((err) => {
        enqueueSnackbar(err?.message, {
          variant: "error",
        });
      });
  };

  useEffect(() => {
    handleGetAllUsers();
  }, []);

  useEffect(() => {
    if (auth) {
      socket.on("documentNotif", (data) => {
        if (data?.recipient?.id === auth?.unitId) {
          alert(data?.message);
          // useMemo(() => Audio(), []);
        }
      });
    } else {
      socket.off("documentNotif");
    }
  }, [socket]);

  useEffect(() => {
    if (auth) {
      // socket.emit("joinRoom", `room-${auth?.unitId}`, () => {
      //   console.log(
      //     `User ${auth?.firstName} ${auth?.lastName} joined room ${auth?.unitName}`
      //   );
      // });
      // join room
      socket.emit("joinRoom", `room-${auth?.unitId}`);
    }
  }, []);

  return (
    <Box>
      <Box
        sx={{
          // backgroundColor: location.pathname === "/login" ? "#2f2f2f" : "gray",
          position: "fixed",
          bottom: 0,
          right: 0,
          zIndex: 1001,
        }}
      >
        <Typography
          sx={{
            fontSize: "12px",
            color: location.pathname === "/login" ? "#fff" : "#2f2f2f",
          }}
        >
          Patch 01242024-015
        </Typography>
      </Box>
      <Routes>
        <Route path="/login" element={<Login />} />

        <Route element={<RequireAuth allowedRoles={mainUsersRole} />}>
          <Route path="/" element={<AdminLayout />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/outgoing" element={<Outgoing />} />
            <Route path="/incoming" element={<Incoming />} />
            <Route path="/pending" element={<Pending />} />
            <Route path="/saved" element={<Saved />} />
            <Route path="/lapsed" element={<Lapsed />} />
            <Route path="/onhold" element={<Onhold />} />
            <Route path="/uploaded" element={<Uploaded />} />

            <Route path="/routing" element={<Routing />} />
            <Route path="/signature" element={<Signature />} />
            <Route path="/signed" element={<Signed />} />
            <Route path="/routedIn" element={<RoutedIn />} />
            <Route path="/routedOut" element={<RoutedOut />} />

            {/* secretary */}
            <Route path="/release" element={<Release />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={["admin"]} />}>
            <Route path="/" element={<AdminLayout />}>
              <Route path="/doc-types" element={<DocTypes />} />
              <Route path="/units" element={<Units />} />
              <Route path="/offices" element={<Offices />} />
              <Route path="/users" element={<Users />} />
              <Route path="/all-documents" element={<AllDocuments />} />
            </Route>
          </Route>
        </Route>

        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route path="*" element={<Missing />} />
      </Routes>
    </Box>
  );
}

export default App;
