/* eslint-disable no-else-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { Box, Tooltip, Typography } from "@mui/material";

import { PDFDocument } from "pdf-lib"; // or use 'pdfjs-dist'

// Icons
import { AiFillSignature } from "react-icons/ai";
import ChecklistIcon from "@mui/icons-material/Checklist";
// import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { FaRegCircleCheck } from "react-icons/fa6";
import VisibilityIcon from "@mui/icons-material/Visibility";

import EditableTable from "components/Table/EditableTable";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import useAxiosPrivate from "contexts/interceptors/axios";
import ViewDocumentModal from "modals/documents/ViewDocumentModal";
import ViewDetailsModal from "modals/documents/ViewDetailsModal";
import { useStateContext } from "contexts/ContextProvider";
import ManualSignModal from "modals/documents/SigningModals/ManualSignModal";
import AutoSignModal from "modals/documents/SigningModals/AutoSignModal";
// import { enqueueSnackbar } from "notistack";
import RouteDocumentModal from "modals/documents/RouteDocumentModal";

dayjs.extend(utc);
dayjs.extend(timezone);

export default function DocumentsTable({
  data,
  selectedData,
  setSelectedData = () => {},
  singleSelect = false,
  showCheckbox = false,
  showMultipleSelection = false,
  loadingState,
  setLoadingState,
  updateTableFunction,
}) {
  const { auth, referenceId, setRoutingDocs } = useStateContext();
  const axiosPrivate = useAxiosPrivate();
  const location = useLocation();

  const [openViewModal, setOpenViewModal] = useState(false);
  const [openViewDetailsModal, setOpenViewDetailsModal] = useState(false);
  const [openRouteModal, setOpenRouteModal] = useState(false);
  const [openManualSignModal, setOpenManualSignModal] = useState(false);
  const [openAutoSignModal, setOpenAutoSignModal] = useState(false);

  const [pageToSign, setPageToSign] = useState(1);
  const [pdfUrl, setPdfUrl] = useState();

  const [dataFromActions, setDataFromActions] = useState();

  const [loading, setLoading] = useState(loadingState);
  const [error, setError] = useState("");

  const SDSSecIds = [3, 4];
  const ASDSSecIds = [7];

  const handleUpdateRoutingDocs = () => {
    setLoading(true);
    setError("");

    axiosPrivate
      .get(`/documents/getAllDocuments`, {
        params: { referenceId },
      })
      .then((res) => {
        res.data.forEach((doc) => {
          if (typeof doc.action === "string") {
            // eslint-disable-next-line no-param-reassign
            doc.action = JSON.parse(doc.action);
          }
        });

        const filteredForRouting = res.data.filter((doc) => {
          const destinations = JSON.parse(doc.destinations);

          if (
            destinations.some(
              (dest) => dest.id === auth.unitId && dest.type === "unit"
            ) &&
            // doc.currentOwner !== auth.unitId &&
            doc.status === 8 &&
            doc.acceptStatus === 1
          ) {
            return true;
          }
          return false;
        });

        // Sort documents by complexity (3 - 1)
        filteredForRouting.sort((a, b) => b.complexity - a.complexity);

        setRoutingDocs(filteredForRouting);
      })
      .catch((err) => {
        setError(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSignDocument = async (signMethod) => {
    setLoading(true);

    const { files } = dataFromActions;

    const parsedFileDocuments = files ? JSON.parse(files) : [];

    const fileName = parsedFileDocuments[parsedFileDocuments.length - 1];

    await axiosPrivate
      .get(`/documents/displayDocumentAsBlob`, {
        params: { fileName },
        responseType: "blob",
      })
      .then(async (res) => {
        const url = window.URL.createObjectURL(
          new Blob([res?.data], { type: "application/pdf" })
        );

        // Convert Blob to ArrayBuffer
        const arrayBuffer = await res.data.arrayBuffer();

        // Load the PDF document using pdf-lib
        const pdfDoc = await PDFDocument.load(arrayBuffer);
        const numberOfPages = pdfDoc.getPageCount();

        setPdfUrl(url);
        setDataFromActions({ ...dataFromActions, numberOfPages });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
        if (signMethod === "auto") {
          setOpenAutoSignModal(true);
        } else if (signMethod === "manual") {
          setOpenManualSignModal(true);
        }
      });
  };

  const handleViewFile = async (rowData) => {
    setOpenViewModal(true);
    setDataFromActions(rowData);
  };

  const handleViewDetails = async (rowData) => {
    if (rowData) {
      setDataFromActions(rowData);
      setOpenViewDetailsModal(true);
    }
  };

  const columns = [
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 150,
      getActions: (params) => {
        const { files } = params.row;

        const parsedFileNames = JSON.parse(files);

        const actionItems = [];

        actionItems.push(
          <Tooltip
            key="view"
            title={
              parsedFileNames[0] === ""
                ? "No file attached"
                : location.pathname === "/signature"
                ? "Sign"
                : location.pathname === "/signed"
                ? "Unsign"
                : "View"
            }
            placement="top"
          >
            <GridActionsCellItem
              disabled={parsedFileNames[0] === ""}
              icon={
                location.pathname === "/signature" ? (
                  <AiFillSignature style={{ fontSize: "19px" }} />
                ) : (
                  <VisibilityIcon />
                )
              }
              label="View"
              onClick={() => handleViewFile(params.row)}
              color="inherit"
            />
          </Tooltip>,
          <Tooltip key="viewDetails" title="View Details" placement="top">
            <GridActionsCellItem
              icon={<ChecklistIcon />}
              label="View Details"
              onClick={() => handleViewDetails(params.row)}
              color="inherit"
            />
          </Tooltip>
        );

        return actionItems;
      },
    },
    { field: "id", headerName: "ID", width: 70 },
    { field: "lpsNo", headerName: "LPS. No.", width: 120 },
    { field: "docType", headerName: "Doc Type", width: 180 },
    { field: "title", headerName: "Doc Title/Details", width: 250 },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      renderCell: (params) => {
        const {
          primarySources,
          lastSource,
          destinations,
          acceptStatus,
          autoInitials,
          manualInitials,
          complied,
          routedBy,
          createdAtDateTime,
        } = params.row;

        const parsedPrimarySources = JSON.parse(primarySources);
        const parsedLastSources = JSON.parse(lastSource);
        const parsedDestinations = JSON.parse(destinations);

        let chosenLastSource;

        if (parsedLastSources.length === 1) {
          chosenLastSource = parsedLastSources ? parsedLastSources[0] : null;
        } else {
          chosenLastSource = parsedLastSources ? parsedLastSources[1] : null;
        }

        const currentDateTime = new Date();
        const createdAtDateTimeObj = new Date(createdAtDateTime);
        // const lastUpdateDateTimeObj = new Date(lastUpdateDateTime);

        const fifteenDaysInMilliseconds = 15 * 24 * 60 * 60 * 1000;

        if (params.value === 2) {
          return (
            <Typography
              sx={{
                fontWeight: "bold",
                color: "red",
              }}
            >
              Returned
            </Typography>
          );
        } else if (location.pathname === "/incoming") {
          return "Incoming";
        } else if (location.pathname === "/outgoing") {
          return "Outgoing";
        } else if (params.value === 1) {
          if (
            parsedDestinations.some(
              (dest) =>
                dest.id === chosenLastSource.id || dest.id === referenceId
            )
          ) {
            return "Incoming";
          } else {
            return "Outgoing";
          }
        } else if (params.value === 3) {
          if (createdAtDateTime) {
            if (
              currentDateTime.getTime() >=
                createdAtDateTimeObj.getTime() + fifteenDaysInMilliseconds &&
              !autoInitials &&
              !manualInitials &&
              !complied &&
              !routedBy
            ) {
              return (
                <Typography
                  sx={{
                    fontWeight: "bold",
                    color: "red",
                  }}
                >
                  Lapsed
                </Typography>
              );
            }
          }

          return (
            <Typography
              sx={{
                fontWeight: "bold",
                color: "green",
              }}
            >
              Accepted
            </Typography>
          );
        } else if (params.value === 4) {
          return (
            <Typography
              sx={{
                fontWeight: "bold",
                color: "#0d85ee",
              }}
            >
              Saved
            </Typography>
          );
        } else if (params.value === 5) {
          return (
            <Typography
              sx={{
                fontWeight: "bold",
                color: "#c49019",
              }}
            >
              On-Hold
            </Typography>
          );
        } else if (params.value === 6) {
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                color: "green",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  mr: 1,
                }}
              >
                Signed
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "20px",
                }}
              >
                <FaRegCircleCheck />
              </Box>
            </Box>
          );
        } else if (params.value === 7) {
          if (acceptStatus === 1) {
            return (
              <Typography
                sx={{
                  fontWeight: "bold",
                }}
              >
                Unsigned
              </Typography>
            );
          } else if (
            parsedDestinations.some((dest) => dest.id === chosenLastSource.id)
          ) {
            return "Incoming";
          } else if (
            parsedPrimarySources.some(
              (prim) => prim?.id === chosenLastSource.id
            ) ||
            chosenLastSource.id === referenceId
          ) {
            return "Outgoing";
          } else {
            return "Incoming";
          }
        } else if (params.value === 8) {
          if (acceptStatus === 1) {
            return (
              <Typography
                sx={{
                  fontWeight: "bold",
                  color: "#c49019",
                }}
              >
                Routing
              </Typography>
            );
          } else if (
            parsedDestinations.some((dest) => dest.id === chosenLastSource.id)
          ) {
            return "Incoming";
          } else if (
            parsedPrimarySources.some(
              (prim) => prim?.id === chosenLastSource.id
            ) ||
            chosenLastSource.id === referenceId
          ) {
            return "Outgoing";
          } else {
            return "Incoming";
          }
        } else if (params.value === 9) {
          return (
            <Typography
              sx={{
                fontWeight: "bold",
                color: "#246fc9",
              }}
            >
              Routed
            </Typography>
          );
        } else if (params.value === 10) {
          return (
            <Typography
              sx={{
                fontWeight: "bold",
                color: "#656791",
              }}
            >
              Initialized
            </Typography>
          );
        }
        return null; // Default return if none of the conditions match
      },
    },
    {
      field: "complexity",
      headerName: "Complexity",
      width: 100,
      renderCell: (params) => {
        const complexity = params.value;

        if (complexity === 1) {
          return "Simple";
        } else if (complexity === 2) {
          return (
            <Typography sx={{ color: "#a88921", fontWeight: "bold" }}>
              Complex
            </Typography>
          );
        } else if (complexity === 3) {
          return (
            <Typography sx={{ color: "red", fontWeight: "bold" }}>
              Urgent
            </Typography>
          );
        }

        return null;
      },
    },
    {
      field: "signComplianceStatus",
      headerName: "Sign/Compliance Status",
      width: 200,
      renderCell: (params) => {
        const { autoInitials, manualInitials, complied, signedDateTime } =
          params.row;

        const parsedAutoInitials = autoInitials ? JSON.parse(autoInitials) : [];
        const parsedManualInitials = manualInitials
          ? JSON.parse(manualInitials)
          : [];
        const parsedComplied = complied ? JSON.parse(complied) : [];

        const combinedSignatories = [
          ...parsedAutoInitials,
          ...parsedManualInitials,
        ];

        const signatoriesIds = combinedSignatories.map(
          (signatory) => signatory?.id
        );

        let isInitialized = false;

        if (SDSSecIds.includes(auth?.unitId)) {
          if (signatoriesIds.includes(1)) {
            isInitialized = true;
          }
        } else if (ASDSSecIds.includes(auth?.unitId)) {
          if (signatoriesIds.includes(2)) {
            isInitialized = true;
          }
        } else if (signatoriesIds.includes(auth?.unitId)) {
          isInitialized = true;
        }

        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              width: "100%",
              overflowX: "auto",
              "&::-webkit-scrollbar": {
                height: "6px", // Adjusts the scrollbar height
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#888", // Adjusts the scrollbar color
                borderRadius: "4px", // Optional: Rounds the corners of the scrollbar
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "#555", // Color on hover
              },
            }}
          >
            {(combinedSignatories.some((signatory) => signatory.id === 1) &&
              signedDateTime) ||
            signedDateTime ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "green",
                  mr: 1,
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    mr: 1,
                  }}
                >
                  Signed
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "20px",
                  }}
                >
                  <FaRegCircleCheck />
                </Box>
              </Box>
            ) : isInitialized ? (
              <Typography
                sx={{
                  fontWeight: "bold",
                  color: "#656791",
                  mr: 1,
                }}
              >
                Initialized
              </Typography>
            ) : null}

            {parsedComplied.map((unit) => unit.id).includes(referenceId) && (
              <Typography
                sx={{
                  fontWeight: "bold",
                  color: "#0d91cb",
                  mr: 1,
                }}
              >
                Complied
              </Typography>
            )}
          </Box>
        );
      },
    },
    {
      field: "classification",
      headerName: "Classification",
      width: 120,
      renderCell: (params) => {
        const classification = params.value;

        if (classification === 1) {
          return "For Signing";
        } else if (classification === 2) {
          return "For Routing"; // routed out
        } else if (classification === 3) {
          return "For Checking";
        } else if (classification === 4) {
          return (
            <Typography
              sx={{
                fontWeight: "bold",
                color: "#246fc9",
              }}
            >
              Routed
            </Typography>
          ); // routed in
        } else if (classification === 5) {
          return (
            <Typography
              sx={{
                fontWeight: "bold",
                color: "#074ccd",
              }}
            >
              Submitted
            </Typography>
          );
        } else {
          return null;
        }
      },
    },
    {
      field: "destinations",
      headerName: "Destination",
      width: 200,
      valueGetter: (params) => {
        const parsedDestinations = JSON.parse(params.value);

        return parsedDestinations
          .map((destination) => destination.destination)
          .join(", ");
      },
    },
    {
      field: "primarySources",
      headerName: "Primary Source",
      width: 200,
      valueGetter: (params) => {
        const parsedPrimSources = JSON.parse(params.value);

        const primaryNames = parsedPrimSources.map((prim) => prim.destination);

        const primaryString = primaryNames.join(", ");

        return primaryString;
      },
    },
    {
      field: "lastSource",
      headerName: "Last Source",
      width: 200,
      valueGetter: (params) => {
        const parsedLastSources = JSON.parse(params.value);

        let lastSource;

        if (parsedLastSources.length === 1) {
          lastSource = parsedLastSources ? parsedLastSources[0] : null;
        } else {
          lastSource = parsedLastSources ? parsedLastSources[1] : null;
        }

        return lastSource?.destination;
      },
    },
    {
      field: "createdAtDateTime",
      headerName: "Uploaded Timestamp",
      width: 180,
      valueGetter: (params) =>
        params.value ? dayjs(params.value).format("MM/DD/YYYY hh:mm A") : null,
    },
    {
      field: "lastUpdateDateTime",
      headerName: "Last Updated Timestamp",
      width: 180,
      valueGetter: (params) =>
        params.value ? dayjs(params.value).format("MM/DD/YYYY hh:mm A") : null,
    },
    {
      field: "firstAcceptedDateTime",
      headerName: "First Accepted Timestamp",
      width: 180,
      valueGetter: (params) =>
        params.value ? dayjs(params.value).format("MM/DD/YYYY hh:mm A") : null,
    },
    {
      field: "lastAcceptedDateTime",
      headerName: "Last Accepted Timestamp",
      width: 180,
      valueGetter: (params) =>
        params.value ? dayjs(params.value).format("MM/DD/YYYY hh:mm A") : null,
    },
    {
      field: "signedDateTime",
      headerName: "Signed Timestamp",
      width: 180,
      valueGetter: (params) =>
        params.value ? dayjs(params.value).format("MM/DD/YYYY hh:mm A") : null,
    },
    {
      field: "duration",
      headerName: "Duration",
      width: 120,
      valueGetter: (params) => {
        const { firstAcceptedDateTime, signedDateTime } = params.row;

        if (!firstAcceptedDateTime) return null;

        // const formattedAcceptTimeStamp = firstAcceptedDateTime
        //   ? dayjs(firstAcceptedDateTime)
        //   : null;
        // const formattedSignedTimeStamp = signedDateTime
        //   ? dayjs(signedDateTime)
        //   : null;

        const firstDate = new Date(firstAcceptedDateTime);
        const endDate = signedDateTime ? new Date(signedDateTime) : new Date();

        const diffInMilliseconds = new Date(endDate) - firstDate;

        const diffInDays = Math.floor(
          diffInMilliseconds / (1000 * 60 * 60 * 24)
        );

        return diffInDays;
      },
    },

    { field: "remarks", headerName: "Remarks", width: 250 },
    { field: "annotation", headerName: "Annotation", width: 250 },
  ];

  useEffect(() => {
    setLoading(loadingState);
  }, []);

  useEffect(() => {
    setLoadingState(loading);
  }, [loading]);

  return (
    <Box>
      <ManualSignModal
        open={openManualSignModal}
        handleClose={() => {
          setDataFromActions(null);
          setOpenManualSignModal(false);
        }}
        pageToSign={pageToSign}
        pdfUrl={pdfUrl}
        loadingState={loading}
        setOpenViewModal={setOpenViewModal}
        dataFromActions={dataFromActions || null}
        setDataFromActions={setDataFromActions}
        updateTableFunction={() => updateTableFunction()}
      />
      <AutoSignModal
        open={openAutoSignModal}
        handleClose={() => {
          setDataFromActions(null);
          setOpenAutoSignModal(false);
        }}
        pageToSign={pageToSign}
        pdfUrl={pdfUrl}
        loadingState={loading}
        setOpenAutoSignModal={setOpenAutoSignModal}
        dataFromActions={dataFromActions || null}
        setDataFromActions={setDataFromActions}
        updateTableFunction={() => updateTableFunction()}
      />
      <ViewDocumentModal
        open={openViewModal}
        handleClose={() => {
          setDataFromActions(null);
          setOpenViewModal(false);
        }}
        pageToSign={pageToSign}
        setPageToSign={setPageToSign}
        pdfUrl={pdfUrl}
        loadingState={loading}
        setOpenViewModal={setOpenViewModal}
        setOpenRouteModal={setOpenRouteModal}
        handleSignDocument={handleSignDocument}
        dataFromActions={dataFromActions || null}
        setDataFromActions={setDataFromActions}
        updateTableFunction={() => updateTableFunction()}
      />
      <ViewDetailsModal
        open={openViewDetailsModal}
        handleClose={() => {
          setDataFromActions(null);
          setOpenViewDetailsModal(false);
        }}
        dataFromActions={dataFromActions || null}
      />
      <RouteDocumentModal
        open={openRouteModal}
        handleClose={() => {
          setDataFromActions(null);
          setOpenRouteModal(false);
          setOpenViewModal(false);
        }}
        loadingState={loading}
        selectedData={[dataFromActions] || null}
        updateTableFunction={() => handleUpdateRoutingDocs()}
      />
      {error}
      <EditableTable
        data={data}
        columns={columns}
        checkbox={showCheckbox}
        multipleSelection={showMultipleSelection}
        // loading={loading || loadingState}
        loading={loadingState}
        // rowToDelete={setRowToDelete}
        singleSelect={singleSelect}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
        height="60vh"
        showSearch
      />
    </Box>
  );
}
