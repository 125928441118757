import React from "react";
import { Box, IconButton, Typography } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

export default function DocumentPreviewModal({
  description,
  setOpenDocPreviewModal,
  filePath,
}) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "Center",
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        height: "100%",
        width: "100%",
        zIndex: 1000,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "98%",
          width: "98%",
          backgroundColor: "#fff",
          overflow: "auto",
          p: 2,
          borderRadius: "10px",
          boxShadow: "3px 2px 20px 3px rgba(0, 0, 0, 0.4)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography>{description}</Typography>
          <IconButton onClick={() => setOpenDocPreviewModal(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        {filePath ? (
          <iframe
            src={filePath}
            title="PDF Viewer"
            height="100%"
            width="100%"
          />
        ) : (
          <p>No PDF Found</p>
        )}
      </Box>
    </Box>
  );
}
