/* eslint-disable no-alert */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";

import EditNoteIcon from "@mui/icons-material/EditNote";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import ShortcutIcon from "@mui/icons-material/Shortcut";

import { useStateContext } from "contexts/ContextProvider";
import useAxiosPrivate from "contexts/interceptors/axios";
import RouteDocumentModal from "modals/documents/RouteDocumentModal";
import AnnotateDocumentModal from "modals/documents/AnnotateDocumentModal";
import TransmitDocumentModal from "modals/documents/TransmitDocumentModal";
import ReturnDocumentModal from "modals/documents/ReturnDocumentModal";
import PageTemplate from "layouts/PageTemplate";
import DocumentsTable from "../DocumentsTable";

export default function RoutingDocuments() {
  const { auth, referenceId, routingDocs, setRoutingDocs } = useStateContext();
  const axiosPrivate = useAxiosPrivate();
  const [selectedData, setSelectedData] = useState(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [openReturnModal, setOpenReturnModal] = useState(false);
  const [openRouteModal, setOpenRouteModal] = useState(false);
  const [openTransmitModal, setOpenTransmitModal] = useState(false);
  const [openAnnotateModal, setOpenAnnotateModal] = useState(false);

  const handleGetAll = () => {
    setLoading(true);
    setError("");

    axiosPrivate
      .get(`/documents/getAllDocuments`, {
        params: { referenceId },
      })
      .then((res) => {
        res.data.forEach((doc) => {
          if (typeof doc.action === "string") {
            doc.action = JSON.parse(doc.action);
          }
        });

        const filteredForRouting = res.data.filter((doc) => {
          const destinations = JSON.parse(doc.destinations);

          if (
            destinations.some(
              (dest) => dest.id === auth.unitId && dest.type === "unit"
            ) &&
            // doc.currentOwner !== auth.unitId &&
            doc.status === 8 &&
            doc.acceptStatus === 1
          ) {
            return true;
          }
          return false;
        });

        // Sort documents by complexity (3 - 1)
        filteredForRouting.sort((a, b) => b.complexity - a.complexity);

        setRoutingDocs(filteredForRouting);
      })
      .catch((err) => {
        setError(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleGetAll();
  }, []);

  // font "Mont"

  return (
    <PageTemplate
      icon={
        <ShortcutIcon
          sx={{
            fontSize: "40px",
          }}
        />
      }
      header="Documents for Routing"
      modals={[
        <ReturnDocumentModal
          open={openReturnModal}
          handleClose={() => {
            setSelectedData(null);
            setOpenReturnModal(false);
          }}
          loadingState={loading}
          selectedData={selectedData || null}
          updateTableFunction={() => handleGetAll()}
        />,
        <TransmitDocumentModal
          open={openTransmitModal}
          handleClose={() => {
            setSelectedData(null);
            setOpenTransmitModal(false);
          }}
          loadingState={loading}
          selectedData={selectedData || null}
          updateTableFunction={() => handleGetAll()}
        />,
        <AnnotateDocumentModal
          open={openAnnotateModal}
          handleClose={() => {
            setSelectedData(null);
            setOpenAnnotateModal(false);
          }}
          loadingState={loading}
          selectedData={selectedData || null}
          updateTableFunction={() => handleGetAll()}
        />,
        <RouteDocumentModal
          open={openRouteModal}
          handleClose={() => {
            setSelectedData(null);
            setOpenRouteModal(false);
          }}
          loadingState={loading}
          selectedData={selectedData || null}
          updateTableFunction={() => handleGetAll()}
        />,
      ]}
      error={error}
      leftButtons={[
        auth?.unitId === 1 || auth?.unitId === 2 || auth?.unitId === 12 ? (
          <>
            <Button
              disabled={
                loading ||
                selectedData?.length === 0 ||
                !selectedData ||
                selectedData[0]?.status === 2
              }
              onClick={() => setOpenRouteModal(true)}
              sx={{
                backgroundColor:
                  loading ||
                  selectedData?.length === 0 ||
                  !selectedData ||
                  selectedData[0]?.status === 2
                    ? "lightgray"
                    : "#00a052",
                color: "#fff",
                fontSize: "14px",
                fontWeight: "bold",
                padding: "5px 20px",
                borderRadius: "15px",
                "&:hover": {
                  backgroundColor: "lightgray",
                  color: "#2f2f2f",
                  fontWeight: "bold",
                },
              }}
            >
              <ShortcutIcon sx={{ mr: 1 }} />
              Route
            </Button>
            <Button
              disabled={
                loading ||
                selectedData?.length === 0 ||
                selectedData?.length > 1 ||
                !selectedData ||
                selectedData[0]?.status === 2 ||
                selectedData[0]?.signedDateTime
              }
              onClick={() => setOpenReturnModal(true)}
              sx={{
                backgroundColor:
                  loading ||
                  selectedData?.length === 0 ||
                  selectedData?.length > 1 ||
                  !selectedData ||
                  selectedData[0]?.status === 2 ||
                  selectedData[0]?.signedDateTime
                    ? "lightgray"
                    : "#da2c43",
                color: "#fff",
                fontSize: "14px",
                fontWeight: "bold",
                padding: "5px 20px",
                borderRadius: "15px",
                "&:hover": {
                  backgroundColor: "lightgray",
                  color: "#2f2f2f",
                  boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
                },
              }}
            >
              <KeyboardReturnIcon sx={{ mr: 1 }} />
              Return
            </Button>
          </>
        ) : (
          <>
            <Button
              disabled={
                loading ||
                selectedData?.length === 0 ||
                selectedData?.length > 1 ||
                !selectedData ||
                selectedData[0].status === 2
              }
              onClick={() => setOpenAnnotateModal(true)}
              sx={{
                backgroundColor:
                  loading ||
                  selectedData?.length === 0 ||
                  selectedData?.length > 1 ||
                  !selectedData ||
                  selectedData[0].status === 2
                    ? "lightgray"
                    : "#4b51b1",
                color: "#fff",
                fontSize: "14px",
                fontWeight: "bold",
                padding: "5px 20px",
                borderRadius: "15px",
                "&:hover": {
                  backgroundColor: "lightgray",
                  color: "#2f2f2f",
                  fontWeight: "bold",
                },
              }}
            >
              <EditNoteIcon sx={{ mr: 1 }} />
              Annotate
            </Button>
            <Button
              disabled={
                loading ||
                selectedData?.length === 0 ||
                !selectedData ||
                selectedData[0].status === 2
              }
              onClick={() => setOpenTransmitModal(true)}
              sx={{
                backgroundColor:
                  loading ||
                  selectedData?.length === 0 ||
                  !selectedData ||
                  selectedData[0].status === 2
                    ? "lightgray"
                    : "#0e6b95",
                color: "#fff",
                fontSize: "14px",
                fontWeight: "bold",
                padding: "5px 20px",
                borderRadius: "15px",
                "&:hover": {
                  backgroundColor: "lightgray",
                  color: "#2f2f2f",
                  boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
                },
              }}
            >
              <ShortcutIcon sx={{ mr: 1 }} />
              Transmit
            </Button>
          </>
        ),
      ]}
      table={
        <DocumentsTable
          data={routingDocs}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          showCheckbox
          singleSelect
          loadingState={loading}
          setLoadingState={setLoading}
          updateTableFunction={handleGetAll}
        />
      }
    />
  );
}
