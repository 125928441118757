/* eslint-disable no-alert */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useStateContext } from "contexts/ContextProvider";
import useAxiosPrivate from "contexts/interceptors/axios";

import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import UploadIcon from "@mui/icons-material/Upload";

import { enqueueSnackbar } from "notistack";
import PageTemplate from "layouts/PageTemplate";
import UpdateFileModal from "modals/documents/UpdateFileModal";
import EditDocumentModal from "modals/documents/EditDocumentModal";
import DocumentsTable from "../DocumentsTable";

export default function OutgoingDocuments() {
  const { auth, referenceId } = useStateContext();
  const axiosPrivate = useAxiosPrivate();
  const [documents, setDocuments] = useState([]);
  const [selectedData, setSelectedData] = useState(null);

  const [openEditModal, setOpenEditModal] = useState(false);
  const [openUpdateFileModal, setOpenUpdateFileModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const SDSSecIds = [3, 4];
  const ASDSSecIds = [7];

  const handleGetAll = () => {
    setLoading(true);
    setError("");

    axiosPrivate
      .get(`/documents/getAllDocuments`, {
        params: { referenceId },
      })
      .then((res) => {
        res.data.forEach((document) => {
          if (typeof document.action === "string") {
            document.action = JSON.parse(document.action);
          }
        });

        const ownedDocs = res.data.filter(
          (doc) => doc.currentOwner === referenceId
        );

        const filteredOutgoing = ownedDocs.filter((doc) => {
          const parsedPrimarySources = JSON.parse(doc?.primarySources);
          const parsedLastSources = JSON.parse(doc?.lastSource);
          const parsedDestinations = JSON.parse(doc?.destinations);

          let lastSource;

          if (parsedLastSources.length === 1) {
            lastSource = parsedLastSources ? parsedLastSources[0] : null;
          } else {
            lastSource = parsedLastSources ? parsedLastSources[1] : null;
          }

          // if the user logged in is from school
          if (auth?.officeId !== 1) {
            if (
              (parsedPrimarySources.some(
                (prim) => prim?.id === referenceId && prim?.dest === "office"
              ) ||
                (lastSource?.id === referenceId &&
                  lastSource?.type === "office")) &&
              (doc.status === 1 || doc.status === 8) &&
              parsedDestinations.some((dest) => dest.id !== referenceId)
            ) {
              return true;
            }
          } else if (auth?.officeId === 1) {
            if (SDSSecIds.includes(referenceId)) {
              if (
                parsedDestinations.some(
                  (dest) => dest.id === 1 && dest.type === "unit"
                ) &&
                SDSSecIds.includes(doc.currentOwner) &&
                (doc.status === 1 || doc.status === 8)
              ) {
                return true;
              }
            } else if (ASDSSecIds.includes(referenceId)) {
              if (
                parsedDestinations.some(
                  (dest) => dest.id === 2 && dest.type === "unit"
                ) &&
                ASDSSecIds.includes(doc.currentOwner) &&
                (doc.status === 1 || doc.status === 8)
              ) {
                return true;
              }
            } else if (
              (parsedPrimarySources.some((prim) => prim?.id === referenceId) ||
                (lastSource?.id === referenceId &&
                  lastSource?.type === "unit")) &&
              (doc.status === 1 || doc.status === 8) &&
              parsedDestinations.some((dest) => dest.id !== referenceId)
            ) {
              return true;
            }
          }

          return null;
        });

        // Sort documents by complexity (3 - 1)
        filteredOutgoing.sort((a, b) => b.complexity - a.complexity);

        setDocuments(filteredOutgoing);
      })
      .catch((err) => {
        setError(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleGetAll();
  }, []);

  const handleSaveDocument = () => {
    const confirm = window.confirm(
      `Are you sure you want to save ${
        selectedData.length > 1 ? "these" : "this"
      } document${selectedData.length > 1 ? "s" : ""}`
    );

    if (confirm) {
      setLoading(true);
      setError("");

      axiosPrivate
        .put(`/documents/saveDocument`, {
          documents: selectedData,
          // Watch out for this
          updateFields: {
            destinations: JSON.stringify([
              {
                id: auth?.unitId,
                destination:
                  auth?.officeId === 1 ? auth?.unitName : auth?.officeName,
                type: auth?.officeId === 1 ? "unit" : "office",
              },
            ]),
            currentOwner: auth?.unitId,
            remarks: `Saved by ${auth?.firstName} ${auth?.lastName} from ${
              auth?.officeId === 1 ? auth?.unitName : auth?.officeName
            }`,
          },
        })
        .then(() => {
          handleGetAll();

          enqueueSnackbar("Document Saved", {
            variant: "info",
          });
        })
        .catch((err) => {
          setError(err?.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <PageTemplate
      icon={
        <UploadIcon
          sx={{
            fontSize: "40px",
          }}
        />
      }
      header="Outgoing Documents"
      modals={[
        <UpdateFileModal
          open={openUpdateFileModal}
          handleClose={() => {
            setSelectedData(null);
            setOpenUpdateFileModal(false);
          }}
          loadingState={loading}
          selectedData={selectedData || null}
          updateTableFunction={() => handleGetAll()}
        />,
        <EditDocumentModal
          open={openEditModal}
          handleClose={() => {
            setSelectedData(null);
            setOpenEditModal(false);
          }}
          loadingState={loading}
          selectedData={selectedData || null}
          setOpenUpdateFileModal={setOpenUpdateFileModal}
          setOpenEditModal={setOpenEditModal}
          updateTableFunction={() => handleGetAll()}
        />,
      ]}
      error={error}
      leftButtons={[
        auth?.unitId !== 1
          ? [
              <Button
                disabled={loading || !selectedData || selectedData.length === 0}
                onClick={() => handleSaveDocument()}
                sx={{
                  backgroundColor:
                    loading || !selectedData || selectedData.length === 0
                      ? "lightgray"
                      : "#00a8f3",
                  color: "#fff",
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "5px 20px",
                  borderRadius: "15px",
                  "&:hover": {
                    backgroundColor: "lightgray",
                    color: "#2f2f2f",
                    fontWeight: "bold",
                  },
                }}
              >
                <SaveIcon sx={{ mr: 1 }} />
                Save
              </Button>,
            ]
          : null,
        <Button
          disabled={
            loading || selectedData?.length === 0 || selectedData?.length > 1
          }
          onClick={() => setOpenEditModal(true)}
          sx={{
            backgroundColor:
              loading || selectedData?.length === 0 || selectedData?.length > 1
                ? "lightgray"
                : "#184c8c",
            color: "#fff",
            fontSize: "14px",
            fontWeight: "bold",
            padding: "5px 20px",
            borderRadius: "15px",
            "&:hover": {
              backgroundColor: "lightgray",
              color: "#2f2f2f",
              boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
            },
          }}
        >
          <EditIcon sx={{ mr: 1 }} />
          Revise
        </Button>,
      ]}
      table={
        <DocumentsTable
          data={documents}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          showCheckbox
          showMultipleSelection
          loadingState={loading}
          setLoadingState={setLoading}
          updateTableFunction={handleGetAll}
        />
      }
    />
  );
}
