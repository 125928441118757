/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from "react";
import { useStateContext } from "contexts/ContextProvider";
import useAxiosPrivate from "contexts/interceptors/axios";

import { TbPencilCheck } from "react-icons/tb";

import PageTemplate from "layouts/PageTemplate";
import DocumentsTable from "../DocumentsTable";

export default function SignedDocuments() {
  const { auth, referenceId } = useStateContext();
  const axiosPrivate = useAxiosPrivate();
  const [documents, setDocuments] = useState([]);
  const [selectedData, setSelectedData] = useState(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleGetAll = () => {
    setLoading(true);
    setError("");

    axiosPrivate
      .get(`/documents/getAllDocuments`, {
        params: { referenceId },
      })
      .then((res) => {
        res.data.forEach((document) => {
          if (typeof document.action === "string") {
            document.action = JSON.parse(document.action);
          }
        });

        let filteredSignedDocs = [];

        if (auth?.unitId === 1 || auth?.unitId === 2) {
          filteredSignedDocs = res.data.filter((doc) => {
            const parsedAutoInitials = doc.autoInitials
              ? JSON.parse(doc.autoInitials)
              : [];
            const parsedManualInitials = doc.manualInitials
              ? JSON.parse(doc.manualInitials)
              : [];

            const combinedSignatories = [
              ...parsedAutoInitials,
              ...parsedManualInitials,
            ];

            if (combinedSignatories.some((sign) => sign?.id === auth?.unitId)) {
              return true;
            }

            return false;
          });
        } else {
          filteredSignedDocs = res.data.filter((doc) => {
            const parsedPrimarySources = doc.primarySources
              ? JSON.parse(doc.primarySources)
              : [];

            if (
              doc?.signedDateTime &&
              parsedPrimarySources.some((prim) => {
                const isOffice =
                  auth?.officeId !== 1 && prim?.type === "office";
                const isUnit = auth?.officeId === 1 && prim?.type === "unit";
                return prim?.id === referenceId && (isOffice || isUnit);
              })
            ) {
              return true;
            }
            return false;
          });
        }

        setDocuments(filteredSignedDocs);
      })
      .catch((err) => {
        setError(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleGetAll();
  }, []);

  return (
    <PageTemplate
      icon={
        <TbPencilCheck
          style={{
            fontSize: "40px",
          }}
        />
      }
      header="Signed Documents"
      error={error}
      table={
        <DocumentsTable
          data={documents}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          loadingState={loading}
          setLoadingState={setLoading}
          updateTableFunction={handleGetAll}
        />
      }
    />
  );
}
