/* eslint-disable no-nested-ternary */
/* eslint-disable no-alert */
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";

import CancelIcon from "@mui/icons-material/Close";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";

import { useEffect, useState } from "react";
import { object, string } from "yup";
import { useFormik } from "formik";
import useAxiosPrivate from "contexts/interceptors/axios";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useStateContext } from "contexts/ContextProvider";
import { enqueueSnackbar } from "notistack";
import { useLocation } from "react-router-dom";
import SelectDestinations from "components/Textfields/SelectDestinations";

dayjs.extend(utc);
dayjs.extend(timezone);

const style = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  position: "absolute",
  backgroundColor: "#da2c43",
  // background:
  //   "linear-gradient(40deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8))",
  //   border: "solid 2px #46e3be",
  boxShadow: "3px 2px 20px 3px rgba(0, 0, 0, 0.3)",
  borderRadius: "10px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  overflow: "auto",
  height: "60vh",
  width: "65vw",
  minHeight: "300px",
  minWidth: "500px",
};

export default function ReturnDocumentModal({
  open,
  handleClose,
  updateTableFunction,
  loadingState,
  selectedData,
}) {
  const { auth } = useStateContext();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();
  const [disabled, setDisabled] = useState(false);
  const [lastSources, setLastSources] = useState([]);
  const [destination, setDestination] = useState();
  const [option, setOption] = useState(1);
  const [selected, setSelected] = useState();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const SDSSecIds = [3, 4];
  const ASDSSecIds = [7];

  const parsedRole = auth?.role ? JSON.parse(auth?.role) : null;

  const formik = useFormik({
    initialValues: {
      annotation: "",
    },

    validationSchema: object().shape({
      annotation: string(),
    }),
    onSubmit: (values) => {
      const confirmed = window.confirm(
        "Are you sure you want to return this document?"
      );

      if (confirmed) {
        setLoading(true);
        setError("");

        // const formattedDestination = {
        //   ...destination,
        //   unit: destination.unitName,
        // };

        axiosPrivate
          .put(`/documents/returnDocument`, {
            docuId: selected?.id,
            seqNo: selected?.seqNo,
            destinations: JSON.stringify([destination]),
            lastSource: {
              id: auth?.unitId,
              destination: auth?.unitName,
              type: auth?.officeId === 1 ? "unit" : "office",
            },
            // currentOwner: destination && destination?.id,

            ...(SDSSecIds.includes(auth?.unitId) && destination?.id === 1
              ? { currentOwner: 4 }
              : ASDSSecIds.includes(auth?.unitId) && destination?.id === 2
              ? { currentOwner: 7 }
              : destination?.id === 2),

            acceptStatus: 0,
            status: 2,
            classification:
              (selected?.classification === 3 ||
                selected?.classification === 4) &&
              (destination?.id === 1 || destination?.id === 2)
                ? 2
                : selected?.classification,
            annotation: {
              annotation: `Returned: ${values.annotation}`,
              annotatedBy: `${auth?.firstName} ${auth?.lastName} from ${
                auth?.officeId === 1 ? auth?.unitName : auth?.officeName
              }`,
            },
            remarks: `Returned by ${auth?.firstName} ${auth?.lastName} from ${
              auth?.officeId === 1 ? auth?.unitName : auth?.officeName
            }`,
          })
          .then(() => {
            enqueueSnackbar("Document Returned", {
              variant: "default",
            });
            formik.resetForm();
            updateTableFunction();
            handleClose();
          })
          .catch((err) => {
            setError(err.response.data.error);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
  });

  useEffect(() => {
    let rowData;
    let lastSource;

    if (selectedData) {
      if (Array.isArray(selectedData) && selectedData.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        rowData = selectedData[0];
      } else {
        rowData = selectedData;
      }

      if (selectedData.length === 0) {
        rowData = null;
      }
    }

    if (rowData) {
      const parsedlastSources = JSON.parse(rowData?.lastSource);

      setLastSources(parsedlastSources);

      if (parsedlastSources.length === 1) {
        lastSource = parsedlastSources ? parsedlastSources[0] : null;
      } else if (
        parsedRole?.some((role) => ["secretary"].includes(role)) &&
        location.pathname === "/incoming"
      ) {
        lastSource = parsedlastSources ? parsedlastSources[1] : null;
      } else {
        lastSource = parsedlastSources ? parsedlastSources[1] : null;
      }

      setDestination(lastSource);
      setSelected(rowData);
    }
  }, [selectedData]);

  useEffect(() => {
    setDisabled(formik.values.annotation && destination?.id !== auth?.unitId);
  }, [formik.values, destination]);

  useEffect(() => {
    setLoading(loadingState);
  }, [loadingState]);

  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
        // formik.resetForm();
        // setError("");
      }}
    >
      <Box sx={style}>
        {loading && (
          <Box
            sx={{
              ...style,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 100,
            }}
          >
            <CircularProgress sx={{ color: "white" }} />
          </Box>
        )}
        <Box
          variant="form"
          component="form"
          onSubmit={formik.handleSubmit}
          autoComplete="off"
          sx={style}
        >
          <Box>
            <Box // Fixed Header
              sx={{
                position: "sticky",
                top: 0,
                backgroundColor: "#660d0a",
                // boxShadow: "3px 2px 20px 3px rgba(0, 0, 0, 0.3)",
                boxShadow: "8px 8px 15px 3px rgba(0, 0, 0, 0.3)",
                borderBottomLeftRadius: "30%",
                borderBottomRightRadius: "200px",
                zIndex: 2,
                px: 4,
                mr: 4,
                mb: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  py: 2,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "25px",
                    color: "#fff",
                    py: 1,
                  }}
                >
                  Return Document
                </Typography>
                <IconButton
                  onClick={handleClose}
                  sx={{ color: "#fff", p: 0, m: 0 }}
                >
                  <CancelIcon />
                </IconButton>
              </Box>
            </Box>
            <Box
              sx={{
                backgroundColor: "#660d0a",
                // backgroundColor: "#fff",
                boxShadow: "8px 8px 15px 3px rgba(0, 0, 0, 0.3)",
                borderRadius: "10px",
                p: 2,
                mx: 4,
                mb: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mb: 2,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "15px",
                    color: "#fff",
                    mr: 2,
                  }}
                >
                  Returning to:
                </Typography>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue={option}
                    onChange={(evt) => {
                      setOption(Number(evt.target.value));
                    }}
                    row
                    sx={{
                      color: "#fff",
                      gap: 2,
                    }}
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label="Choose from available options"
                    />
                    <FormControlLabel
                      value={2}
                      control={<Radio />}
                      label="Choose specific unit"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>

              {destination?.id === auth?.unitId && (
                <Box sx={{ backgroundColor: "red" }}>
                  <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
                    NOTE: You may not return documents to yourself
                  </Typography>
                </Box>
              )}
              <Box
                sx={{
                  display: "block",
                  justifyContent: "space-between",
                  // background: "#fff",
                  backgroundColor: "#f0f0f0",
                  border: "solid 1px #b6b6b6",
                  borderRadius: "4px",
                  width: "100%",
                  mb: 2,
                  p: 2,
                }}
              >
                {option === 1 && (
                  <Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Checkbox
                        checked={destination?.id === lastSources[0]?.id}
                        onChange={() => setDestination(lastSources[0])}
                        // color="#fff"
                      />
                      <Typography
                        sx={{
                          fontWeight:
                            destination?.id === lastSources[0]?.id
                              ? "bold"
                              : "normal",
                          color:
                            destination?.id === lastSources[0]?.id
                              ? "black"
                              : "gray",
                        }}
                      >
                        {lastSources && lastSources[0]?.destination}
                      </Typography>
                    </Box>
                    {lastSources.length > 1 && (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Checkbox
                          checked={destination?.id === lastSources[1]?.id}
                          onChange={() => setDestination(lastSources[1])}
                          // color="#fff"
                        />
                        <Typography
                          sx={{
                            fontWeight:
                              destination?.id === lastSources[1]?.id
                                ? "bold"
                                : "normal",
                            color:
                              destination?.id === lastSources[1]?.id
                                ? "black"
                                : "gray",
                          }}
                        >
                          {lastSources && lastSources[1]?.destination}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                )}

                {option === 2 && (
                  <Box>
                    <SelectDestinations
                      label="Destination"
                      disabled={loading}
                      value={destination?.id}
                      onChange={(fieldName, selectedValue) => {
                        setDestination(selectedValue);
                      }}
                      error={option === 2 && Boolean(destination?.id === "")}
                      helperText={
                        <span style={{ color: "red" }}>
                          {option === 2 &&
                            Boolean(destination?.id === "") &&
                            "Destination is required"}
                        </span>
                      }
                      addSpecificUnits={
                        open &&
                        parsedRole?.some((role) =>
                          ["secretary"].includes(role)
                        ) &&
                        JSON.parse(auth?.relatedUnits)
                      }
                      // showOnlySpecificUnits={
                      //   parsedRole?.some((role) =>
                      //     ["secretary"].includes(role)
                      //   ) &&
                      //   selectedData &&
                      //   selectedData.some((doc) => !doc.signedDateTime)
                      //     ? SDSSecIds.includes(auth.unitId)
                      //       ? [1]
                      //       : ASDSSecIds.includes(auth.unitId)
                      //       ? [2]
                      //       : null
                      //     : null
                      // }
                      restrictOwnDestination={
                        auth?.officeId !== 1 ? "office" : "unit"
                      }
                      sx={{
                        width: "100%",
                        pr: 3,
                        "&:hover": {
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "black !important",
                          },
                        },
                      }}
                    />
                  </Box>
                )}
              </Box>
            </Box>

            <Box
              sx={{
                backgroundColor: "#fff",
                boxShadow: "8px 8px 15px 3px rgba(0, 0, 0, 0.3)",
                borderRadius: "10px",
                p: 2,
                mx: 4,
              }}
            >
              {error && (
                <Box
                  sx={{ backgroundColor: "red", width: "100%", mt: 2, px: 1 }}
                >
                  <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
                    {error}
                  </Typography>
                </Box>
              )}
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Annotation"
                    placeholder="State reason for returning the document..."
                    name="annotation"
                    variant="outlined"
                    disabled={loading}
                    value={formik.values.annotation}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBLur}
                    error={
                      formik.touched.annotation &&
                      Boolean(formik.errors.annotation)
                    }
                    helperText={
                      formik.touched.annotation && formik.errors.annotation
                    }
                    multiline
                    rows={4}
                    sx={{
                      width: "100%",
                      "&:hover": {
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "black !important",
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              p: 2,
              zIndex: 10,
              mt: 2,
            }}
          >
            <Button
              disabled={!disabled}
              type="submit"
              sx={{
                display: "flex",
                alignItems: "center",
                // backgroundColor: disabled ? "#660d0a" : "#59395c",
                backgroundColor: "#660d0a",
                color: "#fff",
                py: 1,
                px: 2,
                // width: "10vw",
                // minWidth: "100px",
                "&:hover": {
                  backgroundColor: "lightgray",
                  color: "red",
                  fontWeight: "bold",
                },
              }}
            >
              <KeyboardReturnIcon
                sx={{
                  mr: 1,
                }}
              />
              Return
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
