/* eslint-disable no-alert */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";

import CheckIcon from "@mui/icons-material/Check";
import DownloadIcon from "@mui/icons-material/FileDownload";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";

import { useStateContext } from "contexts/ContextProvider";
import useAxiosPrivate from "contexts/interceptors/axios";
import ReturnDocumentModal from "modals/documents/ReturnDocumentModal";
import AcceptDocumentsModal from "modals/documents/AcceptDocumentsModal";
import { enqueueSnackbar } from "notistack";
import PageTemplate from "layouts/PageTemplate";
import socket from "contexts/socket";
import DocumentsTable from "../DocumentsTable";

export default function IncomingDocuments() {
  const { auth, referenceId } = useStateContext();

  const axiosPrivate = useAxiosPrivate();
  const [documents, setDocuments] = useState([]);
  const [selectedData, setSelectedData] = useState(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [openAcceptModal, setOpenAcceptModal] = useState(false);
  const [openReturnModal, setOpenReturnModal] = useState(false);

  const SDSSecIds = [3, 4];
  const ASDSSecIds = [7];
  const manyDocuments = selectedData?.length > 1;

  const parsedRole = auth?.role ? JSON.parse(auth?.role) : null;

  const handleGetAll = () => {
    setLoading(true);
    setError("");

    axiosPrivate
      .get(`/documents/getAllDocuments`, {
        params: { referenceId },
      })
      .then((res) => {
        res.data.forEach((doc) => {
          if (typeof doc.action === "string") {
            doc.action = JSON.parse(doc.action);
          }
        });

        const filteredIncoming = res?.data?.filter((doc) => {
          const destinations = JSON.parse(doc?.destinations);

          // if the user logged in is from school
          if (auth?.officeId !== 1) {
            if (doc.status === 2) {
              return (
                destinations.some(
                  (dest) => dest.id === referenceId && dest.type === "office"
                ) && doc.status === 2
              );
            }
            if (
              destinations.some(
                (dest) => dest.id === referenceId && dest.type === "office"
              ) &&
              doc.currentOwner !== referenceId &&
              doc.status === 1
            ) {
              return true;
            }
          } else if (auth?.officeId === 1) {
            // Watch out for this
            if (doc.status === 2) {
              return (
                destinations.some(
                  (dest) => dest.id === referenceId && dest.type === "unit"
                ) && doc.status === 2
              );
            }
            if (SDSSecIds.includes(auth?.unitId)) {
              if (
                destinations.some(
                  (dest) => SDSSecIds.includes(dest.id) && dest.type === "unit"
                ) &&
                !SDSSecIds.includes(doc.currentOwner) &&
                doc.status === 1
              ) {
                return true;
              }
            } else if (ASDSSecIds.includes(auth?.unitId)) {
              if (
                destinations.some(
                  (dest) => ASDSSecIds.includes(dest.id) && dest.type === "unit"
                ) &&
                !ASDSSecIds.includes(doc.currentOwner) &&
                doc.status === 1
              ) {
                return true;
              }
            } else if (
              destinations.some(
                (dest) => dest.id === referenceId && dest.type === "unit"
              ) &&
              doc.currentOwner !== referenceId &&
              doc.status === 1
            ) {
              return true;
            }
          }

          return false;
        });

        // Sort documents by complexity (3 - 1)
        filteredIncoming.sort((a, b) => b.complexity - a.complexity);

        setDocuments(filteredIncoming);
      })
      .catch((err) => {
        setError(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleGetAll();
  }, []);

  const handleAccept = () => {
    if (
      selectedData.some((data) => !data.signedDateTime) &&
      parsedRole?.some((role) => ["unit head", "chief"].includes(role))
    ) {
      setOpenAcceptModal(true);
    } else {
      const confirmed = window.confirm(
        `Are you sure you want to accept ${manyDocuments ? "these" : "this"} ${
          manyDocuments ? "documents" : "document"
        }?`
      );

      if (confirmed) {
        setLoading(true);
        setError("");

        axiosPrivate
          .patch(`/documents/acceptDocs`, {
            documents: selectedData,
            updateFields: {
              // eslint-disable-next-line no-nested-ternary
              currentOwner: SDSSecIds.includes(auth?.unitId)
                ? 4
                : ASDSSecIds.includes(auth?.unitId)
                ? 7
                : referenceId,

              // Watch out for this
              ...(auth?.unitId !== 1 && auth?.unitId !== 2 && { status: 3 }),
              remarks: `Accepted by ${auth?.firstName} ${auth?.lastName} from ${
                auth?.officeId === 1 ? auth?.unitName : auth?.officeName
              }`,
            },
          })
          .then(() => {
            handleGetAll();
            enqueueSnackbar("Document Accepted", { variant: "success" });
          })
          .catch((err) => {
            setError(err.response.data.error);
          })
          .finally(() => {
            setLoading(false);
          });
      }
      setSelectedData(null);
    }
  };

  useEffect(() => {
    if (auth) {
      socket.on("documentNotif", (data) => {
        if (data?.recipient?.id === referenceId) {
          handleGetAll();
        }
      });
    } else {
      socket.off("documentNotif");
    }
  }, [socket]);

  // font "Mont"

  return (
    <PageTemplate
      icon={
        <DownloadIcon
          sx={{
            fontSize: "40px",
          }}
        />
      }
      header="Incoming Documents"
      modals={[
        <AcceptDocumentsModal
          open={openAcceptModal}
          handleClose={() => {
            setSelectedData(null);
            setOpenAcceptModal(false);
          }}
          loadingState={loading}
          selectedData={selectedData || null}
          updateTableFunction={() => handleGetAll()}
        />,
        <ReturnDocumentModal
          open={openReturnModal}
          handleClose={() => {
            setSelectedData(null);
            setOpenReturnModal(false);
          }}
          loadingState={loading}
          selectedData={selectedData || null}
          updateTableFunction={() => handleGetAll()}
        />,
      ]}
      error={error}
      leftButtons={[
        <Button
          disabled={
            loading ||
            selectedData?.length === 0 ||
            // (selectedData?.length > 1 && auth?.role === "unit head") ||
            !selectedData
          }
          onClick={() => handleAccept()}
          sx={{
            backgroundColor:
              loading ||
              selectedData?.length === 0 ||
              // (selectedData?.length > 1 && auth?.role === "unit head") ||
              !selectedData
                ? "lightgray"
                : "green",
            color: "#fff",
            fontSize: "14px",
            fontWeight: "bold",
            padding: "5px 20px",
            borderRadius: "15px",
            "&:hover": {
              backgroundColor: "lightgray",
              color: "#2f2f2f",
              boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
            },
          }}
        >
          <CheckIcon sx={{ mr: 1 }} />
          Accept
        </Button>,
        <Button
          disabled={
            loading ||
            selectedData?.length === 0 ||
            selectedData?.length > 1 ||
            !selectedData ||
            selectedData[0]?.status === 2
          }
          onClick={() => setOpenReturnModal(true)}
          sx={{
            backgroundColor:
              loading ||
              selectedData?.length === 0 ||
              selectedData?.length > 1 ||
              !selectedData ||
              selectedData[0]?.status === 2
                ? "lightgray"
                : "#da2c43",
            color: "#fff",
            fontSize: "14px",
            fontWeight: "bold",
            padding: "5px 20px",
            borderRadius: "15px",
            "&:hover": {
              backgroundColor: "lightgray",
              color: "#2f2f2f",
              boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
            },
          }}
        >
          <KeyboardReturnIcon sx={{ mr: 1 }} />
          Return
        </Button>,
      ]}
      table={
        <DocumentsTable
          data={documents}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          showCheckbox
          showMultipleSelection
          loadingState={loading}
          setLoadingState={setLoading}
          updateTableFunction={handleGetAll}
        />
      }
    />
  );
}
