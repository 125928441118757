/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";
import { Box, IconButton, Modal, Typography } from "@mui/material";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CancelIcon from "@mui/icons-material/Close";
import DiamondIcon from "@mui/icons-material/Diamond";
import CircleIcon from "@mui/icons-material/Circle";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "80vh",
  width: "60vw",
  bgcolor: "background.paper",
  boxShadow: "3px 2px 20px 3px rgba(0, 0, 0, 0.3)",
  borderRadius: "10px",
  overflowY: "auto",
  p: 4,
};

export default function ViewDetailsModal({
  open,
  handleClose,
  dataFromActions,
}) {
  const [rowData, setRowData] = useState();
  const [annotations, setAnnotations] = useState([]);
  const [destinations, setDestinations] = useState([]);
  const [routedBy, setRoutedBy] = useState([]);
  const [signatories, setSignatories] = useState([]);

  useEffect(() => {
    if (dataFromActions) {
      if (Array.isArray(dataFromActions) && dataFromActions.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        setRowData(dataFromActions[0]);
      } else {
        setRowData(dataFromActions);
      }
    }
  }, [dataFromActions]);

  useEffect(() => {
    if (rowData) {
      setAnnotations(rowData.annotations);
      setDestinations(JSON.parse(rowData.destinations));
      setRoutedBy(JSON.parse(rowData.routedBy));

      const parsedAutoInitials = rowData.autoInitials
        ? JSON.parse(rowData.autoInitials)
        : [];
      const parsedManualInitials = rowData.manualInitials
        ? JSON.parse(rowData.manualInitials)
        : [];

      const combinedSignatories = [
        ...parsedAutoInitials,
        ...parsedManualInitials,
      ];

      setSignatories(combinedSignatories);
    }
  }, [rowData]);

  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
      }}
    >
      <Box sx={style}>
        <Box
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
            cursor: "pointer",
            zIndex: 100,
          }}
        >
          <IconButton onClick={handleClose} sx={{ p: 0 }}>
            <CancelIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: "block",
            mb: 2,
          }}
        >
          <Box
            sx={{
              display: "block",
              background: "#ebebeb",
              border: "solid 1px #b6b6b6",
              borderRadius: "4px",
              width: "100%",
              mt: 4,
              p: 2,
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  color: "gray",
                }}
              >
                Destinations:
              </Typography>
              {destinations?.map((destination) => (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "nowrap",
                    ml: 2,
                  }}
                >
                  <DiamondIcon sx={{ fontSize: "15px", mr: 1 }} />
                  {destination?.destination}
                </Box>
              ))}
            </Box>
          </Box>

          <Box
            sx={{
              display: "block",
              background: "#ebebeb",
              border: "solid 1px #b6b6b6",
              borderRadius: "4px",
              width: "100%",
              mt: 4,
              p: 2,
            }}
          >
            <Box sx={{ mb: 4 }}>
              <Typography
                sx={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  color: "gray",
                }}
              >
                Actions:
              </Typography>
              {rowData?.action?.action.map((action) => (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "nowrap",
                    ml: 2,
                  }}
                >
                  <CircleIcon sx={{ fontSize: "10px", mr: 1 }} />
                  {action}
                </Box>
              ))}
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  color: "gray",
                  mt: 2,
                }}
              >
                Prepare:
              </Typography>
              {rowData?.action?.prepare.map((req) => (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "nowrap",
                    ml: 2,
                  }}
                >
                  <CircleIcon sx={{ fontSize: "10px", mr: 1 }} />
                  {req}
                </Box>
              ))}
            </Box>
          </Box>

          <Box
            sx={{
              display: "block",
              background: "#ebebeb",
              border: "solid 1px #b6b6b6",
              borderRadius: "4px",
              width: "100%",
              mt: 4,
              p: 2,
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  color: "gray",
                }}
              >
                Signatories:
              </Typography>
              {signatories?.map((signatory) => (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "nowrap",
                    ml: 2,
                  }}
                >
                  <DiamondIcon sx={{ fontSize: "15px", mr: 1 }} />
                  {signatory?.destination}
                </Box>
              ))}
            </Box>
          </Box>

          {routedBy && (
            <Box
              sx={{
                display: "block",
                background: "#ebebeb",
                border: "solid 1px #b6b6b6",
                borderRadius: "4px",
                width: "100%",
                mt: 4,
                p: 2,
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    color: "gray",
                  }}
                >
                  Routed By:
                </Typography>
                {routedBy?.map((router) => (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "nowrap",
                      ml: 2,
                    }}
                  >
                    <DiamondIcon sx={{ fontSize: "15px", mr: 1 }} />
                    {router?.destination}
                  </Box>
                ))}
              </Box>
            </Box>
          )}
          <Box
            sx={{
              display: "block",
              background: "#ebebeb",
              border: "solid 1px #b6b6b6",
              borderRadius: "4px",
              width: "100%",
              mt: 4,
              p: 2,
            }}
          >
            <Typography
              sx={{
                fontSize: "15px",
                fontWeight: "bold",
                color: "gray",
              }}
            >
              Annotations:
            </Typography>
            {annotations?.map((e) => (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "nowrap",
                  px: 2,
                }}
              >
                <ArrowForwardIosIcon sx={{ fontSize: "15px" }} />
                <Box sx={{ p: 2 }}>
                  <Typography>{e?.annotation}</Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        color: "gray",
                        mr: 1,
                      }}
                    >
                      Annotated By:
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "gray",
                      }}
                    >
                      {e?.annotatedBy}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        color: "gray",
                        mr: 1,
                      }}
                    >
                      Date:
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "gray",
                      }}
                    >
                      {` ${dayjs(e?.annotationDate).format(
                        "MM/DD/YYYY - hh:mm A"
                      )}`}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
