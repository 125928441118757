/* eslint-disable no-nested-ternary */
/* eslint-disable no-alert */
import {
  Box,
  Button,
  // Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Modal,
  Radio,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Close";
import RemoveIcon from "@mui/icons-material/Remove";
import EditIcon from "@mui/icons-material/Edit";
import RestorePageRoundedIcon from "@mui/icons-material/RestorePageRounded";
import SaveIcon from "@mui/icons-material/Save";

import { useEffect, useState } from "react";
import { number, object, string } from "yup";
import { useFormik } from "formik";
import useAxiosPrivate from "contexts/interceptors/axios";
import SelectDocType from "components/Textfields/SelectDocType";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useStateContext } from "contexts/ContextProvider";
import { enqueueSnackbar } from "notistack";
import SelectDestinations from "components/Textfields/SelectDestinations";
import SelectDocStatus from "components/Textfields/SelectDocStatus";

dayjs.extend(utc);
dayjs.extend(timezone);

const style = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  position: "absolute",
  backgroundColor: "#f0f0f0",
  // background:
  //   "linear-gradient(40deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8))",
  //   border: "solid 2px #46e3be",
  boxShadow: "3px 2px 20px 3px rgba(0, 0, 0, 0.3)",
  borderRadius: "10px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  overflow: "auto",
  "@media (min-width: 10px)": {
    height: "90vh",
    width: "85vw",
  },

  "@media (min-width: 480px)": {
    height: "85vh",
    width: "80vw",
  },

  "@media (min-width: 640px)": {
    height: "75vh",
    width: "70vw",
  },

  "@media (min-width: 768px)": {
    height: "75vh",
    width: "70vw",
  },

  "@media (min-width: 1024px)": {
    height: "80vh",
    width: "70vw",
  },

  "@media (min-width: 1082px)": {
    height: "80vh",
    width: "65vw",
  },
};

export default function EditDocumentModal({
  open,
  handleClose,
  updateTableFunction,
  loadingState,
  selectedData,
  setOpenUpdateFileModal,
  setOpenEditModal,
}) {
  const { auth } = useStateContext();
  const axiosPrivate = useAxiosPrivate();
  const [data, setData] = useState();
  const [disabled, setDisabled] = useState(false);
  const [docType, setDocType] = useState("");
  const [destinations, setDestinations] = useState([
    { id: null, destination: null, type: null },
  ]);

  const [isSelecting, setIsSelecting] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [destinationErrorMssg, setDestinationErrorMssg] = useState([]);

  const parsedRole = auth?.role ? JSON.parse(auth?.role) : null;

  const formik = useFormik({
    initialValues: {
      docType: "",
      title: "",
      annotation: "",
      complexity: 1,
    },

    validationSchema: object().shape({
      docType: string().required("Required"),
      title: string().required("Required"),
      annotation: string(),
      complexity: number(),
    }),
    onSubmit: (values) => {
      const confirmed = window.confirm(
        "Are you sure you want to update this file?"
      );

      if (confirmed) {
        setLoading(true);
        setError("");

        let docuType;

        if (docType) {
          docuType = docType;
        } else {
          docuType = values.docType;
        }

        const formData = {
          docuId: data.id,
          fileId: data.fileId,
          action: JSON.stringify({
            action: [],
            prepare: [],
          }),
          docType: docuType,
          title: values.title,
          complexity: values.complexity,
          classification: values.classification,
          acceptStatus: 0,
          ...(parsedRole?.some((role) => ["admin"].includes(role)) && {
            destinations: JSON.stringify(destinations),
            status: values.status,
            acceptStatus: values.acceptStatus,
            currentOwner: values.currentOwner,
          }),
          remarks: `Revised by ${auth?.firstName} ${auth?.lastName} from ${
            auth?.officeId === 1 ? auth?.unitName : auth?.officeName
          }`,
          ...(values.annotation && {
            annotation: {
              annotation: values?.annotation,
              annotatedBy: `${auth?.firstName} ${auth?.lastName} from ${
                auth?.officeId === 1 ? auth?.unitName : auth?.officeName
              }`,
            },
          }),
        };

        axiosPrivate
          .put(`/documents/reviseDocument`, formData)
          .then(() => {
            enqueueSnackbar("Document Updated", {
              variant: "success",
            });
            formik.resetForm();
            updateTableFunction();
            handleClose();
            setIsSelecting(true);
          })
          .catch((err) => {
            setError(err?.message);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
  });

  const displayDestinationError = (id, text) => {
    setDestinationErrorMssg((prevErrors) => ({
      ...prevErrors,
      [id]: text,
    }));
    setTimeout(() => {
      setDestinationErrorMssg((prevErrors) => ({
        ...prevErrors,
        [id]: "",
      }));
    }, 3000);
  };

  const handleDestinations = (index, value) => {
    setDestinations((prevResponse) => {
      const newDestination = [...prevResponse];

      if (!value.id || !value.destination) {
        displayDestinationError(index, "Destination Required");
      }

      if (
        newDestination.some(
          (dest) => dest.id === value?.id && dest.type === value?.type
        )
      ) {
        newDestination[index] = { id: null, destination: null };
        displayDestinationError(index, "Destination already chosen");
      } else {
        newDestination[index] = value || {
          id: null,
          destination: null,
          type: null,
        };
      }
      return newDestination;
    });
  };

  useEffect(() => {
    const areAllValuesFilled = Object.values(formik.values).every(
      (value) => !!value || value === 0
    );

    let docTypeIsFilled = false;

    if (formik.values.docType === "Others") {
      if (docType) {
        docTypeIsFilled = true;
      }
    } else {
      docTypeIsFilled = true;
    }

    setDisabled(!areAllValuesFilled || !docTypeIsFilled);
  }, [formik.values, docType]);

  useEffect(() => {
    let rowData;

    if (selectedData) {
      if (Array.isArray(selectedData) && selectedData.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        rowData = selectedData[0];
      } else {
        rowData = selectedData;
      }

      const initialValues = {
        docType: rowData?.docType || "",
        title: rowData?.title || "",
        complexity: rowData?.complexity || "",
        classification: rowData?.classification || "",
        ...(parsedRole?.some((role) => ["admin"].includes(role)) && {
          status: rowData?.status || "",
          acceptStatus: rowData?.acceptStatus || 0,
          currentOwner: rowData?.currentOwner || 0,
        }),
      };
      formik.setValues(initialValues);

      if (selectedData.length === 0) {
        rowData = null;
      }
    }

    if (rowData) {
      setData(rowData);
      if (rowData.destinations) {
        setDestinations(JSON.parse(rowData?.destinations));
      }
    }

    if (parsedRole?.some((role) => ["secretary"].includes(role))) {
      const parsedRelatedUnits = JSON.parse(auth?.relatedUnits);

      parsedRelatedUnits.map((unit) => unit.id);
    }
  }, [selectedData]);

  useEffect(() => {
    setLoading(loadingState);
  }, [loadingState]);

  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
        setIsSelecting(true);
        // formik.resetForm();
        // setError("");
      }}
    >
      <Box sx={style}>
        {isSelecting && (
          <Box
            sx={{
              ...style,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0, 0, 0, 0.9)",
              height: "100%",
              width: "100%",
              zIndex: 100,
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: 15,
                right: 15,
              }}
            >
              <IconButton
                onClick={() => {
                  handleClose();
                }}
              >
                <CancelIcon sx={{ color: "#fff" }} />
              </IconButton>
            </Box>
            <Box
              onClick={() => setIsSelecting(false)}
              sx={{
                backgroundColor: "#fff",
                borderRadius: "10px",
                transition: "ease-in-out 0.3s",
                cursor: "pointer",
                p: 2,
                mr: 6,
                "&:hover": {
                  mt: -2,
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "15vh",
                  minHeight: "100px",
                  // width: "10vw",
                  minWidth: "100px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#fff",
                    border: "solid 1px gray",
                    borderRadius: "50%",
                    height: "50px",
                    width: "50px",
                    mb: 2,
                  }}
                >
                  <EditIcon />
                </Box>
                Edit Document Details
              </Box>
            </Box>
            <Box
              onClick={() => {
                setOpenEditModal(false);
                setOpenUpdateFileModal(true);
                setIsSelecting(true);
              }}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#fff",
                borderRadius: "10px",
                transition: "ease-in-out 0.3s",
                cursor: "pointer",
                p: 2,
                "&:hover": {
                  mt: -2,
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "15vh",
                  minHeight: "100px",
                  // width: "10vw",
                  minWidth: "100px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#fff",
                    border: "solid 1px gray",
                    borderRadius: "50%",
                    height: "50px",
                    width: "50px",
                    mb: 2,
                  }}
                >
                  <RestorePageRoundedIcon />
                </Box>
                Update File Document
              </Box>
            </Box>
          </Box>
        )}
        {loading && (
          <Box
            sx={{
              ...style,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 100,
            }}
          >
            <CircularProgress sx={{ color: "white" }} />
          </Box>
        )}
        <Box
          variant="form"
          component="form"
          onSubmit={formik.handleSubmit}
          autoComplete="off"
          sx={style}
        >
          <Box>
            <Box // Fixed Header
              sx={{
                position: "sticky",
                top: 0,
                backgroundColor: "#59395c",
                // boxShadow: "3px 2px 20px 3px rgba(0, 0, 0, 0.3)",
                boxShadow: "8px 8px 15px 3px rgba(0, 0, 0, 0.3)",
                borderBottomLeftRadius: "30%",
                borderBottomRightRadius: "200px",
                zIndex: 2,
                px: 4,
                mr: 4,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  py: 2,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "25px",
                    color: "#fff",
                    py: 1,
                  }}
                >
                  Edit Document
                </Typography>
                <IconButton
                  onClick={() => {
                    handleClose();
                    setIsSelecting(true);
                  }}
                  sx={{ color: "#fff", p: 0, m: 0 }}
                >
                  <CancelIcon />
                </IconButton>
              </Box>
              {/* <Divider sx={{ backgroundColor: "gray" }} /> */}
            </Box>
            {error && (
              <Box sx={{ backgroundColor: "red", width: "100%", mt: 2, px: 1 }}>
                <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
                  {error}
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                m: 4,
              }}
            >
              <Grid container spacing={0}>
                <Box
                  sx={{
                    background: "#fff",
                    border: "solid 1px #b6b6b6",
                    width: "100%",
                    p: 2,
                    mb: 2,
                  }}
                >
                  <Grid item xs={12}>
                    <TextField
                      name="title"
                      label="Document Title"
                      variant="outlined"
                      disabled={loading}
                      size="small"
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBLur}
                      error={
                        formik.touched.title && Boolean(formik.errors.title)
                      }
                      helperText={formik.touched.title && formik.errors.title}
                      sx={{
                        width: "100%",
                        mb: 2,
                        "&:hover": {
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "black !important",
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", flexWrap: "wrap", width: "100%" }}
                  >
                    <Box
                      sx={{
                        width:
                          formik.values.docType === "Others" ? "50%" : "100%",
                        minWidth: "200px",
                      }}
                    >
                      <SelectDocType
                        label="Document Type"
                        name="docType"
                        disabled={loading}
                        value={formik.values.docType}
                        onChange={(fieldName, selectedValue) => {
                          formik.setFieldValue("docType", selectedValue);

                          if (selectedValue !== "Others") {
                            setDocType("");
                          }
                        }}
                        onBlur={formik.handleBLur}
                        error={
                          formik.touched.docType &&
                          Boolean(formik.errors.docType)
                        }
                        helperText={
                          formik.touched.docType && formik.errors.docType
                        }
                        sx={{
                          width: "100%",
                          pr: formik.values.docType === "Others" ? 3 : 0,
                          "&:hover": {
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "black !important",
                            },
                          },
                        }}
                      />
                    </Box>
                    <Box sx={{ width: "50%", minWidth: "200px" }}>
                      {formik.values.docType === "Others" ? (
                        <TextField
                          name="otherDocTypes"
                          label="Specify Document Type"
                          size="small"
                          disabled={loading}
                          value={docType}
                          onChange={(e) => setDocType(e.target.value)}
                          variant="standard"
                          fullWidth
                          sx={{
                            mt: -0.5,
                            // ml: -4,
                          }}
                        />
                      ) : (
                        <Box />
                      )}
                    </Box>
                  </Grid>
                  {parsedRole?.some((role) => ["admin"].includes(role)) && (
                    <Grid item xs={12}>
                      <SelectDocStatus
                        label="Document Status"
                        name="status"
                        disabled={loading}
                        value={formik.values.status}
                        onChange={(fieldName, selectedValue) => {
                          formik.setFieldValue("status", selectedValue);
                        }}
                        onBlur={formik.handleBLur}
                        error={
                          formik.touched.status && Boolean(formik.errors.status)
                        }
                        helperText={
                          formik.touched.status && formik.errors.status
                        }
                        sx={{
                          mt: 2,
                          width: "100%",
                          "&:hover": {
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "black !important",
                            },
                          },
                        }}
                      />
                    </Grid>
                  )}
                </Box>

                {parsedRole?.some((role) => ["admin"].includes(role)) && (
                  <>
                    <Box
                      sx={{
                        width: "100%",
                        background: "#fff",
                        border: "solid 1px #b6b6b6",
                        p: 2,
                        mb: 2,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "15px",
                              fontWeight: "bold",
                              color: "gray",
                              mb: 2,
                            }}
                          >
                            Destination/s:
                          </Typography>
                          <Grid
                            container
                            spacing={2}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            {destinations?.map((destination, index) => (
                              <Grid item xs={destinations.length > 1 ? 6 : 12}>
                                <SelectDestinations
                                  label={`Destination ${index + 1}`}
                                  disabled={loading}
                                  value={destination?.id}
                                  onChange={(fieldName, selectedValue) => {
                                    handleDestinations(index, selectedValue);
                                  }}
                                  onBlur={formik.handleBLur}
                                  error={Boolean(destination?.id === "")}
                                  helperText={
                                    <span style={{ color: "red" }}>
                                      {destinationErrorMssg[index]}
                                    </span>
                                  }
                                  addSpecificDestinations={
                                    open &&
                                    parsedRole?.some((role) =>
                                      ["secretary"].includes(role)
                                    ) &&
                                    JSON.parse(auth?.relatedUnits)
                                  }
                                  // restrictOwnUnit
                                  sx={{
                                    width: "100%",
                                    pr: 3,
                                    "&:hover": {
                                      "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "black !important",
                                      },
                                    },
                                  }}
                                />
                              </Grid>
                            ))}
                          </Grid>
                        </Box>
                        <Box sx={{ textAlign: "end" }}>
                          <Tooltip title="Add Destination" placement="right">
                            <IconButton
                              onClick={() =>
                                setDestinations((prev) => [
                                  ...prev,
                                  { id: "", destination: "", type: "" },
                                ])
                              }
                              sx={{
                                backgroundColor: "#4ea82b",
                                color: "#fff",
                                mb: 1,
                                "&:hover": {
                                  backgroundColor: "#0ed145",
                                },
                              }}
                            >
                              <AddIcon sx={{ fontWeight: "bold" }} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Remove Destination" placement="right">
                            <IconButton
                              disabled={destinations.length < 2}
                              onClick={() =>
                                setDestinations((prev) => prev.slice(0, -1))
                              }
                              sx={{
                                backgroundColor:
                                  destinations.length < 2 ? "lightgray" : "red",
                                border:
                                  destinations.length < 2 &&
                                  "solid 1px #f28c8c",
                                color:
                                  destinations.length < 2 ? "black" : "#fff",
                                "&:hover": {
                                  backgroundColor: "#e54c51",
                                },
                              }}
                            >
                              <RemoveIcon sx={{ fontWeight: "bold" }} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        width: "100%",
                        background: "#fff",
                        border: "solid 1px #b6b6b6",
                        p: 2,
                        mb: 2,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: "bold",
                          color: "gray",
                          mb: 2,
                        }}
                      >
                        Current Owner:
                      </Typography>
                      <SelectDestinations
                        label="Current Owner"
                        disabled={loading}
                        value={formik?.values?.currentOwner}
                        onChange={(fieldName, selectedValue) => {
                          formik.setFieldValue(
                            "currentOwner",
                            selectedValue?.id
                          );
                        }}
                        showSuperintendents
                        onBlur={formik.handleBLur}
                        error={Boolean(formik?.values?.currentOwner === "")}
                        helperText={!formik?.values?.currentOwner && "Required"}
                        sx={{
                          width: "100%",
                          "&:hover": {
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "black !important",
                            },
                          },
                        }}
                      />
                    </Box>
                  </>
                )}

                {parsedRole?.some((role) => ["admin"].includes(role)) && (
                  <Box
                    sx={{
                      display: "block",
                      justifyContent: "space-between",
                      background: "#fff",
                      border: "solid 1px #b6b6b6",
                      borderRadius: "4px",
                      width: "100%",
                      mb: 2,
                      p: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        color: "gray",
                      }}
                    >
                      Accept Status:
                    </Typography>
                    <FormControl>
                      <FormGroup
                        row
                        sx={{
                          p: "16px 0 0 16px",
                          gap: 2,
                        }}
                      >
                        {[
                          { label: "Accepted", value: 1 },
                          { label: "Not Accepted", value: 0 },
                        ].map((option) => (
                          <FormControlLabel
                            key={option.value}
                            control={
                              <Radio
                                color="success"
                                checked={
                                  formik.values.acceptStatus === option.value
                                }
                                onChange={() => {
                                  formik.setFieldValue(
                                    "acceptStatus",
                                    option.value
                                  );
                                }}
                              />
                            }
                            label={
                              <Typography
                                sx={{
                                  fontWeight:
                                    formik.values.acceptStatus === option.value
                                      ? "bold"
                                      : "normal",
                                  color:
                                    formik.values.acceptStatus === option.value
                                      ? "green"
                                      : "gray",
                                }}
                              >
                                {option.label}
                              </Typography>
                            }
                          />
                        ))}
                      </FormGroup>
                    </FormControl>
                  </Box>
                )}
                <Box
                  sx={{
                    display: "block",
                    justifyContent: "space-between",
                    background: "#fff",
                    border: "solid 1px #b6b6b6",
                    borderRadius: "4px",
                    width: "100%",
                    mb: 2,
                    p: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: "bold",
                      color: "gray",
                    }}
                  >
                    Classification:
                  </Typography>
                  <FormControl>
                    <FormGroup
                      row
                      sx={{
                        p: "16px 0 0 16px",
                        gap: 2,
                      }}
                    >
                      {[
                        { label: "For Signing", value: 1 },
                        { label: "For Routing", value: 2 },
                        ...(parsedRole?.some((role) => ["admin"].includes(role))
                          ? [
                              { label: "For Checking", value: 3 },
                              { label: "Routed", value: 4 },
                              { label: "For Submission", value: 5 },
                            ]
                          : auth?.unitId === 12 &&
                            parsedRole?.some((role) =>
                              ["unit head"].includes(role)
                            )
                          ? [{ label: "For Submission", value: 5 }]
                          : []),
                      ].map((option) => (
                        <FormControlLabel
                          key={option.value}
                          control={
                            <Radio
                              color="success"
                              checked={
                                formik.values.classification === option.value
                              }
                              onChange={() => {
                                formik.setFieldValue(
                                  "classification",
                                  option.value
                                );
                              }}
                            />
                          }
                          label={
                            <Typography
                              sx={{
                                fontWeight:
                                  formik.values.classification === option.value
                                    ? "bold"
                                    : "normal",
                                color:
                                  formik.values.classification === option.value
                                    ? "green"
                                    : "gray",
                              }}
                            >
                              {option.label}
                            </Typography>
                          }
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                </Box>

                <Box
                  sx={{
                    display: "block",
                    justifyContent: "space-between",
                    background: "#fff",
                    border: "solid 1px #b6b6b6",
                    borderRadius: "4px",
                    width: "100%",
                    mb: 2,
                    p: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: "bold",
                      color: "gray",
                    }}
                  >
                    Complexity:
                  </Typography>
                  <FormControl>
                    <FormGroup
                      row
                      sx={{
                        p: "16px 0 0 16px",
                        gap: 2,
                      }}
                    >
                      {[
                        { label: "Simple", value: 1 },
                        { label: "Complex", value: 2 },
                        { label: "Urgent", value: 3 },
                      ].map((option) => (
                        <FormControlLabel
                          key={option.value}
                          control={
                            <Radio
                              color="success"
                              checked={
                                formik.values.complexity === option.value
                              }
                              onChange={() => {
                                formik.setFieldValue(
                                  "complexity",
                                  option.value
                                );
                              }}
                            />
                          }
                          label={
                            <Typography
                              sx={{
                                fontWeight:
                                  formik.values.complexity === option.value
                                    ? "bold"
                                    : "normal",
                                color:
                                  formik.values.complexity === option.value
                                    ? "green"
                                    : "gray",
                              }}
                            >
                              {option.label}
                            </Typography>
                          }
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                </Box>

                <Box
                  sx={{
                    display: "block",
                    justifyContent: "space-between",
                    background: "#fff",
                    border: "solid 1px #b6b6b6",
                    borderRadius: "4px",
                    width: "100%",
                    p: 2,
                  }}
                >
                  <TextField
                    label="Annotation (Optional)"
                    name="annotation"
                    variant="outlined"
                    disabled={loading}
                    value={formik.values.annotation}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBLur}
                    error={
                      formik.touched.annotation &&
                      Boolean(formik.errors.annotation)
                    }
                    helperText={
                      formik.touched.annotation && formik.errors.annotation
                    }
                    multiline
                    rows={4}
                    sx={{
                      width: "100%",
                      "&:hover": {
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "black !important",
                        },
                      },
                    }}
                  />
                </Box>
              </Grid>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              p: 2,
              zIndex: 10,
              mt: 2,
            }}
          >
            <Button
              disabled={disabled}
              type="submit"
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: disabled ? "lightgray" : "#59395c",
                color: "#fff",
                py: 1,
                px: 2,
                // width: "10vw",
                // minWidth: "100px",
                "&:hover": {
                  backgroundColor: "#f6e247",
                  color: "#59395c",
                  fontWeight: "bold",
                },
              }}
            >
              <SaveIcon
                sx={{
                  mr: 1,
                }}
              />
              Update
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
