/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import React, { useEffect, useState } from "react";
import { useStateContext } from "contexts/ContextProvider";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import DocuTypeIcon from "@mui/icons-material/InsertDriveFile";
// import DownloadDoneOutlinedIcon from "@mui/icons-material/DownloadDoneOutlined";
import { AiFillSignature } from "react-icons/ai";
import { TbPencilCheck } from "react-icons/tb";
import DownloadIcon from "@mui/icons-material/FileDownload";
import FileCopyIcon from "@mui/icons-material/FileCopy";
// import InboxIcon from "@mui/icons-material/MoveToInboxTwoTone";
import { GrDocumentTransfer } from "react-icons/gr";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import OfficeIcon from "@mui/icons-material/HomeWork";
// import OutboxIcon from "@mui/icons-material/Outbox";
import { RiFileTransferFill } from "react-icons/ri";
import PanToolIcon from "@mui/icons-material/PanTool";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import PersonIcon from "@mui/icons-material/Person";
import { PiOfficeChairFill } from "react-icons/pi";
import SaveIcon from "@mui/icons-material/Save";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import UploadIcon from "@mui/icons-material/Upload";
import UsersIcon from "@mui/icons-material/PeopleAlt";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import useAxiosPrivate from "contexts/interceptors/axios";
import AddDocumentModal from "modals/documents/AddDocumentModal";
import ResponsiveCards from "components/ResponsiveCards";
import socket from "contexts/socket";
// import DashboardTable from "./DashboardTable";

export default function Dashboard() {
  const { auth, referenceId, selectedUserType, setSelectedUserType } =
    useStateContext();
  const axiosPrivate = useAxiosPrivate();

  const [userCount, setUserCount] = useState(0);

  const [allDocuments, setAllDocuments] = useState([]);
  const [forRelease, setForRelease] = useState([]);

  const [incoming, setIncoming] = useState([]);
  const [outgoing, setOutgoing] = useState([]);
  const [pending, setPending] = useState([]);
  const [saved, setSaved] = useState([]);
  const [lapsed, setLapsed] = useState([]);
  const [onhold, setOnhold] = useState([]);
  const [signed, setSigned] = useState([]);
  const [uploaded, setUploaded] = useState([]);

  const [forSigning, setForSigning] = useState([]);
  const [forRouting, setForRouting] = useState([]);
  const [routedIn, setRoutedIn] = useState([]);
  const [routedOut, setRoutedOut] = useState([]);

  const [docTypes, setDocTypes] = useState([]);
  const [offices, setOffices] = useState([]);
  const [units, setUnits] = useState([]);
  const [users, setUsers] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [openAddModal, setOpenAddModal] = useState(false);

  const [contents, setContents] = useState([]);

  const SDSSecIds = [3, 4];
  const ASDSSecIds = [7];

  const parsedRole = auth?.role ? JSON.parse(auth?.role) : null;

  const handleGetAllDocuments = () => {
    setLoading(true);
    setError("");

    axiosPrivate
      .get(`/documents/getAllDocuments`)
      .then((res) => {
        setAllDocuments(res?.data);
      })
      .catch((err) => {
        setError(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleGetRelatedDocuments = () => {
    setLoading(true);
    setError("");

    axiosPrivate
      .get(`/documents/getAllDocuments`, {
        params: { referenceId },
      })
      .then((res) => {
        res.data.forEach((document) => {
          if (typeof document.action === "string") {
            document.action = JSON.parse(document.action);
          }
        });

        const filteredForRelease = res?.data?.filter((doc) => {
          const parsedLastSources = JSON.parse(doc?.lastSource);
          const parsedRelatedUnits = JSON.parse(auth?.relatedUnits);

          if (
            doc.currentOwner !== auth.unitId &&
            doc.status === 8 &&
            doc.acceptStatus === 0 &&
            auth?.relatedUnits &&
            parsedLastSources.some((last) =>
              parsedRelatedUnits.some((unit) => unit.id === last.id)
            )
          ) {
            return true;
          }
          return false;
        });

        setForRelease(filteredForRelease);

        const filteredIncoming = res?.data?.filter((doc) => {
          const destinations = JSON.parse(doc?.destinations);

          // if the user logged in is from school
          if (auth?.officeId !== 1) {
            // Watch out for this
            if (doc.status === 2) {
              return (
                destinations.some(
                  (dest) => dest.id === referenceId && dest.type === "office"
                ) && doc.status === 2
              );
            }
            if (
              destinations.some(
                (dest) => dest.id === referenceId && dest.type === "office"
              ) &&
              doc.currentOwner !== referenceId &&
              doc.status === 1
            ) {
              return true;
            }
          } else if (auth?.officeId === 1) {
            // Watch out for this
            if (doc.status === 2) {
              return (
                destinations.some(
                  (dest) => dest.id === referenceId && dest.type === "unit"
                ) && doc.status === 2
              );
            }
            if (SDSSecIds.includes(auth?.unitId)) {
              if (
                destinations.some(
                  (dest) => SDSSecIds.includes(dest.id) && dest.type === "unit"
                ) &&
                !SDSSecIds.includes(doc.currentOwner) &&
                doc.status === 1
              ) {
                return true;
              }
            } else if (ASDSSecIds.includes(auth?.unitId)) {
              if (
                destinations.some(
                  (dest) => ASDSSecIds.includes(dest.id) && dest.type === "unit"
                ) &&
                !ASDSSecIds.includes(doc.currentOwner) &&
                doc.status === 1
              ) {
                return true;
              }
            } else if (
              destinations.some(
                (dest) => dest.id === referenceId && dest.type === "unit"
              ) &&
              doc.currentOwner !== referenceId &&
              doc.status === 1
            ) {
              return true;
            }
          }

          return false;
        });

        setIncoming(filteredIncoming);

        const ownedDocs = res.data.filter(
          (doc) => doc.currentOwner === referenceId
        );

        const filteredOutgoing = ownedDocs.filter((doc) => {
          const parsedPrimarySources = JSON.parse(doc?.primarySources);
          const parsedLastSources = JSON.parse(doc?.lastSource);
          const parsedDestinations = JSON.parse(doc?.destinations);

          let lastSource;

          if (parsedLastSources.length === 1) {
            lastSource = parsedLastSources ? parsedLastSources[0] : null;
          } else {
            lastSource = parsedLastSources ? parsedLastSources[1] : null;
          }

          // if the user logged in is from school
          if (auth?.officeId !== 1) {
            if (
              (parsedPrimarySources.some(
                (prim) => prim?.id === referenceId && prim?.dest === "office"
              ) ||
                (lastSource?.id === referenceId &&
                  lastSource?.type === "office")) &&
              (doc.status === 1 || doc.status === 8) &&
              parsedDestinations.some((dest) => dest.id !== referenceId)
            ) {
              return true;
            }
          } else if (auth?.officeId === 1) {
            if (SDSSecIds.includes(referenceId)) {
              if (
                parsedDestinations.some(
                  (dest) => dest.id === 1 && dest.type === "unit"
                ) &&
                SDSSecIds.includes(doc.currentOwner) &&
                (doc.status === 1 || doc.status === 8)
              ) {
                return true;
              }
            } else if (ASDSSecIds.includes(referenceId)) {
              if (
                parsedDestinations.some(
                  (dest) => dest.id === 2 && dest.type === "unit"
                ) &&
                ASDSSecIds.includes(doc.currentOwner) &&
                (doc.status === 1 || doc.status === 8)
              ) {
                return true;
              }
            } else if (
              (parsedPrimarySources.some((prim) => prim?.id === referenceId) ||
                (lastSource?.id === referenceId &&
                  lastSource?.type === "unit")) &&
              (doc.status === 1 || doc.status === 8) &&
              parsedDestinations.some((dest) => dest.id !== referenceId)
            ) {
              return true;
            }
          }

          return null;
        });

        setOutgoing(filteredOutgoing);

        let filteredPending;

        if (SDSSecIds.includes(referenceId)) {
          filteredPending = res.data.filter(
            (doc) =>
              doc.status === 3 &&
              doc.classification !== 4 &&
              doc.currentOwner === 4
          );
        } else {
          filteredPending = res.data.filter(
            (doc) =>
              doc.status === 3 &&
              doc.classification !== 4 &&
              doc.currentOwner === referenceId
          );
        }

        setPending(filteredPending);

        const filteredSaved = res.data.filter((doc) => {
          const destinations = JSON.parse(doc?.destinations);

          if (auth?.officeId !== 1) {
            if (
              destinations.some(
                (dest) => dest.id === referenceId && dest.type === "office"
              ) &&
              doc.status === 4 &&
              doc.currentOwner === referenceId
            ) {
              return true;
            }
          } else if (auth?.officeId === 1) {
            if (
              destinations.some(
                (dest) => dest.id === referenceId && dest.type === "unit"
              ) &&
              doc.status === 4 &&
              doc.currentOwner === referenceId
            ) {
              return true;
            }
          }

          return false;
        });

        setSaved(filteredSaved);

        const fifteenDaysInMilliseconds = 15 * 24 * 60 * 60 * 1000;

        const filteredLapsed = res.data.filter((doc) => {
          const {
            createdAtDateTime,
            destinations,
            status,
            acceptStatus,
            classification,
          } = doc;

          const parsedDestinations = JSON.parse(destinations);

          const currentDateTime = new Date();
          const createdAtDateTimeObj = new Date(createdAtDateTime);
          // const lastUpdateDateTimeObj = new Date(lastUpdateDateTime);

          if (
            status === 3 &&
            currentDateTime.getTime() >=
              createdAtDateTimeObj.getTime() + fifteenDaysInMilliseconds &&
            createdAtDateTime &&
            acceptStatus &&
            classification !== 4
          ) {
            if (
              auth?.officeId === 1 &&
              parsedDestinations.some(
                (dest) => dest.id === referenceId && dest.type === "unit"
              )
            ) {
              return doc;
            }

            // if the user logged in is from school
            if (
              auth?.officeId !== 1 &&
              parsedDestinations.some(
                (dest) => dest.id === referenceId && dest.type === "office"
              )
            ) {
              return doc;
            }
          }
          return null;
        });

        setLapsed(filteredLapsed);

        const filteredOnhold = res.data.filter((doc) => {
          const parsedDestinations = JSON.parse(doc?.destinations);
          if (
            auth?.officeId === 1 &&
            parsedDestinations.some(
              (dest) => dest.id === referenceId && dest.type === "unit"
            ) &&
            doc.status === 5 &&
            doc.currentOwner === referenceId
          ) {
            return doc;
          }

          // if the user logged in is from school
          if (
            auth?.officeId !== 1 &&
            parsedDestinations.some(
              (dest) => dest.id === referenceId && dest.type === "office"
            ) &&
            doc.status === 5 &&
            doc.currentOwner === referenceId
          ) {
            return doc;
          }

          return null;
        });

        setOnhold(filteredOnhold);

        let filteredSignedDocs = [];

        if (auth?.unitId === 1 || auth?.unitId === 2) {
          filteredSignedDocs = res.data.filter((doc) => {
            const parsedAutoInitials = doc.autoInitials
              ? JSON.parse(doc.autoInitials)
              : [];
            const parsedManualInitials = doc.manualInitials
              ? JSON.parse(doc.manualInitials)
              : [];

            const combinedSignatories = [
              ...parsedAutoInitials,
              ...parsedManualInitials,
            ];

            if (combinedSignatories.some((sign) => sign?.id === auth?.unitId)) {
              return true;
            }

            return false;
          });
        } else {
          filteredSignedDocs = res.data.filter((doc) => {
            const parsedPrimarySources = doc.primarySources
              ? JSON.parse(doc.primarySources)
              : [];

            if (
              doc?.signedDateTime &&
              parsedPrimarySources.some((prim) => {
                const isOffice =
                  auth?.officeId !== 1 && prim?.type === "office";
                const isUnit = auth?.officeId === 1 && prim?.type === "unit";
                return prim?.id === referenceId && (isOffice || isUnit);
              })
            ) {
              return true;
            }
            return false;
          });
        }

        setSigned(filteredSignedDocs);

        const filteredUploaded = res.data.filter((doc) => {
          const parsedPrimarySources = doc.primarySources
            ? JSON.parse(doc.primarySources)
            : [];

          if (
            parsedPrimarySources.some((prim) => {
              const isOffice = auth?.officeId !== 1 && prim?.type === "office";
              const isUnit = auth?.officeId === 1 && prim?.type === "unit";
              return prim?.id === referenceId && (isOffice || isUnit);
            })
          ) {
            return true;
          }
          return false;
        });

        setUploaded(filteredUploaded);

        // SDS, ASDS, Chief

        const filteredForRouting = res.data.filter((doc) => {
          const destinations = JSON.parse(doc.destinations);

          if (
            destinations.some(
              (dest) => dest.id === auth.unitId && dest.type === "unit"
            ) &&
            // doc.currentOwner !== auth.unitId &&
            doc.status === 8 &&
            doc.acceptStatus === 1
          ) {
            return true;
          }
          return false;
        });

        setForRouting(filteredForRouting);

        const filteredForSigning = res.data.filter((doc) => {
          const destinations = JSON.parse(doc.destinations);

          if (
            destinations.some(
              (dest) => dest.id === referenceId && dest.type === "unit"
            ) &&
            doc.status === 7 &&
            doc.acceptStatus === 1
          ) {
            return true;
          }
          return false;
        });

        setForSigning(filteredForSigning);

        const filteredRoutedIn = res?.data?.filter((doc) => {
          const destinations = JSON.parse(doc?.destinations);

          if (auth?.officeId !== 1) {
            if (
              destinations.some(
                (dest) => dest.id === referenceId && dest.type === "office"
              ) &&
              doc.status === 3 &&
              doc.classification === 4
            ) {
              return true;
            }
          } else if (auth?.officeId === 1) {
            if (
              destinations.some(
                (dest) => dest.id === referenceId && dest.type === "unit"
              ) &&
              doc.status === 3 &&
              doc.classification === 4
            ) {
              return true;
            }
          }

          return false;
        });

        setRoutedIn(filteredRoutedIn);

        const filteredRoutedOut = res?.data?.filter((doc) => {
          const parsedRoutedBy = doc.routedBy ? JSON.parse(doc.routedBy) : [];

          if (SDSSecIds.includes(auth.unitId)) {
            if (
              parsedRoutedBy.some(
                (router) => router.id === 1 && doc.classification === 4
              )
            ) {
              return true;
            }
          } else if (ASDSSecIds.includes(auth.unitId)) {
            if (
              parsedRoutedBy.some(
                (router) => router.id === 2 && doc.classification === 4
              )
            ) {
              return true;
            }
          } else if (
            parsedRoutedBy.some(
              (router) => router.id === auth.unitId && doc.classification === 4
            )
          ) {
            return true;
          }

          return false;
        });

        setRoutedOut(filteredRoutedOut);
      })
      .catch((err) => {
        setError(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleGetAllDocTypes = () => {
    axiosPrivate
      .get(`/libraries/getAllDocTypes`)
      .then((res) => {
        setDocTypes(res.data);
      })
      .catch((err) => setError(err.message));
  };

  const handleGetAllOffices = () => {
    axiosPrivate
      .get(`/libraries/getAllOffices`)
      .then((res) => {
        setOffices(res.data);
      })
      .catch((err) => setError(err.message));
  };

  const handleGetAllUnits = () => {
    axiosPrivate
      .get(`/libraries/getAllUnits`)
      .then((res) => {
        setUnits(res.data);
      })
      .catch((err) => setError(err.message));
  };

  const handleGetAllUsers = () => {
    axiosPrivate
      .get(`/user/getAllUsers`)
      .then((res) => {
        setUsers(res.data);
      })
      .catch((err) => setError(err.message));
  };

  useEffect(() => {
    if (
      parsedRole?.some((role) => ["admin"].includes(role)) &&
      selectedUserType === "admin"
    ) {
      setLoading(true);
      setError("");

      Promise.all([
        handleGetAllDocTypes(),
        handleGetAllOffices(),
        handleGetAllUnits(),
        handleGetAllUsers(),
        handleGetAllDocuments(),
      ])
        .then(() => setLoading(false))
        .catch((err) => setError(err.message))
        .finally(() => setLoading(false));
    } else {
      handleGetRelatedDocuments();
    }
  }, [auth, selectedUserType]);

  useEffect(() => {
    if (selectedUserType === "sender" || selectedUserType === "receiver") {
      setContents([
        {
          path: "/incoming",
          value: incoming.length || 0,
          title: "Incoming Documents",
          iconColor: "linear-gradient(120deg, #207a14, #27bf13, #41a175)",
          // iconColor: "linear-gradient(120deg, #886c1d, #957315, #dbc231)",
          icon: (
            <DownloadIcon
              sx={{
                color: "lightgray",
                fontSize: "30px",
              }}
            />
          ),
        },
        {
          path: "/outgoing",
          value: outgoing.length || 0,
          title: "Outgoing Documents",
          iconColor: "linear-gradient(120deg, #5c2774, #637ffd)",
          icon: (
            <UploadIcon
              sx={{
                color: "lightgray",
                fontSize: "30px",
              }}
            />
          ),
        },
        ...(parsedRole.some((role) =>
          [
            "chief",
            "secretary",
            "unit head",
            "unit employee",
            "school personnel",
          ].includes(role)
        )
          ? [
              {
                path: "/pending",
                value: pending.length || 0,
                title: "Pending Documents",
                iconColor: "linear-gradient(120deg, #f09116, #a8650d)",
                icon: (
                  <PendingActionsIcon
                    sx={{
                      color: "lightgray",
                      fontSize: "30px",
                    }}
                  />
                ),
              },
            ]
          : []),
        ...(parsedRole.some((role) => ["secretary"].includes(role))
          ? [
              {
                path: "/release",
                value: forRelease.length || 0,
                title: "Documents for Release",
                iconColor: "linear-gradient(120deg, #2959ba, #1ea2df, #171778)",
                icon: (
                  <ThumbUpIcon
                    style={{
                      color: "lightgray",
                      fontSize: "30px",
                    }}
                  />
                ),
              },
            ]
          : []),
        ...(parsedRole.some((role) =>
          [
            "asds",
            "chief",
            "secretary",
            "unit head",
            "unit employee",
            "school personnel",
          ].includes(role)
        )
          ? [
              {
                path: "/routedIn",
                value: routedIn.length || 0,
                title: "Routed In Documents",
                iconColor: "linear-gradient(120deg, #534f7c, #246fc9, #53c791)",
                // iconColor:
                //           "linear-gradient(120deg, #78e8ce, #7061c6, #ff65ba, #ffb359)",
                icon: (
                  <GrDocumentTransfer
                    style={{
                      color: "lightgray",
                      fontSize: "26px",
                    }}
                  />
                ),
              },
            ]
          : []),

        // Just doubled to fix the arrangement of boxes in SDS dashboard
        ...(parsedRole.some((role) => ["asds", "secretary"].includes(role))
          ? [
              {
                path: "/routedOut",
                value: routedOut.length || 0,
                title: "Routed Out Documents",
                iconColor: "linear-gradient(120deg, #534f7c, #246fc9, #53c791)",
                // iconColor:
                //           "linear-gradient(120deg, #78e8ce, #7061c6, #ff65ba, #ffb359)",
                icon: (
                  <RiFileTransferFill
                    style={{
                      color: "lightgray",
                      fontSize: "26px",
                    }}
                  />
                ),
              },
            ]
          : []),

        ...(parsedRole.some((role) => ["sds", "asds"].includes(role)) ||
        parsedRole.some(
          (role) => ["unit head"].includes(role) && auth?.unitId === 12
        )
          ? [
              {
                path: "/routing",
                value: forRouting.length || 0,
                title: "Documents for Routing",
                // iconColor: "linear-gradient(120deg, #f09116, #a8650d)",
                iconColor: "linear-gradient(120deg, #c77d51, #b0451b, #53c791)",
                icon: (
                  <ShortcutIcon
                    sx={{
                      color: "lightgray",
                      fontSize: "30px",
                    }}
                  />
                ),
              },
            ]
          : []),

        ...(parsedRole.some((role) =>
          ["sds", "asds", "unit head", "chief"].includes(role)
        )
          ? [
              {
                path: "/signature",
                value: forSigning.length || 0,
                title: "Documents for Signature",
                iconColor: "linear-gradient(120deg, #f09116, #a8650d, #53c791)",
                icon: (
                  <AiFillSignature
                    style={{
                      color: "lightgray",
                      fontSize: "30px",
                    }}
                  />
                ),
              },
            ]
          : []),

        // Just doubled to fix the arrangement of boxes in SDS dashboard
        ...(parsedRole.some((role) => ["sds"].includes(role))
          ? [
              {
                path: "/routedOut",
                value: routedOut.length || 0,
                title: "Routed Out Documents",
                iconColor: "linear-gradient(120deg, #534f7c, #246fc9, #53c791)",
                // iconColor:
                //           "linear-gradient(120deg, #78e8ce, #7061c6, #ff65ba, #ffb359)",
                icon: (
                  <RiFileTransferFill
                    style={{
                      color: "lightgray",
                      fontSize: "26px",
                    }}
                  />
                ),
              },
            ]
          : []),
        {
          path: "/signed",
          value: signed.length || 0,
          title: "Signed Documents",
          iconColor: "linear-gradient(120deg, #27bf13, #207a14)",
          icon: (
            <TbPencilCheck
              style={{
                color: "lightgray",
                fontSize: "30px",
              }}
            />
          ),
        },

        ...(parsedRole.some((role) =>
          [
            "asds",
            "secretary",
            "chief",
            "unit head",
            "unit employee",
            "school personnel",
          ].includes(role)
        )
          ? [
              {
                path: "/saved",
                value: saved.length || 0,
                title: "Saved Documents",
                iconColor: "linear-gradient(120deg, #00a8f3, #095b80)",
                icon: (
                  <SaveIcon
                    sx={{
                      color: "lightgray",
                      fontSize: "30px",
                    }}
                  />
                ),
              },
            ]
          : []),

        ...(parsedRole.some((role) =>
          [
            "chief",
            "secretary",
            "unit head",
            "unit employee",
            "school personnel",
          ].includes(role)
        )
          ? [
              {
                path: "/lapsed",
                value: lapsed.length || 0,
                title: "Lapsed Documents",
                iconColor: "linear-gradient(120deg, #cf1515, #9319a6)",
                icon: (
                  <WarningAmberIcon
                    sx={{
                      color: "lightgray",
                      fontSize: "30px",
                    }}
                  />
                ),
              },
              {
                path: "/onhold",
                value: onhold.length || 0,
                title: "On-Hold Documents",
                iconColor: "#cda814",
                icon: (
                  <PanToolIcon
                    sx={{
                      color: "lightgray",
                      fontSize: "30px",
                    }}
                  />
                ),
              },
              {
                path: "/uploaded",
                value: uploaded.length || 0,
                title: "Uploaded Documents",
                iconColor: "linear-gradient(120deg, #ff7979, #936b4d)",
                icon: (
                  <FileCopyIcon
                    style={{
                      color: "lightgray",
                      fontSize: "30px",
                    }}
                  />
                ),
              },
            ]
          : []),
      ]);
    }
    if (
      selectedUserType === "admin" &&
      parsedRole?.some((role) => ["admin"].includes(role))
    ) {
      setContents([
        {
          path: "/doc-types",
          value: docTypes.length || 0,
          title: "Document Types",
          iconColor: "linear-gradient(120deg, #f54848, #621f1f)",
          icon: (
            <DocuTypeIcon
              sx={{
                color: "lightgray",
                fontSize: "30px",
              }}
            />
          ),
        },
        {
          path: "/offices",
          value: offices.length || 0,
          title: "Offices",
          iconColor: "linear-gradient(120deg, #27bf13, #207a14)",
          icon: (
            <OfficeIcon
              sx={{
                color: "lightgray",
                fontSize: "30px",
              }}
            />
          ),
        },
        {
          path: "/units",
          value: units.length || 0,
          title: "Units",
          iconColor: "linear-gradient(120deg, #f09116, #a8650d)",
          icon: (
            <PiOfficeChairFill
              style={{
                color: "lightgray",
                fontSize: "30px",
              }}
            />
          ),
        },
        {
          path: "/users",
          value: users.length || 0,
          title: "Users",
          iconColor: "linear-gradient(120deg, #00a8f3, #095b80)",
          icon: (
            <UsersIcon
              sx={{
                color: "lightgray",
                fontSize: "30px",
              }}
            />
          ),
        },
        {
          path: "/all-documents",
          value: allDocuments.length || 0,
          title: "All Documents",
          iconColor: "linear-gradient(120deg, #65d749, #ff4b4b)",
          icon: (
            <FileCopyIcon
              sx={{
                color: "lightgray",
                fontSize: "30px",
              }}
            />
          ),
        },
      ]);
    }
  }, [
    allDocuments,
    selectedUserType,
    forRelease,
    incoming,
    outgoing,
    pending,
    saved,
    lapsed,
    onhold,
    signed,
    uploaded,
    forSigning,
    forRouting,
    signed,
    routedIn,
    routedOut,
    docTypes,
    offices,
    units,
    users,
  ]);

  useEffect(() => {
    if (!parsedRole?.includes("admin")) {
      if (parsedRole?.some((role) => ["sds", "asds"].includes(role))) {
        setSelectedUserType("receiver");
      } else {
        setSelectedUserType("sender");
      }
    }
  }, []);

  useEffect(() => {
    if (auth) {
      // Listen for document notifications
      socket.on("documentNotif", (data) => {
        if (data?.recipient?.id === auth?.unitId) {
          handleGetAllDocuments();
          handleGetRelatedDocuments();
        }
      });

      if (parsedRole.some((role) => ["admin"].includes(role))) {
        // Request and listen for all room data
        socket.emit("allRoomData"); // Request room data from the server
        socket.on("allRoomData", (roomData) => {
          console.log(roomData);

          let userCounter = 0;

          roomData.forEach((data) => {
            userCounter += data.userCount;
          });

          setUserCount(userCounter);
        });
      }
    }

    // Cleanup listeners on unmount or auth change
    return () => {
      socket.off("documentNotif");
      socket.off("allRoomData");
    };
  }, [auth, socket]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#f0f0f0",
        px: 7,
        py: 2,
      }}
    >
      <AddDocumentModal
        open={openAddModal}
        handleClose={() => setOpenAddModal(false)}
        loadingState={loading}
        updateTableFunction={() => {
          handleGetAllDocuments();
          handleGetRelatedDocuments();
        }}
      />
      {error && (
        <Box sx={{ backgroundColor: "red", width: "100%", mt: 2, px: 1 }}>
          <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
            {error}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
          width: "100%",
          mb: 2,
          gap: 2,
        }}
      >
        <Box sx={{ mt: 2 }}>
          <Typography
            sx={{ textAlign: "left", fontSize: "30px", fontWeight: "bold" }}
          >
            Welcome to the Dashboard!
          </Typography>
          <Typography
            sx={{ textAlign: "left", fontSize: "20px", fontWeight: "bold" }}
          >
            {`${auth?.firstName} ${auth?.lastName}`}
          </Typography>
          <Typography
            sx={{
              textAlign: "left",
              fontSize: "15px",
              fontWeight: "bold",
            }}
          >
            {`${auth?.officeName}`}
          </Typography>
        </Box>

        <Box>
          <Box sx={{ display: "flex", gap: 2, mb: 1 }}>
            {parsedRole?.some((role) => ["admin"].includes(role)) &&
              contents &&
              contents.length > 0 && (
                <Tooltip title="Go Back" placement="top">
                  <IconButton
                    sx={{
                      // backgroundColor: "#cccccc",
                      background: "#534f7c",
                      boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.3)",
                      cursor: "pointer",
                      transition: "all .5s",
                      color: "lightgray",
                      p: 1,
                      "&:hover": {
                        boxShadow: "3px 3px 10px 5px rgba(0, 0, 0, 0.3)",
                        backgroundColor: "#ededed",
                        color: "black",
                      },
                    }}
                    onClick={() => {
                      setContents([]);
                      setSelectedUserType("");
                    }}
                  >
                    <KeyboardReturnIcon sx={{ fontSize: "25px" }} />
                  </IconButton>
                </Tooltip>
              )}
            {(!parsedRole?.some((role) => ["admin"].includes(role)) ||
              selectedUserType !== "admin") && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  disabled={loading}
                  onClick={() => setOpenAddModal(true)}
                  sx={{
                    backgroundColor: loading ? "lightgray" : "#534f7c",
                    color: "#fff",
                    fontSize: "15px",
                    fontWeight: "bold",
                    padding: "8px 20px",
                    borderRadius: "10px",
                    "&:hover": {
                      backgroundColor: "lightgray",
                      color: "#2f2f2f",
                      fontWeight: "bold",
                    },
                  }}
                >
                  <AddIcon sx={{ mr: 1 }} />
                  Add Document
                </Button>
              </Box>
            )}
          </Box>

          {parsedRole.some((role) => ["admin"].includes(role)) && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                width: "100%",
              }}
            >
              <PersonIcon />
              {userCount}
            </Box>
          )}
        </Box>
      </Box>

      {parsedRole?.some((role) => ["admin"].includes(role)) &&
        contents.length === 0 && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              my: "2vw",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                gap: "4vw",
              }}
            >
              <Box
                onClick={() => {
                  setSelectedUserType("admin");
                }}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // backgroundColor: "#cccccc",
                  background: "#59395c",
                  boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.3)",
                  borderRadius: "20px",
                  width: "18vw",
                  minWidth: "290px",
                  cursor: "pointer",
                  transition: "all .5s",
                  color: "lightgray",
                  p: 2,
                  "&:hover": {
                    boxShadow: "3px 3px 10px 5px rgba(0, 0, 0, 0.3)",
                    backgroundColor: "#ededed",
                    color: "black",
                  },
                  "@media (max-width: 1550px)": {
                    // width: "18vw",
                    minWidth: "230px",
                    // maxWidth: "350px",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <AdminPanelSettingsIcon
                    sx={{
                      fontSize: "50px",
                      mr: 2,
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    Admin Management
                  </Typography>
                </Box>
              </Box>

              {parsedRole.length > 1 && (
                <Box
                  onClick={() => {
                    setSelectedUserType("sender");
                  }}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // backgroundColor: "#cccccc",
                    background: "#59395c",
                    boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.3)",
                    borderRadius: "20px",
                    width: "18vw",
                    minWidth: "290px",
                    cursor: "pointer",
                    transition: "all .5s",
                    p: 2,
                    color: "lightgray",
                    "&:hover": {
                      boxShadow: "3px 3px 10px 5px rgba(0, 0, 0, 0.3)",
                      backgroundColor: "#ededed",
                      color: "black",
                    },
                    "@media (max-width: 1550px)": {
                      // width: "18vw",
                      minWidth: "230px",
                      // maxWidth: "350px",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <FileCopyIcon
                      sx={{
                        fontSize: "50px",
                        mr: 2,
                      }}
                    />
                    <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                      Document Management
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        )}
      {contents && contents.length > 0 && (
        <Box sx={{ width: "100%", my: "2vw" }}>
          <ResponsiveCards contents={contents} loadingState={loading} />
        </Box>
      )}
      <Divider
        sx={{
          backgroundColor: "lightgray",
          width: "100%",
          py: 0.1,
          mb: 4,
        }}
      />
    </Box>
  );
}
