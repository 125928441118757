import React from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";

import CancelIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";

import SelectDestinations from "components/Textfields/SelectDestinations";
import SelectDocStatus from "components/Textfields/SelectDocStatus";
import SelectDocType from "components/Textfields/SelectDocType";

export default function FilterModal({
  filters,
  loading,
  docType,
  setDocType,
  setOpenFilter,
  handleInputChange,
  handleFilterDocLogs,
}) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "Center",
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        height: "100%",
        width: "100%",
        zIndex: 1000,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "90%",
          width: "90%",
          backgroundColor: "lightgray",
          overflow: "auto",
          p: 2,
          borderRadius: "10px",
          boxShadow: "3px 2px 20px 3px rgba(0, 0, 0, 0.4)",
        }}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault(); // Prevents the form from reloading the page
            handleFilterDocLogs(); // Calls your filter function
          }}
        >
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                zIndex: 100,
                py: 2,
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  fontSize: "25px",
                }}
              >
                Filter Documents
              </Typography>
              <IconButton onClick={() => setOpenFilter(false)}>
                <CancelIcon />
              </IconButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 2,
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="lpsNo"
                    name="lpsNo"
                    label="LPS No"
                    variant="outlined"
                    size="small"
                    disabled={loading}
                    value={filters.lpsNo}
                    onChange={handleInputChange}
                    sx={{
                      width: "100%",
                      "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                        {
                          display: "none",
                        },
                      "& input[type=number]": {
                        MozAppearance: "textfield",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={filters.docType === "Others" ? 6 : 12}>
                  <SelectDocType
                    label="Document Type"
                    name="docType"
                    disabled={loading}
                    value={filters.docType}
                    onChange={(fieldName, selectedValue) => {
                      const value = {
                        target: { name: fieldName, value: selectedValue },
                      };

                      handleInputChange(value);

                      if (selectedValue !== "Others") {
                        setDocType("");
                      }
                    }}
                    sx={{
                      width: "100%",
                    }}
                  />
                </Grid>

                {filters.docType === "Others" ? (
                  <Grid item xs={6}>
                    <TextField
                      name="otherDocTypes"
                      label="Specify Document Type"
                      size="small"
                      value={docType}
                      onChange={(e) => setDocType(e.target.value)}
                      variant="standard"
                      fullWidth
                      sx={{
                        mt: -0.5,
                      }}
                    />
                  </Grid>
                ) : (
                  <Box />
                )}
                <Grid item xs={12}>
                  <SelectDocStatus
                    label="Document Status"
                    name="status"
                    disabled={loading}
                    value={filters.status}
                    onChange={(fieldName, selectedValue) => {
                      const value = {
                        target: { name: fieldName, value: selectedValue },
                      };

                      handleInputChange(value);
                    }}
                    sx={{
                      width: "100%",
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="title"
                    name="title"
                    label="Document Title"
                    variant="outlined"
                    size="small"
                    value={filters.title}
                    onChange={handleInputChange}
                    sx={{
                      width: "100%",
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <SelectDestinations
                    label="Primary Source"
                    name="primSourceId"
                    disabled={loading}
                    value={filters.primSourceId}
                    onChange={(fieldName, selectedValue) => {
                      const value = {
                        target: {
                          name: fieldName,
                          value: selectedValue.id,
                        },
                      };

                      handleInputChange(value);
                    }}
                    sx={{
                      width: "100%",
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SelectDestinations
                    label="Last Source"
                    name="lastSourceId"
                    disabled={loading}
                    value={filters.lastSourceId}
                    onChange={(fieldName, selectedValue) => {
                      const value = {
                        target: {
                          name: fieldName,
                          value: selectedValue.id,
                        },
                      };

                      handleInputChange(value);
                    }}
                    sx={{
                      width: "100%",
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SelectDestinations
                    label="Destination"
                    name="destinationId"
                    disabled={loading}
                    value={filters.destinationId}
                    onChange={(fieldName, selectedValue) => {
                      const value = {
                        target: {
                          name: fieldName,
                          value: selectedValue.id,
                        },
                      };

                      handleInputChange(value);
                    }}
                    sx={{
                      width: "100%",
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "end", mt: 2 }}>
            <Button
              type="submit" // The button will now submit the form
              sx={{
                backgroundColor: loading ? "lightgray" : "#534f7c",
                color: "#fff",
                fontSize: "15px",
                fontWeight: "bold",
                padding: "8px 20px",
                borderRadius: "10px",
                "&:hover": {
                  backgroundColor: "#f6e247",
                  color: "#59395c",
                  fontWeight: "bold",
                },
              }}
            >
              <SearchIcon sx={{ mr: 1 }} />
              Filter
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
}
