/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";

import { AiFillSignature } from "react-icons/ai";
import CloseIcon from "@mui/icons-material/Close";
import PictureInPictureAltIcon from "@mui/icons-material/PictureInPictureAlt";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { useStateContext } from "contexts/ContextProvider";
import { enqueueSnackbar } from "notistack";
import useAxiosPrivate from "contexts/interceptors/axios";
import DocumentPreviewModal from "./DocumentPreviewModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "98vh",
  width: "98vw",
  bgcolor: "background.paper",
  boxShadow: "3px 2px 20px 3px rgba(0, 0, 0, 0.3)",
  borderRadius: "10px",
  overflowY: "auto",
  p: 2,
};

export default function ViewDocumentModal({
  open,
  handleClose,
  // pageToSign,
  // setPageToSign,
  // pdfUrl,
  loadingState,
  setOpenViewModal,
  setOpenRouteModal,
  handleSignDocument,
  dataFromActions,
  setDataFromActions,
  updateTableFunction,
}) {
  const { auth, BASE_URL } = useStateContext();
  const axiosPrivate = useAxiosPrivate();

  const [rowData, setRowData] = useState();
  const [isSelecting, setIsSelecting] = useState(false);
  const [combinedSignatories, setCombinedSignatories] = useState([]);
  // const [pageToSign, setPageToSign] = useState(1);
  // const [maxPage, setMaxPage] = useState(0);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [disableSigning, setDisableSigning] = useState(false);

  const [destinations, setDestinations] = useState([]);
  const [fileDocuments, setFileDocuments] = useState([]);

  const [openDocPreviewModal, setOpenDocPreviewModal] = useState(false);

  const [isPdfAvailable, setIsPdfAvailable] = useState(false);

  const userRolesWithSignature = ["sds", "asds", "chief", "unit head"];

  const parsedRole = auth?.role ? JSON.parse(auth?.role) : null;

  useEffect(() => {
    setLoading(loadingState);
  }, [loadingState]);

  useEffect(() => {
    if (dataFromActions) {
      if (Array.isArray(dataFromActions) && dataFromActions.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        setRowData(dataFromActions[0]);
      } else {
        setRowData(dataFromActions);
      }
    }
  }, [dataFromActions]);

  useEffect(() => {
    if (rowData) {
      let parsedAutoInitials = [];
      let parsedManualInitials = [];
      let parsedDestinations = [];
      let parsedFileDocuments = [];

      try {
        parsedAutoInitials = rowData.autoInitials
          ? JSON.parse(rowData.autoInitials)
          : [];
      } catch (err) {
        enqueueSnackbar("Failed to parse autoInitials", {
          variant: "error",
        });
      }

      try {
        parsedManualInitials = rowData.manualInitials
          ? JSON.parse(rowData.manualInitials)
          : [];
      } catch (err) {
        enqueueSnackbar("Failed to parse manualInitials", {
          variant: "error",
        });
      }

      try {
        parsedDestinations = rowData.destinations
          ? JSON.parse(rowData.destinations)
          : [];
      } catch (err) {
        enqueueSnackbar("Failed to parse destinations", {
          variant: "error",
        });
      }

      try {
        parsedFileDocuments = rowData.files ? JSON.parse(rowData.files) : [];
      } catch (err) {
        enqueueSnackbar(`Failed to parse PDF files ${rowData.files}`, {
          variant: "error",
        });
      }

      const autoAndManualSignatories = [
        ...parsedAutoInitials,
        ...parsedManualInitials,
      ];

      const signatoriesIds = autoAndManualSignatories.map(
        (signatory) => signatory?.id
      );

      setCombinedSignatories(autoAndManualSignatories);
      setDestinations(parsedDestinations);
      setFileDocuments(parsedFileDocuments);

      setDisableSigning(
        auth.unitId !== 1 &&
          (signatoriesIds.includes(auth.unitId) ||
            autoAndManualSignatories.length >= 4 ||
            loadingState)
      );
    }
  }, [rowData, loadingState]);

  const handleUnsign = async () => {
    const confirmed = window.confirm(
      "Are you sure you want to unsign this document?"
    );

    if (confirmed) {
      setLoading(true);

      const { id, lastSource } = dataFromActions;

      let parsedLastSource = null;

      if (lastSource) {
        try {
          parsedLastSource = JSON.parse(lastSource);
        } catch (err) {
          console.error("Error parsing lastSource:", err);
        }
      }

      await axiosPrivate
        .put(`/documents/unsignDocument/${id}`, {
          unitId: auth?.unitId,
          lastSource: parsedLastSource,
        })
        .then(() => {
          enqueueSnackbar("Document Unsigned", {
            variant: "success",
          });
          updateTableFunction();
          setOpenViewModal(false);
          setDataFromActions(null);
        })
        .catch((err) => {
          setError(err.response.data.error || "Error: Something went wrong");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    const checkPdfExists = async () => {
      try {
        const response = await fetch(
          `${BASE_URL}/pdfUploads/${fileDocuments[fileDocuments.length - 1]}`
        );

        // const arrayBuffer = await response.arrayBuffer();

        // Load the PDF document using pdf-lib
        // const pdfDoc = await PDFDocument.load(arrayBuffer);

        // setMaxPage(pdfDoc.getPageCount());

        if (!response.ok) {
          // File not found, handle the error
          setIsPdfAvailable(false);
          enqueueSnackbar("PDF file not found", { variant: "error" });
        } else {
          // File exists, set the iframe source
          setIsPdfAvailable(true);
        }
      } catch (err) {
        console.error("Error checking PDF:", err);
        enqueueSnackbar("Error loading PDF", { variant: "error" });
      }
    };

    if (fileDocuments.length > 0 && open) {
      checkPdfExists();
    }
  }, [fileDocuments, open]);

  // const handlePageChange = (evt) => {
  //   if (evt === "next") {
  //     if (pageToSign >= maxPage) {
  //       setPageToSign(maxPage);
  //     } else {
  //       setPageToSign(pageToSign + 1);
  //     }
  //   } else if (evt === "prev") {
  //     if (pageToSign === 1) {
  //       setPageToSign(1);
  //     } else {
  //       setPageToSign(pageToSign - 1);
  //     }
  //   } else {
  //     setPageToSign(1);
  //   }
  // };

  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
        setIsSelecting(true);
        // formik.resetForm();
        // setError("");
      }}
    >
      <Box sx={style}>
        {openDocPreviewModal && (
          <DocumentPreviewModal
            description="Preview of document once unsigned"
            setOpenDocPreviewModal={setOpenDocPreviewModal}
            filePath={
              fileDocuments.length > 0 &&
              fileDocuments[fileDocuments.length - 2]
                ? `${BASE_URL}/pdfUploads/${
                    fileDocuments[fileDocuments.length - 2]
                  }`
                : null
            }
          />
        )}
        {isSelecting &&
          parsedRole?.some((role) =>
            ["sds", "asds", "chief", "unit head"].includes(role)
          ) &&
          dataFromActions?.status === 7 && (
            <Box
              sx={{
                ...style,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.9)",
                height: "100%",
                width: "100%",
                zIndex: 100,
              }}
            >
              {rowData?.isReadable !== 0 && (
                <Box
                  onClick={() => {
                    setOpenViewModal(false);
                    setIsSelecting(false);
                    handleSignDocument("auto"); // Open auto detect signing modal
                  }}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    transition: "ease-in-out 0.3s",
                    cursor: "pointer",
                    height: "20vh",
                    width: "25vh",
                    minHeight: "150px",
                    minWidth: "150px",
                    p: 2,
                    mr: 6,
                    "&:hover": {
                      mt: -2,
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#fff",
                        border: "solid 1px gray",
                        borderRadius: "50%",
                        height: "50px",
                        width: "50px",
                        mb: 2,
                      }}
                    >
                      <AiFillSignature style={{ fontSize: "20px" }} />
                    </Box>
                    Auto-Detect Signature Position
                  </Box>
                </Box>
              )}
              <Box
                onClick={() => {
                  setOpenViewModal(false);
                  setIsSelecting(false);
                  handleSignDocument("manual"); // Open manual signing modal
                }}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#fff",
                  borderRadius: "10px",
                  transition: "ease-in-out 0.3s",
                  cursor: "pointer",
                  height: "20vh",
                  width: "25vh",
                  minHeight: "150px",
                  minWidth: "150px",
                  p: 2,
                  mr: 6,
                  "&:hover": {
                    mt: -2,
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#fff",
                      border: "solid 1px gray",
                      borderRadius: "50%",
                      height: "50px",
                      width: "50px",
                      mb: 2,
                    }}
                  >
                    <PictureInPictureAltIcon />
                  </Box>
                  Select Signature Position
                </Box>
              </Box>
              <Box
                onClick={() => {
                  setIsSelecting(false);
                }}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#fff",
                  borderRadius: "10px",
                  transition: "ease-in-out 0.3s",
                  cursor: "pointer",
                  height: "20vh",
                  width: "25vh",
                  minHeight: "150px",
                  minWidth: "150px",
                  p: 2,
                  mr: 6,
                  "&:hover": {
                    mt: -2,
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#fff",
                      border: "solid 1px gray",
                      borderRadius: "50%",
                      height: "50px",
                      width: "50px",
                      mb: 2,
                    }}
                  >
                    <CloseIcon />
                  </Box>
                  Cancel
                </Box>
              </Box>
            </Box>
          )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {`Title: ${dataFromActions?.title}`}
          <IconButton disabled={loadingState} onClick={() => handleClose()}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {/* <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ fontWeight: "bold", mr: 2 }}>
                Page To Sign:
              </Typography>
              <Box
                sx={{
                  borderRadius: "4px",
                  border: "solid 1px #b6b6b6",
                  width: "5vw",
                  minWidth: "80px",
                  color: pageToSign ? "black" : "#757575",
                  py: "8px",
                  px: "12px",
                }}
              >
                <Typography>
                  {`${pageToSign} / ${maxPage || "Page Num"}`}
                </Typography>
              </Box>
              <IconButton
                onClick={() => handlePageChange("prev")}
              >{`<`}</IconButton>
              <IconButton
                onClick={() => handlePageChange("next")}
              >{`>`}</IconButton>
            </Box>
          </Box> */}

          {/* <Divider orientation="vertical" flexItem sx={{ mx: 2 }} /> */}
          <Box />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {(parsedRole?.some((role) => ["sds", "asds"].includes(role)) ||
              (parsedRole?.some((role) => ["unit head"].includes(role)) &&
                auth.unitId === 12)) &&
              destinations?.some((dest) => dest?.id === auth?.unitId) &&
              dataFromActions?.status === 8 &&
              dataFromActions?.acceptStatus === 1 && (
                <Box>
                  <Button
                    disabled={
                      auth.unitId !== 1 &&
                      auth.unitId !== 2 &&
                      auth.unitId !== 12
                    }
                    onClick={() => {
                      setOpenRouteModal(true);
                      // setOpenViewModal(false);
                    }}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor:
                        auth.unitId !== 1 &&
                        auth.unitId !== 2 &&
                        auth.unitId !== 12
                          ? "lightgray"
                          : "#00a052",
                      color: "#fff",
                      py: 1,
                      width: "10vw",
                      minWidth: "200px",
                      mr: 2,
                      "&:hover": {
                        backgroundColor: "lightgray",
                        color: "#2f2f2f",
                        fontWeight: "bold",
                      },
                    }}
                  >
                    <ShortcutIcon sx={{ mr: 1 }} />
                    Route
                  </Button>
                </Box>
              )}
            {parsedRole?.some((role) =>
              userRolesWithSignature.includes(role)
            ) &&
            destinations?.some((dest) => dest?.id === auth?.unitId) &&
            dataFromActions?.status === 7 &&
            dataFromActions?.acceptStatus === 1 ? (
              <Box
                sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
              >
                {disableSigning && (
                  <Typography sx={{ color: "red", fontSize: "12px", mr: 2 }}>
                    Either you already signed this document or the total number
                    of signatories is reached
                  </Typography>
                )}
                <Button
                  disabled={disableSigning}
                  onClick={() => setIsSelecting(true)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: disableSigning ? "lightgray" : "#59395c",
                    color: disableSigning ? "black" : "#fff",
                    py: 1,
                    width: "10vw",
                    minWidth: "200px",
                    mr: 2,
                    "&:hover": {
                      backgroundColor: "#f6e247",
                      color: "#59395c",
                      fontWeight: "bold",
                    },
                  }}
                >
                  {auth.unitId === 1 ? "Sign Document" : "Initialize Document"}
                </Button>
              </Box>
            ) : combinedSignatories
                .map((signatories) => signatories.id)
                .includes(auth?.unitId) && dataFromActions?.status === 3 ? (
              <>
                <Tooltip
                  title="Preview of Document once unsigned"
                  placement="top"
                >
                  <IconButton onClick={() => setOpenDocPreviewModal(true)}>
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
                <Button
                  disabled={loadingState}
                  onClick={() => handleUnsign()}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#59395c",
                    color: "#fff",
                    py: 1,
                    width: "10vw",
                    minWidth: "200px",
                    mr: 2,
                    "&:hover": {
                      backgroundColor: "#f6e247",
                      color: "#59395c",
                      fontWeight: "bold",
                    },
                  }}
                >
                  Unsign Document
                </Button>
              </>
            ) : (
              <Box />
            )}
          </Box>
        </Box>
        {parsedRole?.some((role) =>
          ["sds", "asds", "chief", "unit head"].includes(role)
        ) &&
          dataFromActions?.status === 7 && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography sx={{ fontWeight: "bold" }}>
                {rowData?.isReadable === 1
                  ? "NOTE: This document is NOT SCANNED. You can use auto or manual signing"
                  : "NOTE: This document is SCANNED. You can only sign it manually"}
              </Typography>
            </Box>
          )}
        {error && (
          <Box sx={{ backgroundColor: "red", width: "100%", mt: 2, px: 1 }}>
            <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
              {error}
            </Typography>
          </Box>
        )}
        {loading && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              height: "100vh",
              width: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1000,
            }}
          >
            <CircularProgress sx={{ color: "white" }} />
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90%",
            width: "100%",
            mt: 2,
          }}
        >
          {dataFromActions &&
          open &&
          fileDocuments.length > 0 &&
          fileDocuments[fileDocuments.length - 1] &&
          isPdfAvailable ? (
            <iframe
              src={`${BASE_URL}/pdfUploads/${
                fileDocuments[fileDocuments.length - 1]
              }`}
              title="PDF Viewer"
              height="100%"
              width="100%"
              onError={() =>
                enqueueSnackbar("PDF not found", {
                  variant: "error",
                })
              }
            />
          ) : (
            <p>No PDF Found</p>
          )}
        </Box>
      </Box>
    </Modal>
  );
}
