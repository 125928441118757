import DashboardIcon from "@mui/icons-material/Dashboard";

// import SummarizeIcon from "@mui/icons-material/Summarize";

import { AiFillSignature } from "react-icons/ai";
// import DownloadDoneOutlinedIcon from "@mui/icons-material/DownloadDoneOutlined";
import DownloadIcon from "@mui/icons-material/FileDownload";
import DocuTypeIcon from "@mui/icons-material/InsertDriveFile";
import FileCopyIcon from "@mui/icons-material/FileCopy";
// import InboxIcon from "@mui/icons-material/MoveToInboxTwoTone";
import { GrDocumentTransfer } from "react-icons/gr";
import OfficeIcon from "@mui/icons-material/HomeWork";
// import OutboxIcon from "@mui/icons-material/Outbox";
import { RiFileTransferFill } from "react-icons/ri";
import PanToolIcon from "@mui/icons-material/PanTool";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import { PiOfficeChairFill } from "react-icons/pi";
import SaveIcon from "@mui/icons-material/Save";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import { TbPencilCheck } from "react-icons/tb";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import UploadIcon from "@mui/icons-material/Upload";
import UsersIcon from "@mui/icons-material/PeopleAlt";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const links = [
  {
    title: "Home",
    role: "superadmin",
    links: [
      {
        name: "Dashboard",
        path: "dashboard",
        access: [
          "admin",
          "sds",
          "asds",
          "chief",
          "secretary",
          "unit head",
          "unit employee",
          "school personnel",
        ],
        icon: <DashboardIcon />,
      },
    ],
  },

  {
    title: "Documents",
    role: "transmittingUsers",
    links: [
      {
        name: "Incoming",
        path: "incoming",
        access: [
          "sds",
          "asds",
          "chief",
          "secretary",
          "unit head",
          "unit employee",
          "school personnel",
        ],
        icon: <DownloadIcon />,
      },
      {
        name: "Outgoing",
        path: "outgoing",
        access: [
          "sds",
          "asds",
          "chief",
          "secretary",
          "unit head",
          "unit employee",
          "school personnel",
        ],
        icon: <UploadIcon />,
      },
      {
        name: "Pending",
        path: "pending",
        access: [
          "chief",
          "secretary",
          "unit head",
          "unit employee",
          "school personnel",
        ],
        icon: <PendingActionsIcon />,
      },
      {
        name: "Release",
        path: "release",
        access: ["secretary"],
        icon: <ThumbUpIcon sx={{ fontSize: "26px" }} />,
      },
      {
        name: "Routed In",
        path: "routedIn",
        access: [
          "asds",
          "chief",
          "secretary",
          "unit head",
          "unit employee",
          "school personnel",
        ],
        icon: <GrDocumentTransfer style={{ fontSize: "26px" }} />,
      },
      {
        name: "Routed Out",
        path: "routedOut",
        access: ["asds", "secretary"],
        icon: <RiFileTransferFill style={{ fontSize: "26px" }} />,
      },
      {
        name: "For Routing",
        path: "routing",
        access: ["sds", "asds", "unit head"],
        icon: <ShortcutIcon sx={{ fontSize: "26px" }} />,
      },
      {
        name: "For Signature",
        path: "signature",
        access: ["sds", "asds", "chief", "unit head"],
        icon: <AiFillSignature style={{ fontSize: "26px" }} />,
      },

      {
        name: "Routed Out",
        path: "routedOut",
        access: ["sds"],
        icon: <RiFileTransferFill style={{ fontSize: "26px" }} />,
      },
      {
        name: "Signed",
        path: "signed",
        access: [
          "sds",
          "asds",
          "chief",
          "secretary",
          "unit head",
          "unit employee",
          "school personnel",
        ],
        icon: <TbPencilCheck style={{ fontSize: "26px" }} />,
      },
      {
        name: "Saved",
        path: "saved",
        access: [
          "asds",
          "chief",
          "secretary",
          "unit head",
          "unit employee",
          "school personnel",
        ],
        icon: <SaveIcon />,
      },
      {
        name: "Lapsed",
        path: "lapsed",
        access: [
          "chief",
          "secretary",
          "unit head",
          "unit employee",
          "school personnel",
        ],
        icon: <WarningAmberIcon />,
      },
      {
        name: "On-Hold",
        path: "onhold",
        access: [
          "chief",
          "secretary",
          "unit head",
          "unit employee",
          "school personnel",
        ],
        icon: <PanToolIcon />,
      },
      {
        name: "Uploaded",
        path: "uploaded",
        access: [
          "chief",
          "secretary",
          "unit head",
          "unit employee",
          "school personnel",
        ],
        icon: <FileCopyIcon style={{ fontSize: "26px" }} />,
      },
    ],
  },

  {
    title: "Libraries",
    role: "admin",
    links: [
      {
        name: "Document Types",
        path: "doc-types",
        access: ["admin"],
        icon: <DocuTypeIcon />,
      },
      {
        name: "Offices",
        path: "offices",
        access: ["admin"],
        icon: <OfficeIcon />,
      },
      {
        name: "Units",
        path: "units",
        access: ["admin"],
        icon: <PiOfficeChairFill />,
      },
      {
        name: "Users",
        path: "users",
        access: ["admin"],
        icon: <UsersIcon />,
      },
      {
        name: "All Documents",
        path: "all-documents",
        access: ["admin"],
        icon: <FileCopyIcon />,
      },
    ],
  },
];

export default links;
