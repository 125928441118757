/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import StatBox from "components/StatBox";

export default function ResponsiveCards({ contents, loadingState }) {
  const navigate = useNavigate();

  const chunkArray = (array, chunkSize) => {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  };

  const chunkedContents = chunkArray(contents, 2);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        gap: "4vw",
      }}
    >
      {chunkedContents.map((chunk) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            gap: "4vw",
          }}
        >
          {chunk.map((content) => (
            <Box
              onClick={() => {
                navigate(content.path);
              }}
              sx={{
                position: "relative",
                backgroundColor: "#cccccc",
                boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.3)",
                borderRadius: "20px",
                width: "18vw",
                minWidth: "290px",
                cursor: "pointer",
                transition: "all .5s",
                px: 2,
                py: 4,
                "&:hover": {
                  boxShadow: "3px 3px 10px 5px rgba(0, 0, 0, 0.3)",
                  backgroundColor: "#ededed",
                },
                "@media (max-width: 1550px)": {
                  // width: "18vw",
                  minWidth: "230px",
                  // maxWidth: "350px",
                },
              }}
            >
              <StatBox
                title={loadingState ? <CircularProgress /> : content.value}
                subtitle={content.title}
                icon={
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",

                      background: content.iconColor,

                      // Gold
                      // "linear-gradient(120deg, #b88f08, #cbba00, #b88f08, #98770a)",
                      // background: "lightgray",
                      // background: content.iconColor,
                      boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.3)",
                      mr: 2,
                      p: 2,
                      borderRadius: "50%",
                    }}
                  >
                    {content.icon}
                  </Box>
                }
              />
              {/* <Box
                sx={{
                  background: content.iconColor,
                  height: "20%",
                  width: "100%",
                  bottom: 0,
                  right: 0,
                  borderBottomLeftRadius: "20px",
                  borderBottomRightRadius: "20px",
                  // clipPath: "polygon(90% 0%, 100% 0, 100% 100%, 0% 100%)",
                  position: "absolute",
                  zIndex: 1000,
                }}
              /> */}
              <Box
                sx={{
                  background:
                    // Silver
                    "linear-gradient(120deg, #B6BBBE, #919191, #B6BBBE, #919191, #696868)",
                  height: "100%",
                  width: "30%",
                  top: 0,
                  right: 0,
                  borderTopRightRadius: "20px",
                  borderBottomRightRadius: "20px",
                  clipPath: "polygon(90% 0%, 100% 0, 100% 100%, 0% 100%)",
                  position: "absolute",
                  zIndex: 1000,
                }}
              />
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
}
