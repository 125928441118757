/* eslint-disable no-restricted-globals */
/* eslint-disable no-else-return */
/* eslint-disable no-alert */
import {
  Box,
  Button,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import CancelIcon from "@mui/icons-material/Close";
import ChecklistIcon from "@mui/icons-material/Checklist";
import { FaRegCircleCheck } from "react-icons/fa6";
import FilterListIcon from "@mui/icons-material/FilterList";

import EditableTable from "components/Table/EditableTable";
import { GridActionsCellItem } from "@mui/x-data-grid";
import useAxiosPrivate from "contexts/interceptors/axios";
import { useStateContext } from "contexts/ContextProvider";
import { useState } from "react";
import DetailsModal from "./DetailsModal";
import FilterModal from "./FilterModal";

dayjs.extend(utc);
dayjs.extend(timezone);

const style = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  position: "relative",
  backgroundColor: "#e9e9e9",
  // background:
  //   "linear-gradient(40deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8))",
  //   border: "solid 2px #46e3be",
  boxShadow: "3px 2px 20px 3px rgba(0, 0, 0, 0.3)",
  borderRadius: "10px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  overflow: "auto",
  "@media (min-width: 10px)": {
    height: "90vh",
    width: "85vw",
  },

  "@media (min-width: 480px)": {
    height: "85vh",
    width: "80vw",
  },

  "@media (min-width: 640px)": {
    height: "75vh",
    width: "70vw",
  },

  "@media (min-width: 768px)": {
    height: "75vh",
    width: "70vw",
  },

  "@media (min-width: 1024px)": {
    height: "80vh",
    width: "70vw",
  },

  "@media (min-width: 1082px)": {
    height: "90vh",
    width: "75vw",
  },
};

export default function FilterDocLogsModal({ open, handleClose }) {
  const { auth } = useStateContext();
  const axiosPrivate = useAxiosPrivate();
  const [data, setData] = useState([]);
  const [actions, setActions] = useState([]);
  const [annotations, setAnnotations] = useState([]);
  const [docType, setDocType] = useState("");

  const [openDetails, setOpenDetails] = useState(false);
  const [openFilter, setOpenFilter] = useState(true);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const SDSSecIds = [3, 4];
  const ASDSSecIds = [7];

  const [filters, setFilters] = useState({
    lpsNo: "",
    docType: "",
    status: "",
    title: "",
    primSourceId: "",
    lastSourceId: "",
    destinationId: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const handleViewDetails = async (rowData) => {
    if (rowData) {
      if (Array.isArray(rowData) && rowData.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        setActions(JSON.parse(rowData[0].action));
        setAnnotations(rowData[0].annotations);
      } else {
        setActions(JSON.parse(rowData.action));
        setAnnotations(rowData.annotations);
      }
      setOpenDetails(true);
    }
  };

  const columns = [
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 150,
      getActions: (params) => {
        const actionItems = [];

        actionItems.push(
          <Tooltip key="viewDetails" title="View Details" placement="top">
            <GridActionsCellItem
              icon={<ChecklistIcon />}
              label="View Details"
              onClick={() => handleViewDetails(params.row)}
              color="inherit"
            />
          </Tooltip>
        );

        return actionItems;
      },
    },
    { field: "docuId", headerName: "ID", width: 120 },
    { field: "lpsNo", headerName: "LPS. No.", width: 120 },
    { field: "docType", headerName: "Doc Type", width: 180 },
    { field: "title", headerName: "Doc Title/Details", width: 200 },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      renderCell: (params) => {
        const {
          primarySources,
          lastSource,
          destinations,
          acceptStatus,
          autoInitials,
          manualInitials,
          complied,
          routedBy,
          createdAtDateTime,
        } = params.row;

        const parsedPrimarySources = JSON.parse(primarySources);
        const parsedLastSources = JSON.parse(lastSource);
        const parsedDestinations = JSON.parse(destinations);

        let chosenLastSource;

        if (parsedLastSources.length === 1) {
          chosenLastSource = parsedLastSources ? parsedLastSources[0] : null;
        } else {
          chosenLastSource = parsedLastSources ? parsedLastSources[1] : null;
        }

        const currentDateTime = new Date();
        const createdAtDateTimeObj = new Date(createdAtDateTime);
        // const lastUpdateDateTimeObj = new Date(lastUpdateDateTime);

        const fifteenDaysInMilliseconds = 15 * 24 * 60 * 60 * 1000;

        if (params.value === 2) {
          return (
            <Typography
              sx={{
                fontWeight: "bold",
                color: "red",
              }}
            >
              Returned
            </Typography>
          );
        } else if (params.value === 1) {
          if (
            parsedDestinations.some((dest) => dest.id === chosenLastSource.id)
          ) {
            return "Incoming";
          } else if (
            parsedPrimarySources.some(
              (prim) => prim.id === chosenLastSource.id
            ) ||
            chosenLastSource.id === auth.unitId
          ) {
            return "Outgoing";
          } else {
            return "Incoming";
          }
        } else if (params.value === 3) {
          if (createdAtDateTime) {
            if (
              currentDateTime.getTime() >=
                createdAtDateTimeObj.getTime() + fifteenDaysInMilliseconds &&
              !autoInitials &&
              !manualInitials &&
              !complied &&
              !routedBy
            ) {
              return (
                <Typography
                  sx={{
                    fontWeight: "bold",
                    color: "red",
                  }}
                >
                  Lapsed
                </Typography>
              );
            }
          }

          return (
            <Typography
              sx={{
                fontWeight: "bold",
                color: "green",
              }}
            >
              Accepted
            </Typography>
          );
        } else if (params.value === 4) {
          return (
            <Typography
              sx={{
                fontWeight: "bold",
                color: "#0d85ee",
              }}
            >
              Saved
            </Typography>
          );
        } else if (params.value === 5) {
          return (
            <Typography
              sx={{
                fontWeight: "bold",
                color: "#dcc36d",
              }}
            >
              On-Hold
            </Typography>
          );
        } else if (params.value === 6) {
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                color: "green",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  mr: 1,
                }}
              >
                Signed
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "20px",
                }}
              >
                <FaRegCircleCheck />
              </Box>
            </Box>
          );
        } else if (params.value === 7) {
          if (acceptStatus === 1) {
            return (
              <Typography
                sx={{
                  fontWeight: "bold",
                }}
              >
                Unsigned
              </Typography>
            );
          } else if (
            parsedDestinations.some((dest) => dest.id === chosenLastSource.id)
          ) {
            return "Incoming";
          } else if (
            parsedPrimarySources.some(
              (prim) => prim.id === chosenLastSource.id
            ) ||
            chosenLastSource.id === auth.unitId
          ) {
            return "Outgoing";
          } else {
            return "Incoming";
          }
        } else if (params.value === 8) {
          if (acceptStatus === 1) {
            return (
              <Typography
                sx={{
                  fontWeight: "bold",
                  color: "#c49019",
                }}
              >
                Routing
              </Typography>
            );
          } else if (
            parsedDestinations.some((dest) => dest.id === chosenLastSource.id)
          ) {
            return "Incoming";
          } else if (
            parsedPrimarySources.some(
              (prim) => prim.id === chosenLastSource.id
            ) ||
            chosenLastSource.id === auth.unitId
          ) {
            return "Outgoing";
          } else {
            return "Incoming";
          }
        } else if (params.value === 9) {
          return (
            <Typography
              sx={{
                fontWeight: "bold",
                color: "#246fc9",
              }}
            >
              Routed
            </Typography>
          );
        } else if (params.value === 10) {
          return (
            <Typography
              sx={{
                fontWeight: "bold",
                color: "#656791",
              }}
            >
              Initialized
            </Typography>
          );
        }
        return null; // Default return if none of the conditions match
      },
    },
    {
      field: "complexity",
      headerName: "Complexity",
      width: 100,
      renderCell: (params) => {
        const complexity = params.value;

        if (complexity === 1) {
          return "Simple";
        } else if (complexity === 2) {
          return (
            <Typography sx={{ color: "#a88921", fontWeight: "bold" }}>
              Complex
            </Typography>
          );
        } else if (complexity === 3) {
          return (
            <Typography sx={{ color: "red", fontWeight: "bold" }}>
              Urgent
            </Typography>
          );
        }

        return null;
      },
    },
    {
      field: "signComplianceStatus",
      headerName: "Sign/Compliance Status",
      width: 180,
      renderCell: (params) => {
        const { autoInitials, manualInitials, complied, signedDateTime } =
          params.row;

        const parsedAutoInitials = autoInitials ? JSON.parse(autoInitials) : [];
        const parsedManualInitials = manualInitials
          ? JSON.parse(manualInitials)
          : [];
        const parsedComplied = complied ? JSON.parse(complied) : [];

        const combinedSignatories = [
          ...parsedAutoInitials,
          ...parsedManualInitials,
        ];

        const signatoriesIds = combinedSignatories.map(
          (signatory) => signatory?.id
        );

        let isInitialized = false;

        if (SDSSecIds.includes(auth?.unitId)) {
          if (signatoriesIds.includes(1)) {
            isInitialized = true;
          }
        } else if (ASDSSecIds.includes(auth?.unitId)) {
          if (signatoriesIds.includes(2)) {
            isInitialized = true;
          }
        } else if (signatoriesIds.includes(auth?.unitId)) {
          isInitialized = true;
        }

        if (signedDateTime) {
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                color: "green",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  mr: 1,
                }}
              >
                Signed
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "20px",
                }}
              >
                <FaRegCircleCheck />
              </Box>
            </Box>
          );
        } else if (isInitialized) {
          return (
            <Typography
              sx={{
                fontWeight: "bold",
                color: "#656791",
              }}
            >
              Initialized
            </Typography>
          );
        } else if (
          parsedComplied.map((unit) => unit.id).includes(auth?.unitId)
        ) {
          return (
            <Typography
              sx={{
                fontWeight: "bold",
                color: "#656791",
              }}
            >
              Complied
            </Typography>
          );
        } else {
          return null;
        }
      },
    },
    {
      field: "classification",
      headerName: "Classification",
      width: 120,
      renderCell: (params) => {
        const classification = params.value;

        if (classification === 1) {
          return "For Signing";
        } else if (classification === 2) {
          return "For Routing"; // routed out
        } else if (classification === 3) {
          return "For Checking";
        } else if (classification === 4) {
          return (
            <Typography
              sx={{
                fontWeight: "bold",
                color: "#246fc9",
              }}
            >
              Routed
            </Typography>
          ); // routed in
        } else {
          return null;
        }
      },
    },
    {
      field: "destinations",
      headerName: "Destination",
      width: 200,
      valueGetter: (params) => {
        let parsedDestinations = params.value;
        if (typeof params.value === "string") {
          parsedDestinations = JSON.parse(params.value);
        }

        return parsedDestinations
          .map((destination) => destination.destination)
          .join(", ");
      },
    },
    {
      field: "primarySources",
      headerName: "Primary Source",
      width: 200,
      valueGetter: (params) => {
        const parsedPrimSources = JSON.parse(params.value);

        const primaryNames = parsedPrimSources.map((prim) => prim.destination);

        const primaryString = primaryNames.join(", ");

        return primaryString;
      },
    },
    {
      field: "lastSource",
      headerName: "Last Source",
      width: 200,
      valueGetter: (params) => {
        const parsedLastSources = JSON.parse(params.value);

        let lastSource;

        if (parsedLastSources.length === 1) {
          lastSource = parsedLastSources ? parsedLastSources[0] : null;
        } else {
          lastSource = parsedLastSources ? parsedLastSources[1] : null;
        }

        return lastSource?.destination;
      },
    },
    {
      field: "createdAtDateTime",
      headerName: "Uploaded Timestamp",
      width: 180,
      valueGetter: (params) =>
        params.value ? dayjs(params.value).format("MM/DD/YYYY hh:mm A") : null,
    },
    {
      field: "lastUpdateDateTime",
      headerName: "Last Updated Timestamp",
      width: 180,
      valueGetter: (params) =>
        params.value ? dayjs(params.value).format("MM/DD/YYYY hh:mm A") : null,
    },
    {
      field: "firstAcceptedDateTime",
      headerName: "First Accepted Timestamp",
      width: 180,
      valueGetter: (params) =>
        params.value ? dayjs(params.value).format("MM/DD/YYYY hh:mm A") : null,
    },
    {
      field: "lastAcceptedDateTime",
      headerName: "Last Accepted Timestamp",
      width: 180,
      valueGetter: (params) =>
        params.value ? dayjs(params.value).format("MM/DD/YYYY hh:mm A") : null,
    },
    {
      field: "signedDateTime",
      headerName: "Signed Timestamp",
      width: 180,
      valueGetter: (params) =>
        params.value ? dayjs(params.value).format("MM/DD/YYYY hh:mm A") : null,
    },
    {
      field: "duration",
      headerName: "Duration",
      width: 120,
      valueGetter: (params) => {
        const { firstAcceptedDateTime, signedDateTime } = params.row;

        if (!firstAcceptedDateTime) return null;

        // const formattedAcceptTimeStamp = firstAcceptedDateTime
        //   ? dayjs(firstAcceptedDateTime)
        //   : null;
        // const formattedSignedTimeStamp = signedDateTime
        //   ? dayjs(signedDateTime)
        //   : null;

        const firstDate = new Date(firstAcceptedDateTime);
        const endDate = signedDateTime ? new Date(signedDateTime) : new Date();

        const diffInMilliseconds =
          dayjs(endDate).add(1, "day").toDate() - firstDate;

        const diffInDays = Math.floor(
          diffInMilliseconds / (1000 * 60 * 60 * 24)
        );

        return diffInDays;
      },
    },
    { field: "remarks", headerName: "Remarks", width: 250 },
    {
      field: "annotation",
      headerName: "Annotation",
      width: 250,
    },
  ];

  const handleFilterDocLogs = () => {
    setLoading(true);
    setError("");

    axiosPrivate
      .get(`/documents/filterDocLogs`, {
        params: filters,
      })
      .then((res) => {
        setOpenFilter(false);
        setOpenDetails(false);
        setData(res.data);
      })
      .catch((err) => {
        setError(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
        // formik.resetForm();
        // setError("");
      }}
    >
      <Box sx={style}>
        {openDetails && (
          <DetailsModal
            actions={actions}
            annotations={annotations}
            setOpenDetails={setOpenDetails}
          />
        )}
        {openFilter && (
          <FilterModal
            filters={filters}
            loading={loading}
            docType={docType}
            setDocType={setDocType}
            setOpenFilter={setOpenFilter}
            handleInputChange={handleInputChange}
            handleFilterDocLogs={handleFilterDocLogs}
          />
        )}

        <Box>
          <Box // Fixed Header
            sx={{
              position: "sticky",
              top: 0,
              backgroundColor: "#59395c",
              // boxShadow: "3px 2px 20px 3px rgba(0, 0, 0, 0.3)",
              boxShadow: "8px 8px 15px 3px rgba(0, 0, 0, 0.3)",
              borderBottomLeftRadius: "30%",
              borderBottomRightRadius: "200px",
              zIndex: 2,
              px: 4,
              mr: 4,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                py: 2,
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "25px",
                  color: "#fff",
                  py: 1,
                }}
              >
                Document Logs
              </Typography>
              <IconButton
                onClick={handleClose}
                sx={{ color: "#fff", p: 0, m: 0 }}
              >
                <CancelIcon />
              </IconButton>
            </Box>
            {/* <Divider sx={{ backgroundColor: "gray" }} /> */}
          </Box>
          <Box sx={{ mx: 2, mb: 2, p: 2 }}>
            {error && (
              <Box sx={{ backgroundColor: "red", width: "100%", mt: 2, px: 1 }}>
                <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
                  {error}
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Button
                onClick={() => setOpenFilter(true)}
                sx={{
                  backgroundColor: loading ? "lightgray" : "#534f7c",
                  color: "#fff",
                  fontSize: "15px",
                  fontWeight: "bold",
                  padding: "8px 20px",
                  borderRadius: "10px",
                  "&:hover": {
                    backgroundColor: "#f6e247",
                    color: "#59395c",
                    fontWeight: "bold",
                  },
                }}
              >
                <FilterListIcon sx={{ mr: 1 }} />
                Filter
              </Button>
            </Box>
            <EditableTable
              data={data}
              columns={columns}
              // loading={loading || loadingState}
              loading={loading}
              // rowToDelete={setRowToDelete}
              height="60vh"
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
