/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from "react";
import { useStateContext } from "contexts/ContextProvider";
import useAxiosPrivate from "contexts/interceptors/axios";

import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import PageTemplate from "layouts/PageTemplate";
import DocumentsTable from "../DocumentsTable";

export default function LapsedDocuments() {
  const { auth, referenceId } = useStateContext();
  const axiosPrivate = useAxiosPrivate();
  const [documents, setDocuments] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleGetAll = () => {
    setLoading(true);
    setError("");

    axiosPrivate
      .get(`/documents/getAllDocuments`, {
        params: { referenceId },
      })
      .then((res) => {
        res.data.forEach((document) => {
          if (typeof document.action === "string") {
            document.action = JSON.parse(document.action);
          }
        });

        const fifteenDaysInMilliseconds = 15 * 24 * 60 * 60 * 1000;

        const filteredLapsed = res.data.filter((doc) => {
          const {
            createdAtDateTime,
            destinations,
            status,
            acceptStatus,
            classification,
          } = doc;

          const parsedDestinations = JSON.parse(destinations);

          const currentDateTime = new Date();
          const createdAtDateTimeObj = new Date(createdAtDateTime);
          // const lastUpdateDateTimeObj = new Date(lastUpdateDateTime);

          if (
            status === 3 &&
            currentDateTime.getTime() >=
              createdAtDateTimeObj.getTime() + fifteenDaysInMilliseconds &&
            createdAtDateTime &&
            acceptStatus &&
            classification !== 4
          ) {
            if (
              auth?.officeId === 1 &&
              parsedDestinations.some(
                (dest) => dest.id === referenceId && dest.type === "unit"
              )
            ) {
              return doc;
            }

            // if the user logged in is from school
            if (
              auth?.officeId !== 1 &&
              parsedDestinations.some(
                (dest) => dest.id === referenceId && dest.type === "office"
              )
            ) {
              return doc;
            }
          }
          return null;
        });

        setDocuments(filteredLapsed);
      })
      .catch((err) => {
        setError(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleGetAll();
  }, []);

  return (
    <PageTemplate
      icon={
        <WarningAmberIcon
          sx={{
            fontSize: "40px",
          }}
        />
      }
      header="Lapsed Documents"
      error={error}
      table={
        <DocumentsTable
          data={documents}
          loadingState={loading}
          setLoadingState={setLoading}
          updateTableFunction={handleGetAll}
        />
      }
    />
  );
}
