/* eslint-disable no-nested-ternary */
/* eslint-disable no-alert */
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Modal,
  Radio,
  Tooltip,
  Typography,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Close";
import RemoveIcon from "@mui/icons-material/Remove";
import ShortcutIcon from "@mui/icons-material/Shortcut";

import { useEffect, useState } from "react";
import useAxiosPrivate from "contexts/interceptors/axios";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import SelectDestinations from "components/Textfields/SelectDestinations";
import { useStateContext } from "contexts/ContextProvider";
import { enqueueSnackbar } from "notistack";

dayjs.extend(utc);
dayjs.extend(timezone);

const style = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  position: "absolute",
  backgroundColor: "#e9e9e9",
  // background:
  //   "linear-gradient(40deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8))",
  //   border: "solid 2px #46e3be",
  boxShadow: "3px 2px 20px 3px rgba(0, 0, 0, 0.3)",
  borderRadius: "10px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  overflow: "auto",
  "@media (min-width: 10px)": {
    height: "90vh",
    width: "85vw",
  },

  "@media (min-width: 480px)": {
    height: "85vh",
    width: "80vw",
  },

  "@media (min-width: 640px)": {
    height: "75vh",
    width: "70vw",
  },

  "@media (min-width: 768px)": {
    height: "75vh",
    width: "70vw",
  },

  "@media (min-width: 1024px)": {
    height: "80vh",
    width: "70vw",
  },

  "@media (min-width: 1082px)": {
    height: "80vh",
    width: "65vw",
  },
};

export default function TransmitDocumentModal({
  open,
  handleClose,
  updateTableFunction,
  loadingState,
  selectedData,
}) {
  const { auth } = useStateContext();
  const axiosPrivate = useAxiosPrivate();
  const [disabled, setDisabled] = useState(false);
  const [destinations, setDestinations] = useState([
    { id: null, destination: null, type: null },
  ]);
  const [classification, setClassification] = useState(4);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [destinationErrorMssg, setDestinationErrorMssg] = useState([]);
  // const [isForSignature, setIsForSignature] = useState(true);

  // const SDSSecIds = [3, 4];
  // const ASDSSecIds = [7];
  // const manyDocuments = selectedData?.length > 1;

  const parsedRole = auth?.role ? JSON.parse(auth?.role) : null;

  const handleSubmit = () => {
    const confirmed = window.confirm(
      "Are you sure you want to transmit this document?"
    );

    if (confirmed) {
      setLoading(true);
      setError("");

      const destinationNames = destinations.map((dest) => dest.destination);

      const destinationsString = destinationNames.join(", ");

      axiosPrivate
        .patch(`/documents/transmitDocs`, {
          documents: selectedData,
          updateFields: {
            destinations: JSON.stringify(destinations),
            lastSource:
              auth?.officeId === 1
                ? {
                    id: auth?.unitId,
                    destination: auth?.unitName,
                    type: "unit",
                  }
                : {
                    id: auth?.officeId,
                    destination: auth?.officeName,
                    type: "office",
                  },
            status: 1,
            acceptStatus: 0,
            ...(selectedData?.some((doc) => doc?.classification === 4) &&
              classification !== 4 && {
                classification,
                routedBy: null,
              }),
            remarks: `Transmitted by ${auth?.firstName} ${
              auth?.lastName
            } from ${
              auth?.officeId === 1 ? auth?.unitName : auth?.officeName
            } to ${destinationsString}`,
          },
        })
        .then(() => {
          enqueueSnackbar(
            `Document${selectedData?.length > 1 ? "s" : ""} Transmitted`,
            {
              variant: "success",
            }
          );
          setDestinations([{ id: null, destination: null, type: null }]);
          updateTableFunction();
          handleClose();
        })
        .catch((err) => {
          setError(err?.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const displayDestinationError = (id, text) => {
    setDestinationErrorMssg((prevErrors) => ({
      ...prevErrors,
      [id]: text,
    }));
    setTimeout(() => {
      setDestinationErrorMssg((prevErrors) => ({
        ...prevErrors,
        [id]: "",
      }));
    }, 3000);
  };

  const handleDestinations = (index, value) => {
    setDestinations((prevResponse) => {
      const newDestination = [...prevResponse];

      if (!value.id || !value.destination || !value.type) {
        displayDestinationError(index, "Destination Required");
      }

      if (
        newDestination.some(
          (dest) => dest.id === value?.id && dest.type === value?.type
        )
      ) {
        newDestination[index] = { id: null, destination: null, type: null };
        displayDestinationError(index, "Destination already chosen");
      } else {
        newDestination[index] = value || {
          id: null,
          destination: null,
          type: null,
        };
      }
      return newDestination;
    });
  };

  useEffect(() => {
    const areAllDestinationsFilled = destinations.every((obj) =>
      Object.values(obj).every(
        (val) => val !== undefined && val !== null && val !== ""
      )
    );

    setDisabled(
      parsedRole?.some((role) => ["secretary"].includes(role)) &&
        selectedData &&
        selectedData?.some((doc) => !doc.signedDateTime)
        ? true
        : areAllDestinationsFilled
    );
  }, [destinations]);

  useEffect(() => {
    setLoading(loadingState);
  }, [loadingState]);

  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
        // formik.resetForm();
        // setError("");
      }}
    >
      <Box sx={style}>
        {loading && (
          <Box
            sx={{
              ...style,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 1000,
            }}
          >
            <CircularProgress sx={{ color: "white" }} />
          </Box>
        )}
        <Box sx={style}>
          <Box>
            <Box // Fixed Header
              sx={{
                position: "sticky",
                top: 0,
                backgroundColor: "#59395c",
                // boxShadow: "3px 2px 20px 3px rgba(0, 0, 0, 0.3)",
                boxShadow: "8px 8px 15px 3px rgba(0, 0, 0, 0.3)",
                borderBottomLeftRadius: "30%",
                borderBottomRightRadius: "200px",
                zIndex: 2,
                px: 4,
                mr: 4,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  py: 2,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "25px",
                    color: "#fff",
                    py: 1,
                  }}
                >
                  Transmit Document
                </Typography>
                <IconButton
                  onClick={handleClose}
                  sx={{ color: "#fff", p: 0, m: 0 }}
                >
                  <CancelIcon />
                </IconButton>
              </Box>
              {/* <Divider sx={{ backgroundColor: "gray" }} /> */}
            </Box>

            {error && (
              <Box sx={{ backgroundColor: "red", width: "100%", mt: 2, px: 1 }}>
                <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
                  {error}
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                m: 4,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  background: "#fff",
                  border: "solid 1px #b6b6b6",
                  p: 2,
                  mb: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        color: "gray",
                        mb: 2,
                      }}
                    >
                      Destination/s:
                    </Typography>
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      {destinations?.map((destination, index) => (
                        <Grid item xs={destinations.length > 1 ? 6 : 12}>
                          <SelectDestinations
                            label={`Destination ${index + 1}`}
                            disabled={loading}
                            value={destination?.id}
                            onChange={(fieldName, selectedValue) => {
                              handleDestinations(index, selectedValue);
                            }}
                            error={Boolean(destination?.id === "")}
                            helperText={
                              <span style={{ color: "red" }}>
                                {destinationErrorMssg[index]}
                              </span>
                            }
                            addSpecificDestinations={
                              open &&
                              parsedRole?.some((role) =>
                                ["secretary"].includes(role)
                              ) &&
                              JSON.parse(auth?.relatedUnits)
                            }
                            // showOnlySpecificUnits={
                            //   auth?.role === "secretary" &&
                            //   selectedData &&
                            //   selectedData.some((doc) => !doc.signedDateTime)
                            //     ? SDSSecIds.includes(auth.unitId)
                            //       ? [1]
                            //       : ASDSSecIds.includes(auth.unitId)
                            //       ? [2]
                            //       : null
                            //     : null
                            // }
                            restrictOwnDestination={
                              auth?.officeId !== 1 ? "office" : "unit"
                            }
                            sx={{
                              width: "100%",
                              pr: 3,
                              "&:hover": {
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "black !important",
                                },
                              },
                            }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                  <Box sx={{ textAlign: "end" }}>
                    <Tooltip title="Add Destination" placement="right">
                      <IconButton
                        onClick={() =>
                          setDestinations((prev) => [
                            ...prev,
                            { id: "", destination: "", type: "" },
                          ])
                        }
                        sx={{
                          backgroundColor: "#4ea82b",
                          color: "#fff",
                          mb: 1,
                          "&:hover": {
                            backgroundColor: "#0ed145",
                          },
                        }}
                      >
                        <AddIcon sx={{ fontWeight: "bold" }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Remove Destination" placement="right">
                      <IconButton
                        disabled={destinations.length < 2}
                        onClick={() =>
                          setDestinations((prev) => prev.slice(0, -1))
                        }
                        sx={{
                          backgroundColor:
                            destinations.length < 2 ? "lightgray" : "red",
                          border:
                            destinations.length < 2 && "solid 1px #f28c8c",
                          color: destinations.length < 2 ? "black" : "#fff",
                          "&:hover": {
                            backgroundColor: "#e54c51",
                          },
                        }}
                      >
                        <RemoveIcon sx={{ fontWeight: "bold" }} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              </Box>

              {selectedData?.some((doc) => doc?.classification === 4) && (
                <Box
                  sx={{
                    display: "block",
                    justifyContent: "space-between",
                    background: "#fff",
                    border: "solid 1px #b6b6b6",
                    borderRadius: "4px",
                    width: "100%",
                    mb: 2,
                    p: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: "bold",
                      color: "gray",
                    }}
                  >
                    Classification:
                  </Typography>
                  <FormControl>
                    <FormGroup
                      row
                      sx={{
                        p: "16px 0 0 16px",
                        gap: 2,
                      }}
                    >
                      {[
                        { label: "For Signing", value: 1 },
                        { label: "For Routing", value: 2 },
                        { label: "Routed", value: 4 },
                      ].map((option) => (
                        <FormControlLabel
                          key={option.value}
                          control={
                            <Radio
                              color="success"
                              checked={classification === option.value}
                              onChange={() => {
                                setClassification(option.value);
                              }}
                            />
                          }
                          label={
                            <Typography
                              sx={{
                                fontWeight:
                                  classification === option.value
                                    ? "bold"
                                    : "normal",
                                color:
                                  classification === option.value
                                    ? "green"
                                    : "gray",
                              }}
                            >
                              {option.label}
                            </Typography>
                          }
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                </Box>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              p: 2,
              zIndex: 10,
              mt: 2,
            }}
          >
            <Button
              disabled={!disabled}
              onClick={() => handleSubmit()}
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: disabled ? "#59395c" : "lightgray",
                color: "#fff",
                py: 1,
                px: 2,
                // width: "10vw",
                // minWidth: "100px",
                "&:hover": {
                  backgroundColor: "#f6e247",
                  color: "#59395c",
                  fontWeight: "bold",
                },
              }}
            >
              <ShortcutIcon
                sx={{
                  mr: 1,
                }}
              />
              Transmit
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
